import React from 'react';
import PropTypes from 'prop-types';
import MobileMoveToMenu from '../components/MobileMoveToMenu';

const MobileBottomMenu = (props) => {
    return (
        <div>
            <MobileMoveToMenu moveToIsDisplayed={props.moveToIsDisplayed}
                currentStatus={props.currentStatus}
                statusList={props.statusList}
                isOnShift={props.isOnShift}
                handleUpdateUserStatusSelection={props.handleUpdateUserStatusSelection} />
            <div className="BottomMobNav">
                <ul>
                    <li id="profile" className={props.selectedView === "profile" ? "mobile-selected-li" : "mobile-unselected-li"}
                        onClick={props.changeMobileView.bind(this)}>
                        <a>
                            <img src="images/mob-menu-profile.svg" alt="" />
                            <span>PROFILE</span>
                        </a>
                    </li>
                    <li id="moveTo" className={props.selectedView === "moveTo" ? "mobile-selected-li" : "mobile-unselected-li"}
                        onClick={props.changeMobileView.bind(this)}>
                        <a>
                            <img className={props.moveToIsDisplayed === true ? "mobile-arrow-up" : ""} src="images/mob-menu-move.svg" alt="" />
                            <span>TAKE ME TO</span>
                        </a>
                    </li>
                    <li id="availableList" className={props.selectedView === "availableList" ? "mobile-selected-li" : "mobile-unselected-li"}
                        onClick={props.changeMobileView.bind(this)}>
                        <a>
                            <img src="images/mob-menu-available.svg" alt="" />
                            <span>AVAILABLE LIST</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

MobileBottomMenu.propTypes = {
    selectedView: PropTypes.string,
    moveToIsDisplayed: PropTypes.bool,
    currentStatus: PropTypes.string,
    statusList: PropTypes.array,
    isOnShift: PropTypes.bool,

    changeMobileView: PropTypes.func,
    handleUpdateUserStatusSelection: PropTypes.func
};

export default MobileBottomMenu;
