import React from 'react';
import PropTypes from 'prop-types';

const TopUserProfile = (props) => {
  return (
    <li>
      <a className="profile-btn" href="#">
        <div className="profile-image">
          <img src={props.user.userImage} alt="Profile" />
        </div>
        <span>{props.user.firstName + " " + props.user.lastName}</span>
      </a>
    </li>
  );
};

TopUserProfile.propTypes = {
  user: PropTypes.object.isRequired
};

export default TopUserProfile;
