import * as types from '../constants/actionTypes';
import * as requestState from '../constants/requestStates';

const initialState = {
  request: requestState.NONE,
  lastRequestName: ""
};

export default function requestReducer(state = initialState, action) {
  switch (action.type) {
    case types.REQUEST_POSTED: {
      return { ...state, request: requestState.IN_PROGRESS, lastRequestName: action.payload };
    }

    case types.REQUEST_REJECTED: {
      return { ...state, request: requestState.FAIL };
    }

    case types.REQUEST_CLEAR: {
      return { ...state, request: requestState.NONE, lastRequestName: "" };
    }

    case types.REQUEST_FULFILLED: {
      return { ...state, request: requestState.SUCCESS };
    }

    case types.SIGNOUT: {
      localStorage.clear();
      return { ...initialState, isLoggedIn: false };
    }

  }

  return state;
}
