import React from 'react';
import PropTypes from 'prop-types';
import UserLookupRow from './UserLookupRow';
import { TextField } from 'material-ui';
import { grey300 } from 'material-ui/styles/colors';
import * as offSets from '../data/constants/applicationSizeOffsets';
import draggableItemTypes from '../data/constants/draggableItemTypes';
import { Droppable } from 'react-beautiful-dnd';

const UserLookup = (props) => {

    const styles = {
        underlineStyle: {
            color: grey300
        },
        underlineFocusStyle: {
            color: grey300
        },
        floatingLabelStyle: {
            color: grey300
        },
        floatingLabelFocusStyle: {
            color: grey300
        }
    };

    const containerHeightStyle = {
        minHeight: (props.applicationHeight - offSets.USER_LOOKUP_COMPONENT).toString() + "px",
        height: (props.applicationHeight - offSets.USER_LOOKUP_COMPONENT).toString() + "px"
    };

    const resultContainerStyle = {
        minHeight: (props.applicationHeight - offSets.USER_LOOKUP_COMPONENT_RESULTS).toString() + "px",
        height: (props.applicationHeight - offSets.USER_LOOKUP_COMPONENT_RESULTS).toString() + "px",
        overflow: 'auto'
    };

    const resultContainerStyleDragging = {
        minHeight: (props.applicationHeight - offSets.USER_LOOKUP_COMPONENT_RESULTS).toString() + "px",
        height: (props.applicationHeight - offSets.USER_LOOKUP_COMPONENT_RESULTS).toString() + "px",
        overflow: 'auto',
        backgroundColor: '#969696'
    };

    return (

        <div className="rb-card no-padding elt-shadow-2 lookingup" style={containerHeightStyle}>
            <div className="title-yellow">
                <img className="squared-icon" src="images/search-icon.svg" alt="" /> Lookup
        </div>
            <div className="row-lookup-component searching">
                <TextField floatingLabelText="Start typing to filter..."
                    value={props.userFilter}
                    onChange={props.handleFilteringOfUsers.bind(this)}
                    underlineStyle={styles.underlineStyle}
                    underlineFocusStyle={styles.underlineFocusStyle}
                    floatingLabelStyle={styles.floatingLabelStyle}
                    floatingLabelFocusStyle={styles.floatingLabelFocusStyle} />
            </div>
            <div className="associate-selector-rpsv">
                <span>Select an Associate</span>
            </div>
            <Droppable droppableId="listOfClientAdvisors" type={draggableItemTypes.MANAGER_USER_CARD} direction="vertical" isDropDisabled={true}>
                {(provided, snapshot) => (
                    <div className="search-container"
                        style={!snapshot.isDraggingOver ? resultContainerStyle : resultContainerStyleDragging}
                        ref={provided.innerRef}
                        /*{...provided.droppableProps} need in version 5.0.0*/>
                        {
                            props.listOfClientAdvisors.map((clientAdvisor, index) => {
                                return (
                                    <UserLookupRow
                                        key={index}
                                        index={index}
                                        position={index}
                                        clientAdvisor={clientAdvisor} />
                                );
                            })
                        }
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
};

UserLookup.propTypes = {
    applicationHeight: PropTypes.number,
    listOfClientAdvisors: PropTypes.array,
    handleFilteringOfUsers: PropTypes.func,
    userFilter: PropTypes.string,
};

export default UserLookup;
