import * as types from "../constants/actionTypes";
import OrganizationsServices from "../services/OrganizationServices";

import config from "../../configuration/config";

export function retrieveById(idOrganization) {
  const organizationServicesInstance = new OrganizationsServices(config);

  return function (dispatch) {
    organizationServicesInstance
      .retrieveById(idOrganization)
      .then((r) =>
        dispatch({ type: types.ORGANIZATION_RETRIEVE_BY_ID, payload: r })
      );
  };
}

export function updateSettings(idOrganization, min, managementval) {
  const organizationServicesInstance = new OrganizationsServices(config);
  return function (dispatch) {
    organizationServicesInstance
      .updateAutoRotateMinutes(idOrganization, min)
      .then(() => {
        return organizationServicesInstance
          .updateAllowUsersSelfManagement(idOrganization, managementval)
          .then((r) =>
            dispatch({
              type: types.ORGANIZATION_UPDATE_AUTO_ROTATE_MINUTES,
              payload: r,
            })
          );
      });
  };
}
