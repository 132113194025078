import * as types from '../constants/actionTypes';
import * as offSets from '../constants/applicationSizeOffsets';
import * as statusDescription from '../constants/statusDescriptions';

const initialState = {
    applicationWidth: 0,
    applicationHeight: 0,
    leftBarNavigationTop: 0,
    liveRotationContainerTop: 0,

    availableContainerTop: 0,
    availableRowHeight: 0,
    availableNumberOfRowsByPage: 0,
    availableSelectedPage: 0,

    standardStatusHeight: 0,
    standardStatusWidth: 0,
    standardStatusCircularImageWidth: 0,
    standardStatusCircularImageHeight: 0,
    standardStatusNumberOfItemsByPage: parseInt(localStorage.getItem("standardStatusNumberOfItemsByPage")),
    standardStatusOnHoldSelectedPage: 0,
    standardStatusGuestEngagementSelectedPage: 0,
    standardStatusWillingSelectedPage: 0,
    standardStatusOffSelectedPage: 0,
    lastUpdateDismissed: true,

    platform: "web"
};

export default function applicationReducer(state = initialState, action) {
    let otherContainerHeight = 0;
    let numberOfAvailableRows = 0;
    let dimensions;

    switch (action.type) {
        case types.STORE_APPLICATION_DIMENSIONS: {
            dimensions = action.payload;

            // the size of the other containers
            otherContainerHeight = Math.floor(((dimensions.height - state.availableContainerTop) - (offSets.OTHER_STATUS_CONTAINER / 2)));

            // the number of rows that will fit inside the available container
            numberOfAvailableRows = Math.floor((dimensions.height - state.availableContainerTop - offSets.AVAILABLE_ROW) / state.availableRowHeight);

            return { ...state, applicationWidth: dimensions.width, applicationHeight: dimensions.height, standardStatusHeight: otherContainerHeight, availableNumberOfRowsByPage: numberOfAvailableRows };
        }

        case types.STORE_LEFT_BAR_TOP: {
            return { ...state, leftBarNavigationTop: action.payload };
        }

        case types.STORE_LIVEROTATION_CONTAINER_TOP: {
            return { ...state, liveRotationContainerTop: action.payload };
        }

        case types.STORE_AVAILABLE_CONTAINER_TOP: {
            // the distance between the available container to the client top screen            
            let distanceToTop = action.payload.distanceToTop;

            // get the control to use the correct offset
            let isFromManager = action.payload.isFromManager;

            // the size of the other containers
            otherContainerHeight = Math.floor(((state.applicationHeight - distanceToTop) - (isFromManager ? offSets.OTHER_STATUS_CONTAINER_MANAGER : offSets.OTHER_STATUS_CONTAINER)) / 2);

            // the number of rows that will fit inside the available container
            numberOfAvailableRows = Math.floor((state.applicationHeight - distanceToTop - offSets.AVAILABLE_ROW) / state.availableRowHeight);

            return { ...state, availableContainerTop: distanceToTop, standardStatusHeight: otherContainerHeight, availableNumberOfRowsByPage: numberOfAvailableRows };
        }

        case types.STORE_AVAILABLE_ROW_HEIGHT: {
            // the height of each available row
            let theRowHeight = action.payload;

            if (theRowHeight > 0) {
                // the number of rows that will fit inside the available container
                numberOfAvailableRows = Math.floor((state.applicationHeight - state.availableContainerTop - offSets.AVAILABLE_ROW) / theRowHeight);

                return { ...state, availableRowHeight: theRowHeight, availableNumberOfRowsByPage: numberOfAvailableRows };
            }
            else {
                return { ...state };
            }
        }

        case types.STORE_AVAILABLE_SELECTED_PAGE: {
            return { ...state, availableSelectedPage: action.payload };
        }

        case types.STORE_STANDARD_STATUS_SELECTED_PAGE: {
            let theContainer = action.payload.theContainer;
            let thePage = action.payload.thePage;
            switch (theContainer) {
                case statusDescription.ON_HOLD:
                    return { ...state, standardStatusOnHoldSelectedPage: thePage };
                case statusDescription.GUEST_ENGAGEMENT:
                    return { ...state, standardStatusGuestEngagementSelectedPage: thePage };
                case statusDescription.WILLING:
                    return { ...state, standardStatusWillingSelectedPage: thePage };
                default:
                    return { ...state, standardStatusOffSelectedPage: thePage };
            }
        }

        case types.STORE_STATUS_CONTAINER_DIMENSIONS: {
            dimensions = action.payload;
            let contWidth = dimensions.containerWidth;
            let circImageWidth = dimensions.circularImageWidth + offSets.CIRCULAR_IMAGE_WIDTH;
            let circImageHeight = dimensions.circularImageHeight;

            let itemsByPage = Math.floor(Math.floor(contWidth / circImageWidth) * Math.floor(state.standardStatusHeight / circImageHeight));

            localStorage.setItem("standardStatusNumberOfItemsByPage", itemsByPage);
            return {
                ...state, standardStatusWidth: contWidth,
                standardStatusCircularImageWidth: circImageWidth,
                standardStatusCircularImageHeight: circImageHeight,
                standardStatusNumberOfItemsByPage: itemsByPage
            };
        }

        case types.FETCH_LAST_UPDATE_FULFILLED: {

            return { ...state, lastUpdateDismissed: false };
        }

        case types.DISMISS_UPDATE_FULFILLED: {

            return { ...state, lastUpdateDismissed: true };
        }

        case types.SIGNOUT: {
            localStorage.clear();
            return { ...initialState, isLoggedIn: false };
        }
    }

    return state;
}