import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//import { Redirect } from 'react-router-dom';
import moment from "moment";
import * as userActions from "../data/UserSession/userSessionActions";
import * as liveRotationActions from "../data/LiveRotation/liveRotationActions";
import * as applicationActions from "../data/Application/applicationActions";
import * as reportActions from "../data/Report/reportActions";
import * as securityRoles from "../data/constants/securityRoles";
import * as requestState from "../data/constants/requestStates";
import TopNavigationBar from "./TopNavigationBar";
import ReportFilters from "../components/ReportFilters";
import Offline from "../components/Offline";
import RaisedButton from "material-ui/RaisedButton";
import Spinner from "react-spinkit";
import ChartBarSimple from "../components/ChartBarSimple";

class RotationBoardReport extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.fetchStatus(this.props.selectedOrganizationId);
    }, 0); // feel this a little hacky? check this: http://stackoverflow.com/questions/26556436/react-after-render-code
    setTimeout(() => {
      this.props.fetchLastUpdateByUserId(this.props.userId, true);
    }, 0); // feel this a little hacky? check this: http://stackoverflow.com/questions/26556436/react-after-render-code
    setTimeout(() => {
      this.props.fetchUserCurrentStandardPosition(this.props.userId);
    }, 0); // feel this a little hacky? check this: http://stackoverflow.com/questions/26556436/react-after-render-code

    let event = { target: { defaultValue: "MTD" } };
    this.handleDateRangeChange(event);

    // load the list of client advisors
    if (
      this.props.listOfClientAdvisorsNoFilter === undefined ||
      this.props.listOfClientAdvisorsNoFilter.length === 0
    ) {
      this.props.fetchAllAssociatesByRole(
        securityRoles.ClientAdvisorIdMap,
        this.props.selectedOrganizationId
      );
    }
    this.props.setXAxisInformation();

    // initially clear the report info
    this.props.clearReportInformation();

    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.updateDimensions();

    //Generate the Report
    this.handleReportGeneration();
  }

  loadStandardPositions() {
    if (
      this.props.activeStandardPositions == null ||
      typeof this.props.activeStandardPositions == "undefined" ||
      this.props.activeStandardPositions.length == 0
    ) {
      if (
        this.props.activeStandardPositions == undefined ||
        this.props.activeStandardPositions.length <= 0
      ) {
        if (
          this.props.availableStatusId !== "" &&
          this.props.availableStatusId !== undefined
        ) {
          this.props.fetchStandardPositions(this.props.availableStatusId);
        }
      }
    } else {
      if (this.props.xAxisInformation.length <= 1) {
        this.props.setXAxisInformation();
      }
    }
  }

  componentDidUpdate() {
    // this.props.setXAxisInformation();
    this.loadStandardPositions();
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    // compute the dimensions of the screen
    let w = window;
    let d = document;
    let documentElement = d.documentElement;
    let body = d.getElementsByTagName("body")[0];
    let theWidth =
      body.clientWidth || w.innerWidth || documentElement.clientWidth;
    let theHeight =
      body.clientHeight || w.innerHeight || documentElement.clientHeight;

    this.props.storeApplicationDimensions({
      width: theWidth,
      height: theHeight,
    });
  }

  handleDateRangeChange(event) {
    this.props.dateRangeChange(event.target.defaultValue);
  }

  handleMinDateChange(event, date) {
    this.props.updateMinDateChange(date);
  }

  handleMaxDateChange(event, date) {
    this.props.updateMaxDateChange(date);
  }

  handleClientAdvisorChange(event) {
    if (event.userId !== undefined) {
      this.props.updateSelectedClientAdvisor(event.userId);
    } else if (event.type === "blur") {
      if (event.target.value === "") {
        this.props.updateSelectedClientAdvisor(
          "00000000-0000-0000-0000-000000000000"
        );
      }
    }
  }

  handleReportGeneration() {
    this.props.getReportInformation(
      this.props.selectedOrganizationId,
      this.props.selectedClientAdvisor,
      moment(this.props.minDate).format("YYYY-MM-DD"),
      moment(this.props.maxDate).format("YYYY-MM-DD")
    );
  }

  render() {
    const buttonStyle = {
      margin: 12,
    };

    return !this.props.connection ? (
      <Offline />
    ) : (
      <div className="report-wrapper">
        <TopNavigationBar />
        <div className="header">
          <h1 className="main-title">GuestTrek® Rotation Report</h1>
        </div>
        <div className="big-container">
          <ReportFilters
            selectedDateRange={this.props.selectedDateRange}
            handleDateRangeChange={this.handleDateRangeChange.bind(this)}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            handleMinDateChange={this.handleMinDateChange.bind(this)}
            handleMaxDateChange={this.handleMaxDateChange.bind(this)}
            listOfClientAdvisorsNoFilter={
              this.props.listOfClientAdvisorsNoFilter
            }
            handleClientAdvisorChange={this.handleClientAdvisorChange.bind(
              this
            )}
          />

          <RaisedButton
            label="Generate Report"
            primary={true}
            style={buttonStyle}
            onClick={this.handleReportGeneration.bind(this)}
            disabled={this.props.request === requestState.IN_PROGRESS}
          />
          <Spinner
            style={{
              display:
                this.props.request === requestState.IN_PROGRESS
                  ? "inline-block"
                  : "none",
            }}
            fadeIn="none"
            name="three-bounce"
            color="steelblue"
          />
          <div className="report-container">
            <div>
              <div
                className="row position-title-blue"
                style={{ width: "120%" }}
              >
                <div className="nonavailable">
                  <img
                    className="squared-icon"
                    src="images/report-icon.svg"
                    alt=""
                  />{" "}
                  <span>Report - Overall</span>
                </div>
              </div>
              <div className="gray-bar width-100" style={{ width: "120%" }}>
                {this.props.xAxisInformation.map((info) => {
                  return info != null ? (
                    <span key={info.id}>
                      <img src={info.icon} alt="" />
                      {info.description}
                    </span>
                  ) : null;
                })}
              </div>
              <div className="report-data" style={{ width: "120%" }}>
                <div
                  style={{
                    display:
                      this.props.request === requestState.IN_PROGRESS
                        ? "none"
                        : "block",
                  }}
                >
                  {
                    <div className="teamnametag-report">
                      <div className="block" />
                      <br />
                      {this.props.reportInformation.length <= 0 ? null : (
                        <div className="statistic-container time-spent">
                          <div className="statistic">
                            <ChartBarSimple
                              data={this.props.reportOverallTimeSpent}
                              xKey={"id"}
                              yKey={"value"}
                              containerPadding={30}
                              chartTopPadding={50}
                              chartBottomPadding={50}
                              chartRightPadding={50}
                              chartLeftPadding={85}
                              valuesInMinutes={true}
                              xTickValues={[0, 1, 2, 3, 4, 5, 6]}
                              title={"TIME SPENT BY STAGE"}
                              titlePositionX={190}
                              titlePositionY={30}
                              barWidth={30}
                              barColor={"#4d6584"}
                              tickFormatingFunction={(x) =>
                                `${
                                  String(x).indexOf("e") != -1
                                    ? 0
                                    : (parseInt(x) * 0.0167).toFixed(2)
                                } hours`
                              }
                              xAxisImagesTopPadding={310}
                              xAxisImagesLeftInitialPadding={101}
                              xAxisImagesTickSize={29}
                              xAxisImagesMinWidth={25}
                              xAxisImagesMinHeight={25}
                              xAxisInformation={this.props.xAxisInformation}
                              width={450}
                            />
                          </div>
                        </div>
                      )}
                      {this.props.reportInformation <= 0 ? null : (
                        <div className="statistic-container time-position">
                          <div className="statistic">
                            <ChartBarSimple
                              data={this.props.reportOverallNumberOfTimes}
                              xKey={"id"}
                              yKey={"value"}
                              containerPadding={30}
                              chartTopPadding={50}
                              chartBottomPadding={50}
                              chartRightPadding={50}
                              chartLeftPadding={85}
                              valuesInMinutes={false}
                              xTickValues={[0, 1, 2, 3, 4, 5, 6]}
                              title={"TIMES BY STAGE"}
                              titlePositionX={190}
                              titlePositionY={30}
                              barWidth={30}
                              barColor={"#4d6584"}
                              tickFormatingFunction={(x) =>
                                `${String(x).indexOf("e") != -1 ? 0 : x}`
                              }
                              xAxisImagesTopPadding={310}
                              xAxisImagesLeftInitialPadding={101}
                              xAxisImagesTickSize={29}
                              xAxisImagesMinWidth={25}
                              xAxisImagesMinHeight={25}
                              xAxisInformation={this.props.xAxisInformation}
                              width={450}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="report-container">
            <div>
              <div
                className="row position-title-blue"
                style={{ width: "120%" }}
              >
                <div className="nonavailable">
                  <img
                    className="squared-icon"
                    src="images/report-icon.svg"
                    alt=""
                  />{" "}
                  <span>Report - Detail</span>
                </div>
              </div>
              <div className="gray-bar width-100" style={{ width: "120%" }}>
                {this.props.xAxisInformation.map((info) => {
                  return info != null ? (
                    <span key={info.id}>
                      <img src={info.icon} alt="" />
                      {info.description}
                    </span>
                  ) : null;
                })}
              </div>
              <div className="report-data" style={{ width: "120%" }}>
                <div
                  style={{
                    display:
                      this.props.request === requestState.IN_PROGRESS
                        ? "none"
                        : "block",
                  }}
                >
                  {this.props.reportInformation.map((information) => {
                    return (
                      <div className="teamnametag-report" key={information.id}>
                        <div className="block">
                          <div className="profile-image">
                            <img src={information.photo} alt="" />
                          </div>
                          <span>
                            {information.firstName}&nbsp;{information.lastName}
                          </span>
                          <span className="mbs-report">
                            <img src={information.mbsImage} alt="" />
                          </span>
                        </div>
                        <br />
                        <div className="statistic-container time-spent">
                          <div className="statistic">
                            <ChartBarSimple
                              data={information.timeSpent}
                              xKey={"id"}
                              yKey={"value"}
                              containerPadding={30}
                              chartTopPadding={50}
                              chartBottomPadding={50}
                              chartRightPadding={50}
                              chartLeftPadding={85}
                              valuesInMinutes={true}
                              xTickValues={[0, 1, 2, 3, 4, 5, 6]}
                              title={"TIME SPENT BY STAGE"}
                              titlePositionX={190}
                              titlePositionY={30}
                              barWidth={30}
                              barColor={"#4d6584"}
                              tickFormatingFunction={(x) =>
                                `${
                                  String(x).indexOf("e") != -1
                                    ? 0
                                    : (parseInt(x) * 0.0167).toFixed(2)
                                } hours`
                              }
                              xAxisImagesTopPadding={310}
                              xAxisImagesLeftInitialPadding={101}
                              xAxisImagesTickSize={29}
                              xAxisImagesMinWidth={25}
                              xAxisImagesMinHeight={25}
                              xAxisInformation={this.props.xAxisInformation}
                              width={450}
                            />
                          </div>
                        </div>
                        <div className="statistic-container time-position">
                          <div className="statistic">
                            <ChartBarSimple
                              data={information.numberOfTimes}
                              xKey={"id"}
                              yKey={"value"}
                              containerPadding={30}
                              chartTopPadding={50}
                              chartBottomPadding={50}
                              chartRightPadding={50}
                              chartLeftPadding={85}
                              valuesInMinutes={false}
                              xTickValues={[0, 1, 2, 3, 4, 5, 6]}
                              title={"TIMES BY STAGE"}
                              titlePositionX={190}
                              titlePositionY={30}
                              barWidth={30}
                              barColor={"#4d6584"}
                              tickFormatingFunction={(x) =>
                                `${String(x).indexOf("e") != -1 ? 0 : x}`
                              }
                              xAxisImagesTopPadding={310}
                              xAxisImagesLeftInitialPadding={101}
                              xAxisImagesTickSize={29}
                              xAxisImagesMinWidth={25}
                              xAxisImagesMinHeight={25}
                              xAxisInformation={this.props.xAxisInformation}
                              width={450}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RotationBoardReport.propTypes = {
  // state to props
  isLoggedIn: PropTypes.bool,
  selectedOrganizationId: PropTypes.string,

  availableStatusId: PropTypes.string,
  activeStandardPositions: PropTypes.array,
  statusList: PropTypes.array,
  xAxisInformation: PropTypes.array,

  selectedDateRange: PropTypes.string,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  listOfClientAdvisorsNoFilter: PropTypes.array,
  selectedClientAdvisor: PropTypes.string,
  reportInformation: PropTypes.array,
  reportOverallTimeSpent: PropTypes.array,
  reportOverallNumberOfTimes: PropTypes.array,
  userId: PropTypes.string,

  request: PropTypes.number,
  lastRequestName: PropTypes.string,

  connection: PropTypes.bool,

  // dispatch to props
  dateRangeChange: PropTypes.func,
  updateMinDateChange: PropTypes.func,
  updateMaxDateChange: PropTypes.func,
  updateSelectedClientAdvisor: PropTypes.func,
  clearReportInformation: PropTypes.func,
  setXAxisInformation: PropTypes.func,
  fetchAllAssociatesByRole: PropTypes.func,
  getReportInformation: PropTypes.func,
  storeApplicationDimensions: PropTypes.func,
  fetchStatus: PropTypes.func,
  fetchUserCurrentStandardPosition: PropTypes.func,
  fetchLastUpdateByUserId: PropTypes.func,
  fetchStandardPositions: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    // userSession reducer
    isLoggedIn: state.userSession.isLoggedIn,
    selectedOrganizationId: state.userSession.selectedOrganizationId,

    // liveRotation reducer
    availableStatusId: state.liveRotation.availableStatusId,
    activeStandardPositions: state.liveRotation.activeStandardPositions,
    statusList: state.liveRotation.statusList,
    xAxisInformation: state.liveRotation.xAxisInformation,

    // report reducer
    selectedDateRange: state.report.selectedDateRange,
    minDate: state.report.minDate,
    maxDate: state.report.maxDate,
    listOfClientAdvisorsNoFilter: state.report.listOfClientAdvisorsNoFilter,
    selectedClientAdvisor: state.report.selectedClientAdvisor,
    reportInformation: state.report.reportInformation,
    reportOverallTimeSpent: state.report.reportOverallTimeSpent,
    reportOverallNumberOfTimes: state.report.reportOverallNumberOfTimes,

    // requestReducer reducer
    request: state.requestReducer.request,
    lastRequestName: state.requestReducer.lastRequestName,
    userId: state.userSession.uid,

    // internet reducer
    connection: state.internet.connection,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // report actions
    dateRangeChange: (newSelectedDateRange) =>
      dispatch(reportActions.dateRangeChange(newSelectedDateRange)),
    updateMinDateChange: (minDate) =>
      dispatch(reportActions.updateMinDateChange(minDate)),
    updateMaxDateChange: (maxDate) =>
      dispatch(reportActions.updateMaxDateChange(maxDate)),
    updateSelectedClientAdvisor: (clientAdvisorId) =>
      dispatch(reportActions.updateSelectedClientAdvisor(clientAdvisorId)),
    clearReportInformation: () =>
      dispatch(reportActions.clearReportInformation()),
    setXAxisInformation: () => dispatch(reportActions.setXAxisInformation()),

    // user actions
    fetchAllAssociatesByRole: (idRole, idOrganization) =>
      dispatch(userActions.fetchAllAssociatesByRole(idRole, idOrganization)),
    getReportInformation: (
      organizationId,
      selectedClientAdvisor,
      minDate,
      maxDate
    ) =>
      dispatch(
        userActions.getReportInformation(
          organizationId,
          selectedClientAdvisor,
          minDate,
          maxDate
        )
      ),

    // application actions
    storeApplicationDimensions: (dimensions) =>
      dispatch(applicationActions.storeApplicationDimensions(dimensions)),

    // liveRotation actions
    fetchStatus: (organizationId) =>
      dispatch(liveRotationActions.fetchStatus(organizationId)),
    fetchUserCurrentStandardPosition: (theUserId) =>
      dispatch(liveRotationActions.fetchUserCurrentStandardPosition(theUserId)),
    fetchLastUpdateByUserId: (userId, includeDismissed) =>
      dispatch(
        liveRotationActions.fetchLastUpdateByUserId(userId, includeDismissed)
      ),
    fetchStandardPositions: (availableStatusId) =>
      dispatch(liveRotationActions.fetchStandardPositions(availableStatusId)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RotationBoardReport);
