import React from 'react';
import SignoutButton from '../components/SignoutButton';

const MobileTopNav = () => {
    return (
        <div className="rb-topNav elt-shadow-1 width-100">
            <a id="rotation-logo">
                <img src="images/RotationBoard-logo.svg" alt="" />
            </a>
            <a className="rotation-logo-rpsv">
                <img src="images/rotation-icon.svg" alt="" />
            </a>
            <ul className="left-header">
                <SignoutButton text="" wrapInListItem={true} />
            </ul>
        </div>
    );
};

export default MobileTopNav;
