import * as types from "../constants/actionTypes";
import * as status from "../constants/statusDescriptions";
import update from "immutability-helper";
import _ from "underscore";

const initialState = {
  isLoading: false,
  statusList: [],
  standardPositionsList: [],
  availableStatusId: "",
  activeStandardPositions: [],
  allAssociatesInLiveRotation: [],
  associatesInAvailable: [],
  associatesInOnHold: [],
  associatesInGuestEngagement: [],
  associatesInWilling: [],
  associatesInOff: [],
  listOfClientAdvisors: [],
  filteredListOfClientAdvisors: [],
  userFilter: "",
  rotationLastUpdate: null,
  totalNumberOfStandardPositions: 0,
  errorMessage: "",
  currentDroppableSource: "",
  xAxisInformation: [],
  userCurrentStandardPositionDescription: "",
  userCurrentStandardPositionIcon: "",
  signalrConnectionId: "",
};

export default function liveRotationReducer(state = initialState, action) {
  let errorMessage = "";
  let assInAvailable = [];
  let assInOnHold = [];
  let assInGuestEngagement = [];
  let assInWilling = [];
  let assInOff = [];
  let tnOfStandardPositions = 0;
  let standardPositionsArray = [];

  switch (action.type) {
    /* TODO: find best way to handle errors */
    case types.FETCH_ASSOCIATES_BY_ROLE_REJECTED:
    case types.SAVE_MANAGER_CHANGES_REJECTED:
    case types.FETCH_STATUS_REJECTED:
    case types.FETCH_STANDARD_POSITIONS_REJECTED:
    case types.FETCH_ALL_ASSOCIATES_REJECTED:
    case types.INSERT_CAR_SOLD_REJECTED:
    case types.FETCH_LAST_UPDATE_REJECTED:
    case types.DISMISS_UPDATE_REJECTED: {
      if (
        action.payload !== undefined &&
        action.payload.data !== undefined &&
        action.payload.data.error !== undefined
      ) {
        errorMessage = "";
      }

      if (
        action.payload !== undefined &&
        action.payload.data !== undefined &&
        action.payload.data.error_description !== undefined
      ) {
        errorMessage = action.payload.data.error_description;
      }

      if (errorMessage !== "") {
        return { ...state, errorMessage: errorMessage };
      } else {
        return { ...state };
      }
    }

    case types.FETCH_STATUS_FULFILLED: {
      let availableStatus =
        action.payload.data !== undefined
          ? action.payload.data.filter(
              (s) => s.statusDescription === status.AVAILABLE
            )[0].statusByOrganizationId
          : "";

      
      return {
        ...state,
        statusList: action.payload.data,
        availableStatusId: availableStatus,
      };
    }

    case types.FETCH_STANDARD_POSITIONS_FULFILLED: {
      let standardPositions = action.payload.data;
      tnOfStandardPositions = 0;
      if (standardPositions != undefined) {
        for (let sp of standardPositions) {
          for (let i = 0; i < sp.totalNumberOfUsers; i++) {
            standardPositionsArray.push({
              Description: sp.standardPositionDescription,
              Icon: sp.standardPositionIcon,
              Number: sp.totalNumberOfUsers,
            });
          }
          tnOfStandardPositions +=
            sp.totalNumberOfUsers > 0 ? sp.totalNumberOfUsers : 0;
        }
      }
      return {
        ...state,
        standardPositionsList: standardPositionsArray,
        activeStandardPositions: standardPositions,
        totalNumberOfStandardPositions: tnOfStandardPositions,
      };
    }

    case types.FETCH_ALL_ASSOCIATES_FULFILLED: {
      let allAssociates = action.payload.data;

      assInAvailable =
        allAssociates !== undefined
          ? allAssociates.filter(
              (associate) =>
                associate.statusDescription === status.AVAILABLE ||
                associate.statusDescription === status.ON_HOLD
            )
          : [];
      assInAvailable = _.sortBy(assInAvailable, "sortingIndex"); // all Status come order by status and then by sortingIndex.

      assInOnHold =
        allAssociates !== undefined
          ? allAssociates.filter(
              (associate) => associate.statusDescription === status.ON_HOLD
            )
          : [];

      assInGuestEngagement =
        allAssociates !== undefined
          ? allAssociates.filter(
              (associate) =>
                associate.statusDescription === status.GUEST_ENGAGEMENT
            )
          : [];

      assInWilling =
        allAssociates !== undefined
          ? allAssociates.filter(
              (associate) => associate.statusDescription === status.WILLING
            )
          : [];

      assInOff =
        allAssociates !== undefined
          ? allAssociates.filter(
              (associate) => associate.statusDescription === status.OFF
            )
          : [];

      return {
        ...state,
        allAssociatesInLiveRotation: allAssociates,
        associatesInAvailable: assInAvailable,
        associatesInOnHold: assInOnHold,
        associatesInGuestEngagement: assInGuestEngagement,
        associatesInWilling: assInWilling,
        associatesInOff: assInOff,
        userFilter: "",
      };
    }

    case types.REORDER_ALL_ASSOCIATES_IN_STATE: {
      let allAssociates = state.allAssociatesInLiveRotation;

      assInAvailable =
        allAssociates !== undefined
          ? allAssociates.filter(
              (associate) =>
                associate.statusDescription === status.AVAILABLE ||
                associate.statusDescription === status.ON_HOLD
            )
          : [];
      assInAvailable = _.sortBy(assInAvailable, "sortingIndex"); // all Status come order by status and then by sortingIndex.

      assInOnHold =
        allAssociates !== undefined
          ? allAssociates.filter(
              (associate) => associate.statusDescription === status.ON_HOLD
            )
          : [];

      assInGuestEngagement =
        allAssociates !== undefined
          ? allAssociates.filter(
              (associate) =>
                associate.statusDescription === status.GUEST_ENGAGEMENT
            )
          : [];

      assInWilling =
        allAssociates !== undefined
          ? allAssociates.filter(
              (associate) => associate.statusDescription === status.WILLING
            )
          : [];

      assInOff =
        allAssociates !== undefined
          ? allAssociates.filter(
              (associate) => associate.statusDescription === status.OFF
            )
          : [];

      return {
        ...state,
        allAssociatesInLiveRotation: allAssociates,
        associatesInAvailable: assInAvailable,
        associatesInOnHold: assInOnHold,
        associatesInGuestEngagement: assInGuestEngagement,
        associatesInWilling: assInWilling,
        associatesInOff: assInOff,
      };
    }

    case types.FETCH_LAST_UPDATE_FULFILLED: {
      return { ...state, rotationLastUpdate: action.payload.data };
    }

    case types.DISMISS_UPDATE_FULFILLED: {
      return { ...state, rotationLastUpdate: action.payload.data };
    }

    case types.INSERT_CAR_SOLD_FULFILLED: {
      return { ...state };
    }

    case types.FETCH_ASSOCIATES_BY_ROLE_FULFILLED: {
      // remove associates already assigned to other status
      let listOfAssociatesByRole = [];
      for (let i = 0; i < action.payload.data.length; i++) {
        let id = action.payload.data[i].userId;

        if (
          !state.associatesInAvailable.some((a) => a.userId === id) &&
          !state.associatesInGuestEngagement.some((a) => a.userId === id) &&
          !state.associatesInOnHold.some((a) => a.userId === id) &&
          !state.associatesInWilling.some((a) => a.userId === id)
        ) {
          listOfAssociatesByRole.push(action.payload.data[i]);
        }
      }

      return {
        ...state,
        listOfClientAdvisors: listOfAssociatesByRole,
        filteredListOfClientAdvisors: listOfAssociatesByRole,
      };
    }

    case types.STORE_STANDARD_POSITION_NUMBER: {
      let standardPositionInfo = action.payload;

      // get index and old value of the standard position
      let index = parseInt(
        state.activeStandardPositions.findIndex(
          (sp) => sp.standardPositionByStatusId === standardPositionInfo.id
        )
      );
      let oldNumber = state.activeStandardPositions[index].totalNumberOfUsers;

      // get index and old value of the wildcard standard position
      let wildCardIndex = parseInt(
        state.activeStandardPositions.findIndex((sp) => sp.isWildcard === true)
      );
      let wildCardNumber =
        state.activeStandardPositions[wildCardIndex].totalNumberOfUsers;

      // compute the new balance for the wildcard
      let difference =
        parseInt(standardPositionInfo.newNumber) - parseInt(oldNumber);
      let newBalance = parseInt(wildCardNumber) - parseInt(difference);

      // update the standard position number
      let newStandardPositions = update(state.activeStandardPositions, {
        [index]: {
          totalNumberOfUsers: {
            $set:
              parseInt(standardPositionInfo.newNumber) > 0
                ? parseInt(standardPositionInfo.newNumber)
                : 0,
          },
        },
      });

      newStandardPositions = update(newStandardPositions, {
        [wildCardIndex]: {
          totalNumberOfUsers: {
            $set: parseInt(newBalance) > 0 ? parseInt(newBalance) : 0,
          },
        },
      });

      tnOfStandardPositions = 0;
      if (newStandardPositions != undefined) {
        for (let sp of newStandardPositions) {
          tnOfStandardPositions +=
            sp.totalNumberOfUsers > 0 ? sp.totalNumberOfUsers : 0;
        }
      }

      standardPositionsArray = [];
      if (newStandardPositions != undefined) {
        for (let sp of newStandardPositions) {
          for (let i = 1; i <= sp.totalNumberOfUsers; i++) {
            standardPositionsArray.push({
              Description: sp.standardPositionDescription,
              Icon: sp.standardPositionIcon,
            });
          }
        }
      }

      return {
        ...state,
        standardPositionsList: standardPositionsArray,
        activeStandardPositions: newStandardPositions,
        totalNumberOfStandardPositions: tnOfStandardPositions,
      };
    }

    case types.STORE_STANDARD_POSITION_MOVE_POSITION: {
      let standardPosition = action.payload;
      let changedStandardPositions = state.activeStandardPositions;

      let oldIndex = parseInt(standardPosition.index);
      let newIndex = parseInt(standardPosition.newPos);

      if (newIndex < 0) {
        newIndex = 0;
      }

      if (newIndex >= 0 && newIndex !== oldIndex) {
        // put the "moving" object in the selected place (theNewIndex)
        changedStandardPositions = update(changedStandardPositions, {
          $splice: [
            [oldIndex, 1],
            [newIndex, 0, changedStandardPositions[oldIndex]],
          ],
        });

        // updates the "SortIndex" to the correct value for each element in the array
        changedStandardPositions.forEach((currentValue, index) => {
          changedStandardPositions = update(changedStandardPositions, {
            [index]: {
              sortingIndex: { $set: index },
            },
          });
        });

        standardPositionsArray = [];
        if (changedStandardPositions != undefined) {
          for (let sp of changedStandardPositions) {
            for (let i = 1; i <= sp.totalNumberOfUsers; i++) {
              standardPositionsArray.push({
                Description: sp.standardPositionDescription,
                Icon: sp.standardPositionIcon,
              });
            }
          }
        }
      }

      return {
        ...state,
        activeStandardPositions: changedStandardPositions,
        standardPositionsList: standardPositionsArray,
      };
    }

    case types.UPDATE_USER_POSITION: {
      let theList = action.payload.list;
      let theOldIndex = action.payload.oldIndex;
      let theNewIndex = action.payload.newIndex;

      let changedListOfUsers = state[theList];

      if (theNewIndex < 0) {
        theNewIndex = 0;
      }

      if (theNewIndex >= 0 && theNewIndex !== theOldIndex) {
        // put the "moving" object in the selected place (theNewIndex)
        changedListOfUsers = update(changedListOfUsers, {
          $splice: [
            [theOldIndex, 1],
            [theNewIndex, 0, changedListOfUsers[theOldIndex]],
          ],
        });

        // put ON HOLD people in their fixed places, except if the admin is moving a user in ON HOLD (&& currentValue.SortIndex !== theOldIndex)
        changedListOfUsers.forEach((currentValue, index) => {
          if (
            currentValue.statusDescription !== null &&
            currentValue.statusDescription === status.ON_HOLD &&
            currentValue.sortingIndex !== theOldIndex
          ) {
            changedListOfUsers = update(changedListOfUsers, {
              $splice: [
                [index, 1],
                [currentValue.sortingIndex, 0, changedListOfUsers[index]],
              ],
            });
          }
        });

        // updates the "SortIndex" to the correct value for each element in the array
        changedListOfUsers.forEach((currentValue, index) => {
          changedListOfUsers = update(changedListOfUsers, {
            [index]: {
              sortingIndex: { $set: index },
            },
          });
        });
      }

      return { ...state, [theList]: changedListOfUsers };
    }

    case types.CHANGE_USER_FROM_STATUS: {
      let sourceList = action.payload.sourceList;
      let targetList = action.payload.targetList;
      let theOIndex = action.payload.oldIndex;
      let theNIndex = action.payload.newIndex;

      let draggableId = action.payload.draggableId;

      if (draggableId != null) {
        const index = state[sourceList].findIndex(
          (sl) => sl.fkUser === draggableId
        );
        if (index != -1) {
          theOIndex = index;
        }
      }

      let sourceListCopy = state[sourceList];
      let targetListCopy = state[targetList];
      let availableSourceListCopy = state.associatesInAvailable;
      let onHoldSourceListCopy = state.associatesInOnHold;
      let offSourceListCopy = state.associatesInOff;
      let listOfClientAdvisorsCopy = state.listOfClientAdvisors;
      let targetStatus = null;

      switch (targetList) {
        case "associatesInAvailable": // available
          targetStatus = status.AVAILABLE;
          break;

        case "associatesInOnHold": // on hold
          targetStatus = status.ON_HOLD;
          break;

        case "associatesInGuestEngagement": // guest engagement
          targetStatus = status.GUEST_ENGAGEMENT;
          break;

        case "associatesInWilling": // willing
          targetStatus = status.WILLING;
          break;

        case "associatesInOff": // off
          targetStatus = status.OFF;
          break;
      }

      // "from" variables
      let isFromAvailableList =
        sourceList.indexOf("associatesInAvailable") !== -1;
      let isFromOnHoldList = sourceList.indexOf("associatesInOnHold") !== -1;
      let isFromListOfClientAdvisors =
        sourceList.indexOf("listOfClientAdvisors") !== -1;

      // "goes to" variables
      let goesToAvailableList =
        targetList.indexOf("associatesInAvailable") !== -1;
      let goesToOnHoldList = targetList.indexOf("associatesInOnHold") !== -1;
      let goesToOffList = targetList.indexOf("associatesInOff") !== -1;

      // copy of arrays necessary in upcoming changes
      let newAvailableListCopy;
      let newOnHoldListCopy;
      let newOffSourceListCopy;
      //let newListOfClientAdvisorsCopy;

      if (theNIndex < 0) {
        theNIndex = 0;
      }

      if (theNIndex >= 0) {
        // get the "moving" object
        let theItem = sourceListCopy[theOIndex];

        let isInOnHoldAlready = theItem.statusDescription == status.ON_HOLD;

        // remove "moving" object from the source list, except if the source list is AVAILABLE and the target list is ON HOLD
        let filteredSourceList;
        if (isFromAvailableList && goesToOnHoldList) {
          filteredSourceList = Object.assign({}, sourceListCopy);
        } else if (isFromListOfClientAdvisors) {
          // always that a client advisor is moved from the list (of client advisors) the OFF list has to be updated
          filteredSourceList = Object.assign(
            {},
            sourceListCopy.filter((item) => item.userId !== theItem.userId)
          );

          offSourceListCopy = Object.assign(
            {},
            offSourceListCopy.filter((item) => item.userId !== theItem.userId)
          );
          newOffSourceListCopy = Object.keys(offSourceListCopy).map(
            (key) => offSourceListCopy[key]
          ); // this changes the previous result into an array
          // update immutable object using "update" helper so that the "SortIndex" is set to the
          // correct value for each element in the source list
          newOffSourceListCopy.forEach((currentValue, index) => {
            newOffSourceListCopy = update(newOffSourceListCopy, {
              [index]: {
                sortingIndex: { $set: index },
              },
            });
          });
        } else {
          filteredSourceList = Object.assign(
            {},
            sourceListCopy.filter((item) => item.userId !== theItem.userId)
          );
        }

        // and make sure that the returned object is an Array
        let newSourceListCopy = Object.keys(filteredSourceList).map(
          (key) => filteredSourceList[key]
        );

        // update immutable object using "update" helper so that the "SortIndex" is set to the
        // correct value for each element in the source list
        newSourceListCopy.forEach((currentValue, index) => {
          if (
            currentValue.statusDescription !== null &&
            currentValue.statusDescription !== status.ON_HOLD
          ) {
            newSourceListCopy = update(newSourceListCopy, {
              [index]: {
                sortingIndex: { $set: index },
              },
            });
          }
        });

        // insert the "moving" item in the target array (to the end),
        // except if:
        //  (1) the item goes to ON HOLD and is already in ON HOLD
        //  (2) the item comes from ON HOLD and goes to AVAILABLE (because it should be in AVAILABLE already)
        if (
          !(isInOnHoldAlready && goesToOnHoldList) &&
          !(isFromOnHoldList && goesToAvailableList)
        ) {
          targetListCopy = update(targetListCopy, { $push: [theItem] });

          if (goesToOffList) {
            // if the item is pushed to the OFF list, then we need to push it to the list of client advisors too
            listOfClientAdvisorsCopy = update(listOfClientAdvisorsCopy, {
              $push: [theItem],
            });
          }
        }

        // the item needs to be pushed at the end of AVAILABLE list if it is being moved from any STATUS to ON HOLD
        if (goesToOnHoldList && !isFromAvailableList) {
          newAvailableListCopy = update(availableSourceListCopy, {
            $push: [theItem],
          });

          // this is just to populate the object "newOnHoldListCopy" and avoid messing up the return in this method
          // anyways if the code reaches this point, the targetList is ON HOLD
          newOnHoldListCopy = Object.assign({}, targetListCopy);

          // update the Status of the item in AVAILABLE list
          newAvailableListCopy = update(newAvailableListCopy, {
            [newAvailableListCopy.length - 1]: {
              sortingIndex: { $set: newAvailableListCopy.length - 1 },
              statusDescription: { $set: status.ON_HOLD },
            },
          });
        }

        // move the "moving" item to the correct index except if it comes from ON HOLD and goes to AVAILABLE
        // why? because the item is already in AVAILABLE and there is no certainty of its position
        // the second "if" statement takes care of it by getting the item from the AVAILABLE list
        if (!(isFromOnHoldList && goesToAvailableList)) {
          targetListCopy = update(targetListCopy, {
            $splice: [
              [targetListCopy.length - 1, 1],
              [theNIndex, 0, targetListCopy[targetListCopy.length - 1]],
            ],
          });

          // once the item is pushed to the targeted list, we need to know if it was an ON HOLD item going
          // to a list different than AVAILABLE and ON HOLD in order to update (remove the item from)
          // the AVAILABLE and ON HOLD lists
          if (isInOnHoldAlready && !goesToAvailableList && !goesToOnHoldList) {
            availableSourceListCopy = Object.assign(
              {},
              availableSourceListCopy.filter(
                (item) => item.userId !== theItem.userId
              )
            );
            onHoldSourceListCopy = Object.assign(
              {},
              onHoldSourceListCopy.filter(
                (item) => item.userId !== theItem.userId
              )
            );

            // convert objects in array
            // and make sure that the returned object is an Array
            newAvailableListCopy = Object.keys(availableSourceListCopy).map(
              (key) => availableSourceListCopy[key]
            );
            newOnHoldListCopy = Object.keys(onHoldSourceListCopy).map(
              (key) => onHoldSourceListCopy[key]
            );
          }
        } else if (isFromOnHoldList && goesToAvailableList) {
          // find the item in the AVAILABLE list to get its SortIndex
          let itemInAvailable = targetListCopy.find(
            (item) => item.userId === theItem.userId
          );

          // move the item to the new index (theNIndex)
          targetListCopy = update(targetListCopy, {
            $splice: [
              [itemInAvailable.sortingIndex, 1],
              [theNIndex, 0, targetListCopy[itemInAvailable.sortingIndex]],
            ],
          });

          // update the Status of the item in the new index (theNIndex)
          targetListCopy = update(targetListCopy, {
            [theNIndex]: {
              statusDescription: { $set: status.AVAILABLE },
            },
          });
        }

        // update immutable object using "update" helper so that the "SortIndex" and "Status" is set to the
        // correct value for each element in the targeted list
        if (
          (!goesToOnHoldList && !goesToAvailableList) ||
          (!isFromAvailableList && !isFromOnHoldList)
        ) {
          targetListCopy.forEach((currentValue, index) => {
            if (
              goesToOffList ||
              (currentValue.statusDescription !== null &&
                currentValue.statusDescription !== status.ON_HOLD)
            ) {
              targetListCopy = update(targetListCopy, {
                [index]: {
                  sortingIndex: { $set: index },
                  statusDescription: { $set: targetStatus },
                },
              });
            } else {
              targetListCopy = update(targetListCopy, {
                $splice: [
                  [index, 1],
                  [currentValue.sortingIndex, 0, targetListCopy[index]],
                ],
              });
            }
          });
        }

        // put ON HOLD people in their fixed places, except if the admin is moving a user in ON HOLD (&& currentValue.SortIndex !== theOldIndex)
        if (isFromAvailableList || isFromOnHoldList) {
          newSourceListCopy.forEach((currentValue, index) => {
            if (
              currentValue.statusDescription !== null &&
              currentValue.statusDescription === status.ON_HOLD &&
              currentValue.sortingIndex !== theOIndex
            ) {
              newSourceListCopy = update(newSourceListCopy, {
                $splice: [
                  [index, 1],
                  [currentValue.sortingIndex, 0, newSourceListCopy[index]],
                ],
              });
            } else if (
              goesToOnHoldList &&
              currentValue.statusDescription !== null &&
              currentValue.statusDescription !== status.ON_HOLD &&
              currentValue.sortingIndex === theOIndex
            ) {
              newSourceListCopy = update(newSourceListCopy, {
                [theOIndex]: {
                  statusDescription: { $set: status.ON_HOLD },
                },
              });
            }
          });
        }

        if (isFromListOfClientAdvisors) {
          if (newAvailableListCopy !== undefined) {
            return {
              ...state,
              listOfClientAdvisors: newSourceListCopy,
              filteredListOfClientAdvisors: newSourceListCopy,
              associatesInAvailable: newAvailableListCopy,
              associatesInOff: newOffSourceListCopy,
              [sourceList]: newSourceListCopy,
              [targetList]: targetListCopy,
              userFilter: "",
            };
          } else {
            return {
              ...state,
              listOfClientAdvisors: newSourceListCopy,
              filteredListOfClientAdvisors: newSourceListCopy,
              associatesInOff: newOffSourceListCopy,
              [sourceList]: newSourceListCopy,
              [targetList]: targetListCopy,
              userFilter: "",
            };
          }
        }

        if (goesToOffList) {
          if (newAvailableListCopy !== undefined) {
            if (newOnHoldListCopy !== undefined) {
              return {
                ...state,
                [sourceList]: newSourceListCopy,
                [targetList]: targetListCopy,
                associatesInAvailable: newAvailableListCopy,
                filteredListOfClientAdvisors: listOfClientAdvisorsCopy,
                listOfClientAdvisors: listOfClientAdvisorsCopy,
                associatesInOnHold: newOnHoldListCopy,
                userFilter: "",
              };
            } else {
              return {
                ...state,
                [sourceList]: newSourceListCopy,
                [targetList]: targetListCopy,
                associatesInAvailable: newAvailableListCopy,
                filteredListOfClientAdvisors: listOfClientAdvisorsCopy,
                listOfClientAdvisors: listOfClientAdvisorsCopy,
                userFilter: "",
              };
            }
          } else {
            return {
              ...state,
              [sourceList]: newSourceListCopy,
              [targetList]: targetListCopy,
              filteredListOfClientAdvisors: listOfClientAdvisorsCopy,
              listOfClientAdvisors: listOfClientAdvisorsCopy,
              userFilter: "",
            };
          }
        }

        if (
          newAvailableListCopy !== undefined &&
          newOnHoldListCopy !== undefined
        ) {
          return {
            ...state,
            associatesInAvailable: newAvailableListCopy,
            associatesInOnHold: newOnHoldListCopy,
            [sourceList]: newSourceListCopy,
            [targetList]: targetListCopy,
          };
        }

        return {
          ...state,
          [sourceList]: newSourceListCopy,
          [targetList]: targetListCopy,
        };
      }

      return { ...state };
    }

    case types.FILTER_ASSOCIATES_BY_ROLE: {
      let filteredList = Object.assign(
        {},
        state.listOfClientAdvisors.filter(
          (associate) =>
            associate.userFirstName
              .toLowerCase()
              .indexOf(action.payload.toLowerCase()) !== -1 ||
            associate.userLastName
              .toLowerCase()
              .indexOf(action.payload.toLowerCase()) !== -1
        )
      );
      // make sure that the returned object is an Array
      let listIntoArray = Object.keys(filteredList).map(
        (key) => filteredList[key]
      );

      return {
        ...state,
        filteredListOfClientAdvisors: listIntoArray,
        userFilter: action.payload,
      };
    }

    case types.STORE_CURRENT_DROPPABLE_SOURCE: {
      return { ...state, currentDroppableSource: action.payload };
    }

    case types.REPORT_SET_X_AXIS_INFORMATION: {
      let xInfo = [];
      let localStandardPositions = Object.assign(
        {},
        state.activeStandardPositions
      );
      let localStatusList = Object.assign({}, state.statusList);

      localStandardPositions = Object.keys(localStandardPositions).map(
        (key) => localStandardPositions[key]
      );
      localStatusList = Object.keys(localStatusList).map(
        (key) => localStatusList[key]
      );

      localStandardPositions.forEach((sp) => {
        if (sp.totalNumberOfUsers > 0) {
          let item = {
            id: sp.standardPositionId,
            description: sp.standardPositionDescription,
            icon: sp.standardPositionIcon,
          };
          xInfo.push(item);
        }
      });

      localStatusList.forEach((s) => {
        if (
          s.statusDescription === status.ON_HOLD ||
          s.statusDescription === status.GUEST_ENGAGEMENT
        ) {
          let item = {
            id: s.fkStatus,
            description: s.statusDescription,
            icon: s.statusIcon,
          };
          xInfo.push(item);
        }
      });

      // now, as per request, move the OnHold to the end of the array... so look for
      // the index and splice it to the last place of the array
      let onHoldIndex = xInfo.findIndex(
        (item) => item.description === status.ON_HOLD
      );

      xInfo.push(xInfo.splice(onHoldIndex, 1)[0]);

      return { ...state, xAxisInformation: xInfo };
    }

    case types.MOBILE_FETCH_USER_CURRENT_STANDARD_POSITION: {
      let userId = action.payload;

      let record = Object.assign(
        {},
        state.allAssociatesInLiveRotation.filter(
          (item) => item.userId === userId
        )
      );

      if (
        record !== undefined &&
        record[0] !== undefined &&
        record[0].standardPositionDescription !== undefined &&
        record[0].standardPositionIcon !== undefined
      ) {
        return {
          ...state,
          userCurrentStandardPositionDescription:
            record[0].standardPositionDescription,
          userCurrentStandardPositionIcon: record[0].standardPositionIcon,
        };
      }

      return { ...state };
    }

    case types.SIGNOUT: {
      localStorage.clear();
      return { ...initialState, isLoggedIn: false };
    }
  }

  return state;
}
