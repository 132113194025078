import * as types from '../constants/actionTypes';

const initialState = {
  selectedView: "profile",
  moveToIsDisplayed: false
};

export default function mobileReducer(state = initialState, action) {
  switch (action.type) {
    case types.MOBILE_CHANGE_VIEW: {

      let isMoveTo = false;
      if (action.payload === "moveTo") {
        isMoveTo = !state.moveToIsDisplayed;
      }
      else {
        isMoveTo = false;
      }

      return { ...state, selectedView: action.payload, moveToIsDisplayed: isMoveTo };
    }

    case types.UPDATE_USER_STATUS_FULFILLED: {
      return { ...state, moveToIsDisplayed: false };
    }
  }

  return state;
}
