import React from 'react';
import PropTypes from 'prop-types';

const MobileViewAvailableList = (props) => {
    return (
        props.selectedView !== "availableList" ? null
            :
            <div className="Mobile-body av-table">
                <table>
                    <tbody>
                        <tr>
                            <th>#</th>
                            <th colSpan="2"><img className="squared-icon" src="images/available-icon.svg" alt="" />Available</th>
                        </tr>
                        {props.associatesInAvailable.map((element, index) => {
                            return (
                                <tr key={index}>
                                    <td className="table-num">{index + 1}</td>
                                    {element.standardPositionIcon !== undefined
                                        ?
                                        <td><img src={element.standardPositionIcon} alt="" /></td>
                                        : <td><img src="images/onhold-icon.svg" alt="" /></td>
                                    }
                                    <td>{element.userFirstName}&nbsp;{element.userLastName}</td>
                                </tr>);
                        })}
                    </tbody>
                </table>
            </div>
    );
};

MobileViewAvailableList.propTypes = {
    selectedView: PropTypes.string,
    associatesInAvailable: PropTypes.array
};

export default MobileViewAvailableList;
