export default {
  client: {
    grant_type: 'password',
    client_id: 'RBWeb',
    mobile_client_id: 'RBWeb',
    client_secret: '5@2^EP$ppzf$'
  },
  api: {
    eltAuth: {
      url: 'https://staging.services.eleadertech.com:9003/ELT/CAS'
    },
    productAuth: {
      url: 'https://staging.services.eleadertech.com:9003/GTR/CAS'
    },
    userServices: {
      url: 'https://staging.services.eleadertech.com:9003/GTR/RB/'
    },
    liveRotation: {
      url: 'https://staging.services.eleadertech.com:9003/GTR/RB/'
    },
    signalrUrl: {
      url: 'https://staging.services.eleadertech.com:9003/GTR/RB'
    },
    managerRotation: {
      url: 'https://staging.services.eleadertech.com:9003/GTR/RB/'
    },
    report: {
      url: 'https://staging.services.eleadertech.com:9003/GTR/RB/' 
    },
    organizationServices: {
      url: 'https://staging.services.eleadertech.com:9003/GTR/RB/',
    }
  },
  redirectAfterSignout: "https://staging.eleadertech.com/?so=1",
  expressCoachUrl: "https://staging.expresscoach.guesttrek.com",
  debug: "0"
};
  