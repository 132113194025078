import axios from 'axios';

class LiveRotationServices {
    constructor(config, productWT) {
        this.liveRotationUrl = config.api.liveRotation.url;
        this.productWebToken = "";

        if (productWT !== undefined && productWT !== "") {
            this.productWebToken = productWT;
        }
        else {
            this.productWebToken = localStorage.getItem("productWebToken");
        }

        this.axiosClient = axios.create({
            baseURL: this.liveRotationUrl,
            headers: {
                Accept: 'application/json',
                "Authorization": "Bearer " + this.productWebToken
            }
        });
    }

    fetchStatus(organizationId) {
        if (organizationId !== undefined) {
            return this.axiosClient.get(`StatusServices/Status/StatusByOrganization/${organizationId}`)
                .catch(error => {
                    return Promise.reject(error);
                });
        }
    }

    fetchStandardPositions(availableStatusId) {
        if (availableStatusId !== undefined && availableStatusId !== null && availableStatusId !== "") {
            return this.axiosClient.get(`StandardPositionServices/StandardPosition/Model/ByStatusByOrganization/${availableStatusId}`)
                .catch(error => {
                    return Promise.reject(error);
                });
        }
    }

    fetchAllAssociatesInLiveRotation(organizationId) {
        if (organizationId !== undefined && organizationId !== null) {
            return this.axiosClient.get(`LiveRotationServices/LiveRotation/ByOrganization/${organizationId}`)
                .catch(error => {
                    return Promise.reject(error);
                });
        }
    }

    fetchAllAssociatesInLiveRotationByOrganizationId(organizationId) {
        return this.axiosClient.get(`LiveRotationServices/LiveRotation/ByOrganization/${organizationId}`)
            .catch(error => {
                return Promise.reject(error);
            });
    }

    fetchLastUpdatesByOrganization(organizationId, includeDismissed) {
        return this.axiosClient.get(`RotationUpdateServices/RotationUpdate/ByOrganization/${organizationId}/IncludeDismissed/${includeDismissed}`)
            .catch(error => {
                return Promise.reject(error);
            });
    }

    fetchLastUpdateByOrganization(organizationId, includeDismissed) {
        return this.axiosClient.get(`RotationUpdateServices/RotationUpdate/LastUpdate/ByOrganization/${organizationId}/IncludeDismissed/${includeDismissed}`)
            .catch(error => {
                return Promise.reject(error);
            });
    }

    fetchLastUpdateByUserId(userId, includeDismissed) {
        return this.axiosClient.get(`RotationUpdateServices/RotationUpdate/LastUpdate/ByUser/${userId}/IncludeDismissed/${includeDismissed}`)
            .catch(error => {
                return Promise.reject(error);
            });
    }

    dismissLastUpdate(rotationUpdateObject) {
        let newUpdateObject = Object.assign({}, rotationUpdateObject);
        newUpdateObject.isDismissed = true;
        return this.axiosClient.put("RotationUpdateServices/RotationUpdate/UpdateRotationUpdate", newUpdateObject)
            .catch(error => {
                return Promise.reject(error);
            });
    }

    insertCarSold(userId, wasSold) {
        return this.axiosClient.post(`CarSalesServices/CarSale/CreateCarSale/${userId}/${wasSold}`)
            .catch(error => {
                return Promise.reject(error);
            });
    }

    fetchAllAssociatesByRole(roleId, organizationId) {
        if (roleId !== undefined && organizationId !== undefined && organizationId !== null) {
            return this.axiosClient.get(`UserServices/User/ByRoleId/${roleId}/ByOrganization/${organizationId}`)
                .catch(error => {
                    return Promise.reject(error);
                });
        }
    }

    fetchAllAssociatesLiveRotationModelByRole(roleId, organizationId) {
        if (roleId !== undefined && organizationId !== undefined) {
            return this.axiosClient.get(`UserServices/User/LiveRotationModel/ByRoleId/${roleId}/ByOrganization/${organizationId}`)
                .catch(error => {
                    return Promise.reject(error);
                });
        }
    }
}

export default LiveRotationServices;