import React from 'react';
import PropTypes from 'prop-types';
import * as utils from '../helpers/utils';
import draggableItemTypes from '../data/constants/draggableItemTypes';
import { Draggable } from 'react-beautiful-dnd';

const UserLookupRow = (props) => {
    return (
        <Draggable draggableId={"draggable-clientadvisors-" + String(props.clientAdvisor.userId)}
            type={draggableItemTypes.MANAGER_USER_CARD}
            key={props.position}
            index={props.index}
            isDragDisabled={(props.clientAdvisor !== undefined && props.clientAdvisor.user !== undefined) ? !props.clientAdvisor.user.isOnShift : false}>
            {(provided) => (
                <div className={utils.isOdd(props.position) ? "row-lookup-component odd-tag" : "row-lookup-component even-tag"}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    <div className="name-tag-associate">
                        <div className="circle-profile-image">{/*mbs-red*/}
                            <img src={props.clientAdvisor.user !== undefined ? props.clientAdvisor.user.photo : ""} alt="Profile" />

                        </div>
                        <div className="associate-info">
                            <span className="name">{props.clientAdvisor.userFirstName}&nbsp;{props.clientAdvisor.userLastName}</span>
                            <span className="email">{props.clientAdvisor.user === undefined ? "" : props.clientAdvisor.user.email ? props.clientAdvisor.user.email : ""}</span>
                        </div>
                        <div className={props.clientAdvisor.user === undefined ? "nonavailable-status" : props.clientAdvisor.user.isOnShift ? "available-status" : "nonavailable-status"} />
                    </div>
                    {provided.placeholder}
                </div>
            )}
        </Draggable>
    );
};

UserLookupRow.propTypes = {
    position: PropTypes.number,
    index: PropTypes.number,
    clientAdvisor: PropTypes.object
};

export default UserLookupRow;