import moment from 'moment';

const userSessionExtension = {
    hasExpired(expireDate) {
        const expirationDate = moment(new Date(expireDate));
        return expirationDate.isBefore(moment());
    },

    isLoggedIn(accessToken, expireDate) {
        if (accessToken === undefined || expireDate === undefined) {
            return false;
        }

        return (Boolean(accessToken) && !userSessionExtension.hasExpired(expireDate));
    },

    saveClaims(eltResponse, otherClaims, productSpecificInformation, productWebToken) {
        
        localStorage.setItem("refreshToken", eltResponse.data.refresh_token);
        localStorage.setItem("expire", eltResponse.data[".expires"]);
        localStorage.setItem("accessToken", eltResponse.data.access_token);
        localStorage.setItem("userName", eltResponse.data.userName);
        localStorage.setItem("firstName", eltResponse.data.firstName);
        localStorage.setItem("lastName", eltResponse.data.lastName);
        localStorage.setItem("userImage", productSpecificInformation.data.photo);
        localStorage.setItem("mbsImage", productSpecificInformation.data.mbsImage);

        let userOrganizations = productSpecificInformation.data.otherOrganizations.slice();
        localStorage.setItem("org", otherClaims.org.substr(0, 8) + "-" + otherClaims.org.substr(8, 4) + "-" + otherClaims.org.substr(12, 4) + "-" + otherClaims.org.substr(16, 4) + "-" + otherClaims.org.substr(20, 12));
        localStorage.setItem("uid", otherClaims.nameid.substr(0, 8) + "-" + otherClaims.nameid.substr(8, 4) + "-" + otherClaims.nameid.substr(12, 4) + "-" + otherClaims.nameid.substr(16, 4) + "-" + otherClaims.nameid.substr(20, 12));
        localStorage.setItem("initialRole", otherClaims.role);
        localStorage.setItem("productWebToken", productWebToken);
        localStorage.setItem("schedule", parseInt(productSpecificInformation.data.schedule));
        localStorage.setItem("userOrganizations", JSON.stringify(userOrganizations));
        localStorage.setItem("selectedOrganizationId", productSpecificInformation.data.defaultOrganizationId);
        localStorage.setItem("selectedOrganizationName", productSpecificInformation.data.defaultOrganizationName);
        localStorage.setItem("status", productSpecificInformation.data.status.description);
        localStorage.setItem("statusId", productSpecificInformation.data.status.id);
        localStorage.setItem("isOnShift", productSpecificInformation.data.isOnShift);
        //localStorage.setItem("platform", otherClaims.aud.indexOf("Mobile") !== -1 ? "mobile" : "web");
        localStorage.setItem("userProfile", 0);
    },

    availableDays(schedule) {
        let days = [];

        if ((schedule & 1) === 1) {
            days.push("SU");
        }

        if ((schedule & 2) === 2) {
            days.push("MO");
        }

        if ((schedule & 4) === 4) {
            days.push("TU");
        }

        if ((schedule & 8) === 8) {
            days.push("WE");
        }

        if ((schedule & 16) === 16) {
            days.push("TH");
        }

        if ((schedule & 32) === 32) {
            days.push("FR");
        }

        if ((schedule & 64) === 64) {
            days.push("SA");
        }

        return days;
    },

    daysToBit(days) {
        let sch = 0;
        
        if (days.find(x => (x === "SU"))) {
            sch = sch + Math.pow(2, 0);
        }

        if (days.find(x => (x === "MO"))) {
            sch = sch + Math.pow(2, 1);
        }

        if (days.find(x => (x === "TU"))) {
            sch = sch + Math.pow(2, 2);
        }

        if (days.find(x => (x === "WE"))) {
            sch = sch + Math.pow(2, 3);
        }

        if (days.find(x => (x === "TH"))) {
            sch = sch + Math.pow(2, 4);
        }

        if (days.find(x => (x === "FR"))) {
            sch = sch + Math.pow(2, 5);
        }

        if (days.find(x => (x === "SA"))) {
            sch = sch + Math.pow(2, 6);
        }

        return sch;
    }
};

export default userSessionExtension;