import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "../data/Application/applicationActions";
import * as offSets from "../data/constants/applicationSizeOffsets";
import { decrypt16 } from "../helpers/utils";

import { Link } from "react-router-dom";

class LeftNavigationBar extends React.Component {
  componentDidMount() {
    window.addEventListener("resize", this.updateTop.bind(this));

    setTimeout(() => {
      this.updateTop();
    }, 0); // feel this a little hacky? check this: http://stackoverflow.com/questions/26556436/react-after-render-code
  }

  componentDidUpdate() {
    setTimeout(() => {
      this.updateTop();
    }, 2000); // Looks hacky? It is.
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateTop.bind(this));
  }

  updateTop() {
    let distanceToTop = 0;
    let componentDiv = document.getElementById("leftNav");
    if (componentDiv != undefined) {
      distanceToTop =
        componentDiv.getBoundingClientRect().top + offSets.LEFT_NAVIGATION_BAR;
    }

    this.props.storeLeftNavigationBarTop(distanceToTop);
  }

  render() {
    const heightStyle = {
      minHeight:
        (
          this.props.applicationHeight - this.props.leftBarNavigationTop
        ).toString() + "px",
      height:
        (
          this.props.applicationHeight - this.props.leftBarNavigationTop
        ).toString() + "px"
    };

    let userProfileDecrypt = (this.props.userProfile !== undefined && this.props.userProfile !== null) ? decrypt16(this.props.userProfile) : "";
    let userProfileArray = (userProfileDecrypt !== undefined && userProfileDecrypt !== null) ? String(userProfileDecrypt).split(',') : "";

    return (
      <div
        id="leftNav"
        className="rb-leftNav elt-shadow-2"
        style={
          this.props.applicationWidth > 0 &&
            this.props.applicationWidth >
            offSets.LEFT_NAVIGATION_MAX_WIDTH_THRESHOLD
            ? heightStyle
            : {}
        }
      >
        { userProfileArray.includes("3") ||
          userProfileArray.includes("2") ||
          userProfileArray.includes("1") ||
          userProfileArray.includes("0") ? (
            <Link to="/liveRotation">
              <img src="images/home-icon.svg" alt="Home" />
            </Link>
          ) : null}

        {userProfileArray.includes("3") || userProfileArray.includes("1") ? (
          <Link to="/manager">
            <img src="images/RotationManager-icon.svg" alt="Edit Rotation" />
          </Link>
        ) : null}

        {userProfileArray.includes("3") ? (
          <Link to="/report">
            <img src="images/report-icon.svg" alt="Report" />
          </Link>
        ) : null}

        <Link to="/profile">
          <i className="material-icons md-light" style={{ color: 'white', marginLeft: "-2px" }}>account_box</i>
        </Link>

        {userProfileArray.includes("3") ? (
          <Link to="/settings">
            <i className="material-icons md-light" style={{ color: 'white', marginLeft: "-2px" }}>settings</i>
          </Link>
        ) : null} 

        {/* <Link to="/offline"><img src="images/padlock.svg" alt="Offline" /></Link> */}
      </div>
    );
  }
}

LeftNavigationBar.propTypes = {
  applicationHeight: PropTypes.number,
  applicationWidth: PropTypes.number,
  leftBarNavigationTop: PropTypes.number,
  userProfile: PropTypes.string,

  storeLeftNavigationBarTop: PropTypes.func
};

function mapStateToProps(state) {
  return {
    applicationWidth: state.application.applicationWidth,
    applicationHeight: state.application.applicationHeight,
    leftBarNavigationTop: state.application.leftBarNavigationTop,
    userProfile: state.userSession.userProfile
  };
}

export default connect(
  mapStateToProps,
  actions
)(LeftNavigationBar);
