import { combineReducers } from "redux";
import liveRotationReducer from "./data/LiveRotation/liveRotationReducer";
import userSessionReducer from "./data/UserSession/userSessionReducer";
import applicationReducer from "./data/Application/applicationReducer";
import organizationReducer from "./data/Organization/organizationReducer";
import managerReducer from "./data/Manager/managerReducer";
import requestReducer from "./data/Request/requestReducer";
import reportReducer from "./data/Report/reportReducer";
import mobileReducer from "./data/Mobile/mobileReducer";
import { reducer as toastrReducer } from "react-redux-toastr";
import { reducer as internetReducer } from "react-redux-internet-connection";

const rootReducer = combineReducers({
  toastr: toastrReducer,
  liveRotation: liveRotationReducer,
  userSession: userSessionReducer,
  application: applicationReducer,
  manager: managerReducer,
  requestReducer: requestReducer,
  report: reportReducer,
  mobile: mobileReducer,
  internet: internetReducer,
  organization: organizationReducer,
});

export default rootReducer;
