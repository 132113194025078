import React from "react";
import PropTypes from "prop-types";

const ChartBarCustomLabel = (props) => {
  const totalWidth = 300;
  const numberOfActiveStandards = props.xAxisInformation.length;
  const widthItem = totalWidth / numberOfActiveStandards;

  const getSeparationIndex = (numberOfActiveStandards) => {
    switch (numberOfActiveStandards) {
      case 4:
        return 1.14;
      case 5:
        return 1.07;
      case 6:
        return 1.02;
      case 7:
        return 0.99;
      default:
        return 1.14;
    }
  };

  return (
    <foreignObject
      className="icon-axis"
      y={props.xAxisImagesTopPadding}
      x={props.xAxisImagesLeftInitialPadding + props.index * getSeparationIndex(numberOfActiveStandards) * widthItem}
      width={props.xAxisImagesMinWidth}
      height={props.xAxisImagesMinHeight}
    >
      {props.xAxisInformation[props.index] !== undefined ? (
        <img
          style={{
            minHeight: String(props.xAxisImagesMinHeight) + "px",
            minWidth: String(props.xAxisImagesMinWidth) + "px",
            width: String(props.xAxisImagesMinWidth) + "px",
            height: String(props.xAxisImagesMinHeight) + "px",
          }}
          src={props.xAxisInformation[props.index].icon}
          alt=" "
          key={props.xAxisInformation[props.index].id}
        />
      ) : null}
    </foreignObject>
  );
};

ChartBarCustomLabel.propTypes = {
  xAxisInformation: PropTypes.array,
  index: PropTypes.number,
  xAxisImagesTopPadding: PropTypes.number,
  xAxisImagesLeftInitialPadding: PropTypes.number,
  xAxisImagesTickSize: PropTypes.number,
  xAxisImagesMinWidth: PropTypes.number,
  xAxisImagesMinHeight: PropTypes.number,
};

export default ChartBarCustomLabel;
