import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//import { Redirect } from 'react-router-dom';
import * as statusDescription from "../data/constants/statusDescriptions";
import * as rotationActions from "../data/LiveRotation/liveRotationActions";
import * as applicationActions from "../data/Application/applicationActions";
import * as securityRoles from "../data/constants/securityRoles";
import TopNavigationBar from "./TopNavigationBar";
import LastUpdate from "./LastUpdate";
import FooterStandardPositions from "../components/FooterStandardPositions";
import StandardPositionContainer from "./StandardPositionContainer";
import StandardStatusContainer from "./StandardStatusContainer";
import Offline from "../components/Offline";
import screenfull from "screenfull";
import config from "../configuration/config";
import { hubConnection } from "signalr-no-jquery";
import { toastr } from "react-redux-toastr";

let connection = null;
class LiveRotation extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      this.handlePageLoading();
    }, 100); // Looks hacky? It is.
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
    //stop the Signal R connection
    if (connection != null) connection.stop();
  }

  handlePageLoading() {
    let self = this;
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.updateDimensions();
    this.props.fetchAllAssociatesInLiveRotation(
      this.props.selectedOrganizationId
    );
    this.props.storeAvailableSelectedPage(0);

    connection = hubConnection(`${config.api.signalrUrl.url}`);
    const hubProxy = connection.createHubProxy("rotationHub");

    // set up event listeners i.e. for incoming "message" event
    hubProxy.on("addMessage", function (message) {
      switch (message) {
        case "UpdateUserStatus": {
          self.props.fetchAllAssociatesInLiveRotation(
            self.props.selectedOrganizationId
          );
          self.retrieveUpdate();
        }
      }
    });

    // connect
    connection
      .start({ jsonp: true })
      .done(function () {
        toastr.info(" ", "Real time connection established.", {
          transitionIn: "fadeIn",
          transitionOut: "fadeOut",
          timeOut: 1200,
        });
      })
      .fail(function (err) {
        toastr.info(" ", `Real time could not connect: ${err}`);
      });
  }

  handleFullScreen() {
    this.props.storeAvailableSelectedPage(0);
    const theContainer = document.getElementById("mainContainer");
    if (screenfull.isEnabled) {
      screenfull.request(theContainer);
    }
  }

  updateDimensions() {
    // compute the dimensions of the screen
    let w = window;
    let d = document;
    let documentElement = d.documentElement;
    let body = d.getElementsByTagName("body")[0];
    let theWidth =
      w.innerWidth || documentElement.clientWidth || body.clientWidth;
    let theHeight =
      w.innerHeight || documentElement.clientHeight || body.clientHeight;

    this.props.storeApplicationDimensions({
      width: theWidth,
      height: theHeight,
    });
  }

  retrieveUpdate() {
    switch (this.props.userInitialRole) {
      case securityRoles.User:
      case securityRoles.Associate:
      case securityRoles.ClientAdvisor:
        this.props.fetchLastUpdateByUserId(this.props.userId, false);
        break;
      case securityRoles.DealershipAdmin:
      case securityRoles.GlobalAdmin:
      case securityRoles.Admin:
        this.props.fetchLastUpdate();
        break;
    }
  }

  renderOtherStatusContainers() {
    if (
      this.props.statusList != undefined &&
      this.props.statusList.length > 0
    ) {
      let statusContainersToRender = this.props.statusList.map(
        (theStatus, index) => {
          return theStatus.statusDescription != statusDescription.AVAILABLE ? (
            <StandardStatusContainer
              key={index}
              status={theStatus}
              allowScrolling={false}
              format="grid"
            />
          ) : null;
        }
      );

      return statusContainersToRender;
    }
  }

  render() {
    return !this.props.connection ? (
      <Offline />
    ) : (
      <div id="RB-Dashboard">
        <TopNavigationBar />
        <div id="mainContainer">
          <h1 className="main-title">GuestTrek Rotation</h1>
          <LastUpdate />
          <div
            id="liveRotationContainer"
            className="rb-card elt-shadow-2 margin-rspv"
          >
            <div
              className="fullscreen-btn"
              onClick={this.handleFullScreen.bind(this)}
            >
              <img
                className="fullscreen-icon"
                src="images/FullScreen-icon.svg"
                alt=""
              />
            </div>
            <StandardPositionContainer
              automaticPagingChangeOn={true}
              allowScrolling={false}
            />
            {this.renderOtherStatusContainers()}
            <div className="position-explanation">
              <FooterStandardPositions
                activeStandardPositions={
                  this.props.activeStandardPositions === undefined
                    ? []
                    : this.props.activeStandardPositions
                }
              />
            </div>
          </div>
          <div className="explanation-rpsv">
            <FooterStandardPositions
              activeStandardPositions={
                this.props.activeStandardPositions === undefined
                  ? []
                  : this.props.activeStandardPositions
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

LiveRotation.propTypes = {
  activeStandardPositions: PropTypes.array,
  statusList: PropTypes.array,
  rotationLastUpdate: PropTypes.object,

  applicationHeight: PropTypes.number,
  liveRotationContainerTop: PropTypes.number,

  isLoggedIn: PropTypes.bool,
  selectedOrganizationId: PropTypes.string,
  userInitialRole: PropTypes.string,
  userId: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  organization: PropTypes.string,

  connection: PropTypes.bool,

  storeApplicationDimensions: PropTypes.func,
  storeAvailableSelectedPage: PropTypes.func,
  fetchAllAssociatesInLiveRotation: PropTypes.func,
  fetchLastUpdate: PropTypes.func,
  fetchLastUpdateByUserId: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    // liveRotation reducer
    activeStandardPositions: state.liveRotation.activeStandardPositions,
    statusList: state.liveRotation.statusList,
    rotationLastUpdate: state.liveRotation.rotationLastUpdate,

    // application reducer
    applicationHeight: state.application.applicationHeight,
    liveRotationContainerTop: state.application.liveRotationContainerTop,

    // userSession reducer
    isLoggedIn: state.userSession.isLoggedIn,
    selectedOrganizationId: state.userSession.selectedOrganizationId,
    userInitialRole: state.userSession.initialRole,
    userId: state.userSession.userId,
    firstName: state.userSession.firstName,
    lastName: state.userSession.lastName,
    organization: state.userSession.org,

    // internet
    connection: state.internet.connection,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // application actions
    storeApplicationDimensions: (width, height) =>
      dispatch(applicationActions.storeApplicationDimensions(width, height)),
    storeAvailableSelectedPage: (thePage) =>
      dispatch(applicationActions.storeAvailableSelectedPage(thePage)),

    // rotation acitons
    fetchAllAssociatesInLiveRotation: (organizationId) =>
      dispatch(
        rotationActions.fetchAllAssociatesInLiveRotation(organizationId)
      ),
    fetchLastUpdate: () => dispatch(rotationActions.fetchLastUpdate()),
    fetchLastUpdateByUserId: (userId, includeDismissed) =>
      dispatch(
        rotationActions.fetchLastUpdateByUserId(userId, includeDismissed)
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveRotation);
