import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as mobileActions from '../data/Mobile/mobileActions';
import * as liveRotationActions from '../data/LiveRotation/liveRotationActions';
import * as userActions from '../data/UserSession/userSessionActions';
import * as requestActions from '../data/Request/requestActions';
import * as requestState from '../data/constants/requestStates';
import * as types from '../data/constants/actionTypes';
import Offline from '../components/Offline';
import MobileTopNav from '../components/MobileTopNav';
import MobileViewProfile from '../components/MobileViewProfile';
import MobileViewAvailableList from '../components/MobileViewAvailableList';
import MobileBottomMenu from '../components/MobileBottomMenu';
import config from '../configuration/config';
import { hubConnection } from 'signalr-no-jquery';

class LiveRotationPageMobile extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        let self = this;
        setTimeout(() => { this.props.fetchAllAssociatesInLiveRotation(this.props.selectedOrganizationId); }, 0); // feel this a little hacky? check this: http://stackoverflow.com/questions/26556436/react-after-render-code
        setTimeout(() => { this.props.fetchStatus(this.props.selectedOrganizationId); }, 0); // feel this a little hacky? check this: http://stackoverflow.com/questions/26556436/react-after-render-code
        setTimeout(() => { this.props.fetchLastUpdateByUserId(this.props.userId, true); }, 0); // feel this a little hacky? check this: http://stackoverflow.com/questions/26556436/react-after-render-code
        setTimeout(() => { this.props.fetchUserCurrentStandardPosition(this.props.userId); }, 0); // feel this a little hacky? check this: http://stackoverflow.com/questions/26556436/react-after-render-code

        const connection = hubConnection(`${config.api.signalrUrl.url}`);
        const hubProxy = connection.createHubProxy('rotationHub');

        // set up event listeners i.e. for incoming "message" event
        hubProxy.on("addMessage", function (message) {
            switch (message) {
                case "UpdateUserStatus": {
                    self.props.fetchAllAssociatesInLiveRotation(self.props.selectedOrganizationId);
                    self.props.fetchLastUpdateByUserId(self.props.userId, true);
                    self.props.fetchUserCurrentStandardPosition(self.props.userId);
                }
            }
        });

        // connect
        connection.start({ jsonp: true })
            .done(setTimeout(() => connection.start({ jsonp: true }), 5000))
            .fail(setTimeout(() => connection.start({ jsonp: true }), 5000));
    }

    componentDidUpdate() {
        if (this.props.request === requestState.SUCCESS) {
            switch (this.props.lastRequestName) {
                case types.FETCH_ALL_ASSOCIATES_POST:
                case types.UPDATE_USER_STATUS_POST:
                    this.props.fetchAllAssociatesInLiveRotation(this.props.selectedOrganizationId);
                    this.props.fetchLastUpdateByUserId(this.props.userId, true);
                    this.props.fetchUserCurrentStandardPosition(this.props.userId);
                    break;
            }
            this.props.clearRequest();
        }
    }

    handleChangeOfView(target) {
        this.props.changeMobileView(target.currentTarget.id);
    }

    handleUpdateUserStatusSelection(newStatusId) {
        this.props.dispatchUpdateUserStatus(this.props.userId, newStatusId);
    }

    render() {
        return (
            !this.props.connection ? <Offline /> :

                <div className="mobileView mob-100-percent">
                    <div className="TopMobNav">
                        <MobileTopNav />

                        <MobileViewProfile userImage={this.props.userImage}
                            firstName={this.props.firstName}
                            lastName={this.props.lastName}
                            selectedView={this.props.selectedView}
                            status={this.props.status}
                            userCurrentStandardPositionDescription={this.props.userCurrentStandardPositionDescription}
                            userCurrentStandardPositionIcon={this.props.userCurrentStandardPositionIcon}
                            lastUpdate={this.props.lastUpdate} />

                        <MobileViewAvailableList selectedView={this.props.selectedView}
                            associatesInAvailable={this.props.associatesInAvailable} />

                        <MobileBottomMenu changeMobileView={this.handleChangeOfView.bind(this)}
                            selectedView={this.props.selectedView}
                            moveToIsDisplayed={this.props.moveToIsDisplayed}
                            currentStatus={this.props.status}
                            statusList={this.props.statusList}
                            isOnShift={this.props.isOnShift}
                            handleUpdateUserStatusSelection={this.handleUpdateUserStatusSelection.bind(this)} />
                    </div>
                </div>
        );
    }
}

LiveRotationPageMobile.propTypes = {
    // userSession
    userImage: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    status: PropTypes.string,
    userId: PropTypes.string,
    selectedOrganizationId: PropTypes.string,
    isOnShift: PropTypes.bool,
    organization: PropTypes.string,

    // mobile
    selectedView: PropTypes.string,
    moveToIsDisplayed: PropTypes.bool,

    // live rotation
    userCurrentStandardPositionDescription: PropTypes.string,
    userCurrentStandardPositionIcon: PropTypes.string,
    lastUpdate: PropTypes.object,
    statusList: PropTypes.array,
    associatesInAvailable: PropTypes.array,

    // request
    request: PropTypes.number,
    lastRequestName: PropTypes.string,

    // internet
    connection: PropTypes.bool,

    // dispatch to props
    changeMobileView: PropTypes.func,
    fetchUserCurrentStandardPosition: PropTypes.func,
    fetchLastUpdateByUserId: PropTypes.func,
    fetchStatus: PropTypes.func,
    dispatchUpdateUserStatus: PropTypes.func,
    clearRequest: PropTypes.func,
    fetchAllAssociatesInLiveRotation: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        // userSession reducer
        userImage: state.userSession.userImage,
        firstName: state.userSession.firstName,
        lastName: state.userSession.lastName,
        status: state.userSession.status,
        userId: state.userSession.uid,
        selectedOrganizationId: state.userSession.selectedOrganizationId,
        isOnShift: state.userSession.isOnShift,
        organization: PropTypes.string,

        // mobile reducer
        selectedView: state.mobile.selectedView,
        moveToIsDisplayed: state.mobile.moveToIsDisplayed,

        // live rotation reducer
        userCurrentStandardPositionDescription: state.liveRotation.userCurrentStandardPositionDescription,
        userCurrentStandardPositionIcon: state.liveRotation.userCurrentStandardPositionIcon,
        lastUpdate: state.liveRotation.rotationLastUpdate,
        statusList: state.liveRotation.statusList,
        associatesInAvailable: state.liveRotation.associatesInAvailable,

        // requestReducer reducer
        request: state.requestReducer.request,
        lastRequestName: state.requestReducer.lastRequestName,

        // internet
        connection: state.internet.connection,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        // mobile actions
        changeMobileView: (theView) => dispatch(mobileActions.changeMobileView(theView)),

        // live rotation actions
        fetchUserCurrentStandardPosition: (theUserId) => dispatch(liveRotationActions.fetchUserCurrentStandardPosition(theUserId)),
        fetchLastUpdateByUserId: (userId, includeDismissed) => dispatch(liveRotationActions.fetchLastUpdateByUserId(userId, includeDismissed)),
        fetchStatus: (organizationId) => dispatch(liveRotationActions.fetchStatus(organizationId)),
        fetchAllAssociatesInLiveRotation: (organizationId) => dispatch(liveRotationActions.fetchAllAssociatesInLiveRotation(organizationId)),

        // user actions
        dispatchUpdateUserStatus: (userId, newStatusId) => dispatch(userActions.updateUserStatus(userId, newStatusId)),

        // request actions
        clearRequest: () => dispatch(requestActions.clearRequest())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveRotationPageMobile);
