import * as types from '../constants/actionTypes';
import config from '../../configuration/config';
import LiveRotationServices from '../services/LiveRotationServices';

/*****/
/* RETRIEVES ALL AVAILABLE STATUS SETUP FOR THE DEALER */
export function fetchStatus(organizationId) {
  return function (dispatch) {
    let liveRotationServices = new LiveRotationServices(config);
    liveRotationServices.fetchStatus(organizationId)
      .then(response => {
        dispatch({ type: types.FETCH_STATUS_FULFILLED, payload: response });
      })
      .catch(error => {
        dispatch({ type: types.FETCH_STATUS_REJECTED, payload: error.response });
      });
  };
}

/*****/
/* RETRIEVES THE COMPLETE LIST OF STANDARD POSITIONS SETUP BY THE DEALERSHIP */
export function fetchStandardPositions(availableStatusId) {
  return function (dispatch) {
    let liveRotationServices = new LiveRotationServices(config);
    liveRotationServices.fetchStandardPositions(availableStatusId)
      .then(response => {
        dispatch({ type: types.FETCH_STANDARD_POSITIONS_FULFILLED, payload: response });
      })
      .catch(error => {
        dispatch({ type: types.FETCH_STANDARD_POSITIONS_REJECTED, payload: error.response });
      });
  };
}

/*****/
/* RETRIEVES ALL ASSOCIATES IN THE LIVE ROTATION */
export function fetchAllAssociatesInLiveRotation(organizationId) {
  if (organizationId !== null) {
    return function (dispatch) {
      let liveRotationServices = new LiveRotationServices(config);
      dispatch({ type: types.REQUEST_POSTED, payload: types.FETCH_ALL_ASSOCIATES_POST });
      liveRotationServices.fetchAllAssociatesInLiveRotation(organizationId)
        .then(response => {
          dispatch({ type: types.FETCH_ALL_ASSOCIATES_FULFILLED, payload: response });
          dispatch({ type: types.REQUEST_FULFILLED });
        })
        .catch(error => {
          dispatch({ type: types.FETCH_ALL_ASSOCIATES_REJECTED, payload: error.response });
          dispatch({ type: types.REQUEST_REJECTED });
        });
    };
  }
}

export function reorderAllAssociatesInState() {
  return function (dispatch) {
    dispatch({ type: types.REORDER_ALL_ASSOCIATES_IN_STATE });
  };
}

/*****/
/* RETRIEVES THE LAST NOT DISMISSED UPDATE RECORD FROM THE DATABASE */
export function fetchLastUpdate() {
  if (localStorage.getItem("selectedOrganizationId") !== null) {
    return function (dispatch) {
      let liveRotationServices = new LiveRotationServices(config);
      liveRotationServices.fetchLastUpdateByOrganization(localStorage.getItem("selectedOrganizationId"), false)
        .then(response => {
          dispatch({ type: types.FETCH_LAST_UPDATE_FULFILLED, payload: response });
        })
        .catch(error => {
          dispatch({ type: types.FETCH_LAST_UPDATE_REJECTED, payload: error.response });
        });
    };
  }
}

/*****/
/* RETRIEVES THE LAST NOT DISMISSED UPDATE RECORD FROM THE DATABASE */
export function fetchLastUpdateByUserId(userId, includeDismissed = false) {
  return function (dispatch) {
    let liveRotationServices = new LiveRotationServices(config);
    liveRotationServices.fetchLastUpdateByUserId(userId, includeDismissed)
      .then(response => {
        dispatch({ type: types.FETCH_LAST_UPDATE_FULFILLED, payload: response });
      })
      .catch(error => {
        dispatch({ type: types.FETCH_LAST_UPDATE_REJECTED, payload: error.response });
      });
  };
}

/*****/
/* MARK A GIVEN UPDATE AS DISMISSED */
export function dismissLastUpdate(rotationUpdateObject) {
  return function (dispatch) {
    let liveRotationServices = new LiveRotationServices(config);
    liveRotationServices.dismissLastUpdate(rotationUpdateObject)
      .then(response => {
        dispatch({ type: types.DISMISS_UPDATE_FULFILLED, payload: response });
      })
      .catch(error => {
        dispatch({ type: types.DISMISS_UPDATE_REJECTED, payload: error.response });
      });
  };
}

/*****/
/* INSERT A RECORD TO STORE WHETHER OR NOT A CAR WAS SOLD BY THE GIVEN USER */
export function insertCarSold(userId, wasSold) {
  return function (dispatch) {
    let liveRotationServices = new LiveRotationServices(config);
    dispatch({ type: types.REQUEST_POSTED, payload: types.INSERT_CAR_SOLD_POSTS });
    liveRotationServices.insertCarSold(userId, wasSold)
      .then(response => {
        dispatch({ type: types.INSERT_CAR_SOLD_FULFILLED, payload: response });
        dispatch({ type: types.REQUEST_FULFILLED });
      })
      .catch(error => {
        dispatch({ type: types.INSERT_CAR_SOLD_REJECTED, payload: error.response });
        dispatch({ type: types.REQUEST_REJECTED });
      });
  };
}

export function storeStandardPositionNumber(id, newNumber) {
  let data = { id, newNumber };
  return ({ type: types.STORE_STANDARD_POSITION_NUMBER, payload: data });
}

export function storeStandardPositionMovePosition(index, newPos) {
  return function (dispatch) {
    let data = { index, newPos };
    dispatch({ type: types.STORE_STANDARD_POSITION_MOVE_POSITION, payload: data });
  };
}

export function updateUserPosition(list, oldIndex, newIndex) {
  let data = { list, oldIndex, newIndex };
  return ({ type: types.UPDATE_USER_POSITION, payload: data });
}

export function changeUserFromStatus(sourceList, targetList, draggableId, oldIndex, newIndex) {
  let data = { sourceList, targetList, draggableId, oldIndex, newIndex };
  return ({ type: types.CHANGE_USER_FROM_STATUS, payload: data });
}

export function filterAssociatesByRole(value) {
  return function (dispatch) {
    dispatch({ type: types.FILTER_ASSOCIATES_BY_ROLE, payload: value });
  };
}

export function storeCurrentDroppableSource(initialList) {
  return function (dispatch) {
    dispatch({ type: types.STORE_CURRENT_DROPPABLE_SOURCE, payload: initialList });
  };
}

export function fetchUserCurrentStandardPosition(userId) {
  return function (dispatch) {
    dispatch({ type: types.MOBILE_FETCH_USER_CURRENT_STANDARD_POSITION, payload: userId });
  };
}

