import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import LoginPage from '../containers/LoginPage';
import ManageRotation from '../containers/ManagerRotationPage';
import RotationBoardReport from '../containers/RotationBoardReport';
import LiveRotationPage from '../containers/LiveRotationPage';
import LiveRotationPageMobile from '../containers/LiveRotationPageMobile';
import UnderConstruction from '../containers/UnderConstructionPage';
import SignOutPage from '../containers/SignoutPage';
import MyProfile from '../containers/MyProfile';
import Settings from '../containers/Settings';

import AdminPage from '../containers/AdminPage';
import Security from '../containers/Security';
import Roles from '../containers/Roles';
import Offline from '../components/Offline';

import { DragDropContext } from 'react-beautiful-dnd';
import draggableItemTypes from '../data/constants/draggableItemTypes';
import ReduxToastr from 'react-redux-toastr';

import * as rotationActions from '../data/LiveRotation/liveRotationActions';

import { ReactReduxInternetConnection } from 'react-redux-internet-connection';


class App extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  onDragStart = (start) => {

    this.props.storeCurrentDroppableSource(start.source.droppableId);

    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  };

  onDragEnd = (result) => {
    this.props.storeCurrentDroppableSource("");

    if (!result.destination) { return; }

    if (result.draggableId.indexOf("-nouser") !== -1) {
      // if there are no user in the draggable then it keeps the name pattern: "[draggablename]-[index]-[userId || nouser]", for instance "draggable-users-available-30-nouser", or "draggable-users-available-3-5"
      // cancel drop if there are not a valid user in the draggable
      return;
    }

    let sourceIndex = result.source.index;
    let destinationIndex = result.destination.index;  
    let draggableId = result.draggableId.split('-clientadvisors-')[1];

    switch (result.type) {
      case draggableItemTypes.STANDARD_POSITION: {
        this.props.storeStandardPositionMovePosition(sourceIndex, destinationIndex);
        break;
      }
      case draggableItemTypes.MANAGER_USER_CARD: {

        if (!(result.draggableId.indexOf("clientadvisors") !== -1)) {
          // this is probaly a bug in the library --> destination index is starting in -1
          // https://github.com/atlassian/react-beautiful-dnd/issues/35
          let maxIndexInDroppable = parseInt(result.draggableId.split(':')[1]);
          if (sourceIndex > destinationIndex && sourceIndex !== maxIndexInDroppable) {
            destinationIndex += 1;
          }
        }

        if (!(result.draggableId.indexOf("clientadvisors") !== -1)) {
          draggableId = null; 
        }

        // just changing the order of users in the same droppable (for instance, changing the order of users in Willing)
        // this is not permitted in the ON HOLD status
        if (result.source.droppableId === result.destination.droppableId) {
          this.props.updateUserPosition(result.source.droppableId, sourceIndex, destinationIndex);
        }
        else { // moving one user to another droppable (for instance, moving a user from Willing to Off)
          this.props.changeUserFromStatus(result.source.droppableId, result.destination.droppableId, draggableId, sourceIndex, destinationIndex);
        }
        break;
      }
    }
  };

  getStyle = () => {
    if (this.props.platform === "mobile") {
      return "mob-100-percent";
    }

    return " ";
  }

  render() {

    return (
      <MuiThemeProvider>
        <DragDropContext
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}>
          <div className={this.getStyle()}>
            <Switch>
              <Route exact path="/" component={LoginPage} />
              <Route path="/login" component={LoginPage} />
              <Route path="/loginMobile/:uuid" component={LoginPage} />
              <Route path="/loginCas/:refreshToken" component={LoginPage} />
              <Route path="/manager" component={ManageRotation} />
              <Route path="/report" component={RotationBoardReport} />
              <Route path="/liveRotation" component={LiveRotationPage} />
              <Route path="/liveRotationPageMobile" component={LiveRotationPageMobile} />
              <Route path="/signout" component={SignOutPage} />
              <Route path="/profile" component={MyProfile}/>
              <Route path="/settings" component={Settings}/>
              {/* Are following used? */}
              <Route path="/admin" component={AdminPage} />
              <Route path="/security" component={Security} />
              <Route path="/roles" component={Roles} />
              <Route path="/underConstruction" component={UnderConstruction} />
              <Route path="/offline" component={Offline} />
            </Switch>
            <ReduxToastr
              timeOut={4000}
              newestOnTop={true}
              preventDuplicates={true}
              position="top-center"
              progressBar={true}
            />
            <ReactReduxInternetConnection />
          </div>
        </DragDropContext>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
  isLoggedIn: PropTypes.bool,
  platform: PropTypes.string,
  history: PropTypes.object,
  storeStandardPositionMovePosition: PropTypes.func,
  updateUserPosition: PropTypes.func,
  changeUserFromStatus: PropTypes.func,
  storeCurrentDroppableSource: PropTypes.func
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.userSession.isLoggedIn,
    platform: state.userSession.platform
  };
}

function mapDispatchToProps(dispatch) {
  return {
    storeStandardPositionMovePosition: (index, newPos) => dispatch(rotationActions.storeStandardPositionMovePosition(index, newPos)),
    updateUserPosition: (list, oldIndex, newIndex) => dispatch(rotationActions.updateUserPosition(list, oldIndex, newIndex)),
    changeUserFromStatus: (sourceList, targetList, draggableId, oldIndex, newIndex) => dispatch(rotationActions.changeUserFromStatus(sourceList, targetList, draggableId, oldIndex, newIndex)),
    storeCurrentDroppableSource: (sourceList) => dispatch(rotationActions.storeCurrentDroppableSource(sourceList)), 
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
