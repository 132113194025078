import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as userSessionActions from '../data/UserSession/userSessionActions';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import AutoComplete from 'material-ui/AutoComplete';

class SelectedOrganization extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            organizationModalIsOpen: false,
            searchText: "",
        };
    }

    componentDidUpdate() {
        if (this.props.organizationSwitchedFlag === true) {
            this.props.authenticateWithRefreshToken(this.props.newSelectedOrganizationId);
        }
    }

    handleUpdateInput = (searchText) => {
        this.setState({
            searchText: searchText,
        });
    };

    handleNewRequest = (value) => {
        if (value !== null) {
            this.props.clearDataForSwitchingOrganization(value.id);
        }

        this.setState({
            searchText: "",
            organizationModalIsOpen: false,
        });
    };

    handleClickOpen() {
        this.setState({ organizationModalIsOpen: true });
    }

    handleClose() {
        this.setState({ organizationModalIsOpen: false });
    }

    render() {

        const dataSourceConfig = {
            text: 'name',
            value: 'id',
        };

        return (
            <li style={{ borderRightWidth: '1px', borderRightStyle: 'solid' }}>
                <a className="logout-btn" onClick={this.handleClickOpen.bind(this)}>
                    <img src="images/swap_horizontal.png" alt="" />
                    <span>{this.props.selectedOrganizationName}</span>
                </a>
                <Dialog
                    open={this.state.organizationModalIsOpen}
                >
                    <div style={{ textAlign: "center" }}>
                        <AutoComplete
                            hintText="Start typing"
                            dataSource={JSON.parse(this.props.userOrganizations)}
                            dataSourceConfig={dataSourceConfig}
                            searchText={this.state.searchText}
                            onUpdateInput={this.handleUpdateInput}
                            onNewRequest={this.handleNewRequest}
                            filter={(searchText, key) => (key.indexOf(searchText) !== -1)}
                            openOnFocus={true}
                            fullWidth={true}
                        />
                    </div>
                    <div style={{ float: "right" }}>
                        <RaisedButton label="Close" onClick={this.handleClose.bind(this)} />
                    </div>
                </Dialog>
            </li>
        );
    }
}

SelectedOrganization.propTypes = {
    //userActions
    selectedOrganizationId: PropTypes.string,
    selectedOrganizationName: PropTypes.string,
    userOrganizations: PropTypes.string, // it's a stringified json object. In the bind will be parsed to JSON
    organizationSwitchedFlag: PropTypes.bool,
    newSelectedOrganizationId: PropTypes.string,

    authenticateWithRefreshToken: PropTypes.func,
    clearDataForSwitchingOrganization: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        selectedOrganizationId: state.userSession.selectedOrganizationId,
        selectedOrganizationName: state.userSession.selectedOrganizationName,
        userOrganizations: state.userSession.userOrganizations,
        organizationSwitchedFlag: state.userSession.organizationSwitchedFlag,
        newSelectedOrganizationId: state.userSession.newSelectedOrganizationId,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        clearDataForSwitchingOrganization: (newSelectedOrganizationId) => dispatch(userSessionActions.clearDataForSwitchingOrganization(newSelectedOrganizationId)),
        authenticateWithRefreshToken: (newSelectedOrganizationId) => dispatch(userSessionActions.authenticateWithRefreshToken(newSelectedOrganizationId))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedOrganization);