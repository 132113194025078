import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as rotationActions from "../data/LiveRotation/liveRotationActions";
import * as userActions from "../data/UserSession/userSessionActions";
import * as requestActions from "../data/Request/requestActions";
import * as statusDescription from "../data/constants/statusDescriptions";
import LeftNavigationBar from "./LeftNavigationBar";
import TopUserProfile from "../components/TopUserProfile";
import TopStatusDropdown from "../components/TopStatusDropdown";
import SignoutButton from "../components/SignoutButton";
import Offline from "../components/Offline";
import SelectedOrganization from "./SelectedOrganization";
import config from "../configuration/config";
class TopNavigationBar extends React.Component {
  componentDidMount() {
    if (
      this.props.statusList !== undefined &&
      this.props.statusList.length <= 0
    ) {
      this.props.fetchStatus(this.props.selectedOrganizationId);
    }
  }

  renderStatusList() {
    if (this.props.statusList != undefined) {
      let isOnShiftValue = false;

      if (this.props.isOnShift === true) {
        isOnShiftValue = true;
      } else if (this.props.isOnShift === "true") {
        isOnShiftValue = true;
      } else if (this.props.isOnShift === "false") {
        isOnShiftValue = false;
      } else if (this.props.isOnShift === false) {
        isOnShiftValue = false;
      } else {
        isOnShiftValue = true;
      }

      let willingIsVisible =
        !isOnShiftValue &&
        this.props.userCurrentStatus !== statusDescription.WILLING;
      let availableIsVisible =
        this.props.userCurrentStatus === statusDescription.ON_HOLD
          ? true
          : isOnShiftValue &&
            (this.props.userCurrentStatus === statusDescription.ON_HOLD ||
              this.props.userCurrentStatus ===
                statusDescription.GUEST_ENGAGEMENT ||
              this.props.userCurrentStatus === statusDescription.OFF) &&
            this.props.userCurrentStatus !== statusDescription.AVAILABLE;
      let onHoldIsVisible =
        this.props.userCurrentStatus === statusDescription.AVAILABLE;
      let guestEngagementIsVisible =
        this.props.userCurrentStatus === statusDescription.AVAILABLE;

      let statuses = this.props.statusList.map((theStatus, index) => {
        if (
          theStatus.statusDescription === statusDescription.OFF &&
          this.props.userCurrentStatus !== statusDescription.OFF
        ) {
          return (
            <a
              key={index}
              onClick={this.handleUpdateUserStatusSelection.bind(
                this,
                theStatus.statusByOrganizationId
              )}
            >
              <img src={theStatus.statusIcon} alt="" />
              <span>{theStatus.statusDescription}</span>
            </a>
          );
        }

        if (
          willingIsVisible &&
          theStatus.statusDescription === statusDescription.WILLING
        ) {
          return (
            <a
              key={index}
              onClick={this.handleUpdateUserStatusSelection.bind(
                this,
                theStatus.statusByOrganizationId
              )}
            >
              <img src={theStatus.statusIcon} alt="" />
              <span>{theStatus.statusDescription}</span>
            </a>
          );
        }

        if (
          availableIsVisible &&
          theStatus.statusDescription === statusDescription.AVAILABLE
        ) {
          return (
            <a
              key={index}
              onClick={this.handleUpdateUserStatusSelection.bind(
                this,
                theStatus.statusByOrganizationId
              )}
            >
              <img src={theStatus.statusIcon} alt="" />
              <span>{theStatus.statusDescription}</span>
            </a>
          );
        }

        if (
          onHoldIsVisible &&
          theStatus.statusDescription === statusDescription.ON_HOLD
        ) {
          return (
            <a
              key={index}
              onClick={this.handleUpdateUserStatusSelection.bind(
                this,
                theStatus.statusByOrganizationId
              )}
            >
              <img src={theStatus.statusIcon} alt="" />
              <span>{theStatus.statusDescription}</span>
            </a>
          );
        }

        if (
          guestEngagementIsVisible &&
          theStatus.statusDescription === statusDescription.GUEST_ENGAGEMENT
        ) {
          return (
            <a
              key={index}
              onClick={this.handleUpdateUserStatusSelection.bind(
                this,
                theStatus.statusByOrganizationId
              )}
            >
              <img src={theStatus.statusIcon} alt="" />
              <span>{theStatus.statusDescription}</span>
            </a>
          );
        }
      });

      return statuses;
    }
  }

  statusButtonStyle(description) {
    switch (description) {
      case "Off":
        return "position-dropbtn off";
      case "Available":
        return "position-dropbtn available";
      case "On Hold":
        return "position-dropbtn on-hold";
      case "Guest Engagement":
        return "position-dropbtn guest-engagement";
      case "Willing":
        return "position-dropbtn willing";
      default:
        return "position-dropbtn off";
    }
  }

  renderSelectedStatus() {
    if (
      this.props.statusList != undefined &&
      this.props.selectedStatus != undefined &&
      this.props.statusList.length > 0
    ) {
      let theSelectedStatus = this.props.statusList.find(
        (status) => status.statusDescription === this.props.selectedStatus
      );

      return theSelectedStatus !== undefined ? (
        <button
          className={this.statusButtonStyle(
            theSelectedStatus.statusDescription
          )}
        >
          <img src={theSelectedStatus.statusIcon} alt="" />
          <span>{theSelectedStatus.statusDescription}</span>
        </button>
      ) : (
        <div />
      );
    }
  }

  handleUpdateUserStatusSelection(newStatusId) {
    this.props.dispatchUpdateUserStatus(this.props.userId, newStatusId);
  }

  render() {
    return !this.props.connection ? (
      <Offline />
    ) : (
      <div>
        <div>
          <div id="topNav" className="rb-topNav elt-shadow-1 width-100">
            <div className="menu-buton">
              <input id="check01" type="checkbox" name="menu" />
              <label htmlFor="check01">
                <img src="images/menu-icon.png" alt="" />
              </label>
              <ul className="submenu">
                <li>
                  <a
                    href={`${config.expressCoachUrl}?rt=${this.props.userInfo.refreshToken}&cid=${config.client.client_id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="images/ExpressCoachIcon.png" alt="" />
                    ExpressCoach<sup>&reg;</sup>
                  </a>
                </li>
              </ul>
            </div>
            {/* <a className="menu-buton" href="#"><img src="images/menu-icon.png" alt="" /></a> */}
            <a id="rotation-logo" href="#">
              <img src="images/RotationBoard-logo.svg" alt="Rotation-Logo" />
            </a>
            <a className="rotation-logo-rpsv" href="#">
              <img src="images/rotation-icon.svg" alt="Rotation-Logo" />
            </a>

            <ul className="left-header">
              <TopUserProfile user={this.props.userInfo} />
              <TopStatusDropdown
                selectedStatus={this.renderSelectedStatus()}
                allStatus={this.renderStatusList()}
              />
              <SelectedOrganization />
              <SignoutButton text="Sign Out" wrapInListItem={true} />
            </ul>
          </div>
          <LeftNavigationBar />
        </div>
      </div>
    );
  }
}

TopNavigationBar.propTypes = {
  statusList: PropTypes.array,
  isLoggedIn: PropTypes.bool.isRequired,
  selectedStatus: PropTypes.string,
  userInfo: PropTypes.object.isRequired,
  isOnShift: PropTypes.string,
  userCurrentStatus: PropTypes.string,
  userCurrentStatusId: PropTypes.string,
  userId: PropTypes.string,
  request: PropTypes.number,
  lastRequestName: PropTypes.string,
  errorMessage: PropTypes.string,

  connection: PropTypes.bool,

  //userActions
  dispatchUpdateUserStatus: PropTypes.func,
  signOut: PropTypes.func.isRequired,
  selectedOrganizationId: PropTypes.string,
  selectedOrganizationName: PropTypes.string,

  //rotationActions
  fetchStatus: PropTypes.func.isRequired,
  insertCarSold: PropTypes.func,

  clearRequest: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    // liveRotation reducer
    statusList: state.liveRotation.statusList,
    errorMessage: state.liveRotation.errorMessage,

    // userSession reducer
    isLoggedIn: state.userSession.isLoggedIn,
    selectedStatus: state.userSession.status,
    userInfo: state.userSession,
    isOnShift: state.userSession.isOnShift,
    userCurrentStatus: state.userSession.status,
    usercurrentStatusId: state.userSession.statusId,
    userId: state.userSession.uid,
    selectedOrganizationId: state.userSession.selectedOrganizationId,
    selectedOrganizationName: state.userSession.selectedOrganizationName,

    // requestReducer reducer
    request: state.requestReducer.request,
    lastRequestName: state.requestReducer.lastRequestName,

    // internet reducer
    connection: state.internet.connection,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // rotationActions
    fetchStatus: (organizationId) =>
      dispatch(rotationActions.fetchStatus(organizationId)),
    insertCarSold: (userId, wasSold) =>
      dispatch(rotationActions.insertCarSold(userId, wasSold)),

    // userActions
    dispatchUpdateUserStatus: (userId, newStatusId) =>
      dispatch(userActions.updateUserStatus(userId, newStatusId)),

    signOut: () => dispatch(userActions.signOut()),
    // requestActions
    clearRequest: () => dispatch(requestActions.clearRequest()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopNavigationBar);
