import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as userSessionActions from '../data/UserSession/userSessionActions';
import * as requestState from '../data/constants/requestStates';

import TopNavigationBar from './TopNavigationBar';
import Avatar from 'material-ui/Avatar';
import TextField from 'material-ui//TextField';
import RaisedButton from 'material-ui/RaisedButton';
import Spinner from 'react-spinkit';
import { toastr } from 'react-redux-toastr';

class MyProfile extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            firstName: this.props.firstName,
            middleName: this.props.middleName,
            lastName: this.props.lastName,
            active: true,
            title: this.props.title,
            birthDate: "01/01/0001",
            userName: this.props.userName,
            email: this.props.email,
            mobilePhone: this.props.mobilePhone,
            phoneNumber: this.props.phoneNumber,
            imagePath: this.props.imagePath,
            MBSImagePath: this.props.MBSImagePath,

            sunday: this.props.sunday,
            monday: this.props.monday,
            tuesday: this.props.tuesday,
            wednesday: this.props.wednesday,
            thursday: this.props.thursday,
            friday: this.props.friday,
            saturday: this.props.saturday
        };

        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.loadCasUserInfo();
    }

    componentDidMount() {
        setTimeout(() => { this.loadCasUserInfo(); }, 0);
    }

    componentWillReceiveProps(newProps) {
        if (this.state.firstName !== newProps.firstName && newProps.firstName !== null) {
            this.setState({ firstName: newProps.firstName });
        }

        if (this.state.lastName !== newProps.lastName && newProps.lastName !== null) {
            this.setState({ lastName: newProps.lastName });
        }

        if (this.state.title !== newProps.title && newProps.title !== null) {
            this.setState({ title: newProps.title });
        }

        if (this.state.phoneNumber !== newProps.phoneNumber && newProps.phoneNumber !== null) {
            this.setState({ phoneNumber: newProps.phoneNumber });
        }

        if (newProps.userSaved !== null) {
            newProps.userSaved ? toastr.info(" ", "All information saved successfully!") : toastr.warning(" ", "There was an error and the information was not saved");
            this.props.clearUserSavedFlag();
        }
    }

    loadCasUserInfo() {
        this.props.retrieveCasUser();
    }

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleCheckboxChange = prop => event => {
        this.setState({ [prop]: event.target.checked });
    }

    handleDiscardChanges() {
        this.setState({
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            title: this.props.title,
            userName: this.props.userName,
            email: this.props.email,
            phoneNumber: this.props.phoneNumber,

            sunday: this.props.sunday,
            monday: this.props.monday,
            tuesday: this.props.tuesday,
            wednesday: this.props.wednesday,
            thursday: this.props.thursday,
            friday: this.props.friday,
            saturday: this.props.saturday
        });
    }

    handleSaveChanges() {
        this.props.updateUser(this.state);
    }

    render() {
        return (
            <div>
                <TopNavigationBar />
                <div className="header">
                    <h1 className="main-title">My Profile</h1>
                </div>
                <div className="big-container">
                    <div style={{ width: "555px", height: "100%" }} className="tile tile-blue elt-shadow-3">
                        <h1>Profile Information</h1>
                        <Avatar style={{ width: "70px", height: "70px", padding: "2px", margin: "5px" }} src={this.props.imagePath} />

                        <div style={{ width: "100", backgroundColor: "rgb(255, 204, 0)", height: "10px" }} />
                        <button disabled={true} style={{ width: "40px", height: "40px", borderRadius: "50%", backgroundColor: "rgb(231, 71, 0)", display: "flex", cursor: "pointer", float: "right", marginRight: "15px", marginTop: "-24px" }}>
                            <i className="material-icons md-light" style={{ color: 'white' }}>camera_alt</i>
                        </button>
                        <div style={{ display: "flex", alignItems: "center", width: "95%" }}>
                            <div style={{ display: "flex", flexDirection: "column", flexBasis: "100%", flex: "1" }}>
                                <TextField style={{ marginLeft: "10px", width: "99%", float: "left" }} floatingLabelText="First Name" value={this.state.firstName} onChange={this.handleChange('firstName')} />
                                <TextField style={{ marginLeft: "10px", width: "100%", float: "right" }} floatingLabelText="Title" value={this.state.title} onChange={this.handleChange('title')} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", flexBasis: "100%", flex: "1" }}>
                                <TextField style={{ marginLeft: "10px", width: "99%", float: "left" }} floatingLabelText="Last Name" value={this.state.lastName} onChange={this.handleChange('lastName')} />
                                <TextField style={{ marginLeft: "10px", width: "99%", float: "left" }} floatingLabelText="Work Phone" value={this.state.phoneNumber} onChange={this.handleChange('phoneNumber')} />
                            </div>
                        </div>
                        <div style={{ flexGrow: "1", padding: "15px", marginTop: "5px", boxSizing: "border-box" }}>
                            <span>Schedule:</span>
                            <div style={{ paddingTop: "10px", width: "102%" }}>
                                <input style={{ verticalAlign: "text-top" }} type="checkbox" name="sunday" id="sunday" checked={this.state.sunday} onChange={this.handleCheckboxChange('sunday')} /><span>SUN</span>&nbsp;&nbsp;&nbsp;
                                <input style={{ verticalAlign: "text-top" }} type="checkbox" name="monday" id="monday" defaultChecked={this.state.monday} onChange={this.handleCheckboxChange('monday')} /><span>MON</span>&nbsp;&nbsp;&nbsp;
                                <input style={{ verticalAlign: "text-top" }} type="checkbox" name="tuesday" id="tuesday" defaultChecked={this.state.tuesday} onChange={this.handleCheckboxChange('tuesday')} /><span>TUE</span>&nbsp;&nbsp;&nbsp;
                                <input style={{ verticalAlign: "text-top" }} type="checkbox" name="wednesday" id="wednesday" defaultChecked={this.state.wednesday} onChange={this.handleCheckboxChange('wednesday')} /><span>WED</span>&nbsp;&nbsp;&nbsp;
                                <input style={{ verticalAlign: "text-top" }} type="checkbox" name="thursday" id="thursday" defaultChecked={this.state.thursday} onChange={this.handleCheckboxChange('thursday')} /><span>THU</span>&nbsp;&nbsp;&nbsp;
                                <input style={{ verticalAlign: "text-top" }} type="checkbox" name="friday" id="friday" defaultChecked={this.state.friday} onChange={this.handleCheckboxChange('friday')} /><span>FRI</span>&nbsp;&nbsp;&nbsp;
                                <input style={{ verticalAlign: "text-top" }} type="checkbox" name="saturday" id="saturday" defaultChecked={this.state.saturday} onChange={this.handleCheckboxChange('saturday')} /><span>SAT</span>
                            </div>
                        </div>
                        <div style={{ flexGrow: "1", padding: "15px", backgroundColor: "#F2F2F2", boxSizing: "border-box" }}>
                            <RaisedButton label="Save" primary={true} onClick={this.handleSaveChanges.bind(this)} disabled={this.props.request === requestState.IN_PROGRESS} />&nbsp;&nbsp;
                            <RaisedButton label="Discard Changes" onClick={this.handleDiscardChanges.bind(this)} disabled={this.props.request === requestState.IN_PROGRESS} />&nbsp;&nbsp;
                            <Spinner style={{ display: this.props.request === requestState.IN_PROGRESS ? 'inline-block' : 'none' }} fadeIn="none" name="three-bounce" color="steelblue" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

MyProfile.propTypes = {
    retrieveCasUser: PropTypes.func,
    updateUser: PropTypes.func,
    clearUserSavedFlag: PropTypes.func,

    //userImage: PropTypes.string,
    imagePath: PropTypes.string,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    title: PropTypes.string,
    userName: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    mobilePhone: PropTypes.string,
    MBSImagePath: PropTypes.string,

    sunday: PropTypes.bool,
    monday: PropTypes.bool,
    tuesday: PropTypes.bool,
    wednesday: PropTypes.bool,
    thursday: PropTypes.bool,
    friday: PropTypes.bool,
    saturday: PropTypes.bool,

    userSaved: PropTypes.bool,

    request: PropTypes.number,
};

function mapStateToProps(state) {
    return {
        //userSession reducer
        imagePath: state.userSession.userImage,
        firstName: state.userSession.firstName,
        lastName: state.userSession.lastName,
        title: state.userSession.title,
        userName: state.userSession.userName,
        email: state.userSession.email,
        phoneNumber: state.userSession.workPhoneNumber,
        mobilePhone: state.userSession.mobilePhone,
        MBSImagePath: state.userSession.mbsImage,

        sunday: state.userSession.sunday,
        monday: state.userSession.monday,
        tuesday: state.userSession.tuesday,
        wednesday: state.userSession.wednesday,
        thursday: state.userSession.thursday,
        friday: state.userSession.friday,
        saturday: state.userSession.saturday,

        userSaved: state.userSession.userSaved,

        // requestReducer reducer
        request: state.requestReducer.request,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        // userSession actions
        retrieveCasUser: (userId) => dispatch(userSessionActions.retrieveCasUser(userId)),
        updateUser: (theUser) => dispatch(userSessionActions.updateUser(theUser)),
        clearUserSavedFlag: () => dispatch(userSessionActions.clearUserSavedFlag())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);