import React from 'react';
import PropTypes from 'prop-types';
import * as userSessionActions from '../data/UserSession/userSessionActions';
import { Redirect } from 'react-router-dom';
import { TextField } from 'material-ui';
import { connect } from 'react-redux';
import userSessionExtension from '../data/UserSession/userSessionExtensions';
import { grey300 } from 'material-ui/styles/colors';
import { isMobile } from 'react-device-detect';

class LoginPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.clearAll();

        this.state = {
            userSession: { userName: "", password: "" },
            logginWithRefreshToken: false
        };

        this.handleLogin = this.handleLogin.bind(this);
        this.onUserNameChange = this.onUserNameChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);

        if (isMobile) {
            this.props.storePlatform("mobile");
        }
        else {
            this.props.storePlatform("web");
        }

        // if the refresh token comes in the query string
        let refreshToken = this.props.match.params.refreshToken;

        if (refreshToken != null && refreshToken != undefined && refreshToken !== "undefined" && refreshToken !== '') {

            localStorage.setItem("refreshToken", refreshToken);
            
            this.props.authenticateWithRefreshToken(this.props.platform);
        }

        // if the access token has expired and refresh token is in the state
        if (this.props.userSession.refreshToken &&
            this.props.userSession.expire &&
            !userSessionExtension.hasExpired(this.props.userSession.expire)) {

            this.props.authenticateWithRefreshToken(this.props.platform);
        }
    }

    onUserNameChange(event) {
        const userSession = this.state.userSession;
        userSession.userName = event.target.value;
        this.setState({ userSession: userSession });
    }

    onPasswordChange(event) {
        const userSession = this.state.userSession;
        userSession.password = event.target.value;
        this.setState({ userSession: userSession });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.props.authenticateUser(
                this.state.userSession.userName,
                this.state.userSession.password,
                this.props.match.params.uuid,
                this.props.platform
            );
        }
    }

    handleLogin() {
        this.props.authenticateUser(
            this.state.userSession.userName,
            this.state.userSession.password,
            this.props.match.params.uuid,
            this.props.platform
        );
    }

    renderErrorMessage() {
        if (this.props.errorMessage) {
            return (<div className="error" > {this.props.errorMessage} </div>);
        }
    }

    clearAll() {
        localStorage.clear();
        sessionStorage.clear();
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            let eqPos = cookie.indexOf("=");
            let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }

    render() {

        const styles = {
            underlineStyle: {
                color: grey300
            },
            underlineFocusStyle: {
                color: grey300
            },
            floatingLabelStyle: {
                color: grey300
            },
            floatingLabelFocusStyle: {
                color: grey300
            }
        };

        const styleLogin = localStorage.refreshToken ? {display: 'none'} : {};
        const styleToken = localStorage.refreshToken ? {} : {display: 'none'};

        if (this.props !== undefined && this.props.userSession !== undefined && this.props.userSession.isLoggedIn === false) {
            return (
                <div id="login">

                    <div className="login-bg" style={styleToken}>
                        <div className="center-loading">
                            <div className="lds-ring">
                                <img className="logo-selected" src={'../images/ELT-Logo-small.png'} />
                                <div className="rings" />
                                <div className="rings" />
                                <div className="rings" />
                                <div className="rings" />
                            </div>
                        </div>
                    </div>

                    <div className="login-container" style={styleLogin}>
                        <img className="login-logo" src="../images/RotationBoard-logo.svg" alt="" />
                        <TextField floatingLabelText="Username"
                            onChange={this.onUserNameChange.bind(this)}
                            onKeyPress={this.handleKeyPress.bind(this)}
                            value={this.state.userSession.userName}
                            underlineStyle={styles.underlineStyle}
                            underlineFocusStyle={styles.underlineFocusStyle}
                            floatingLabelStyle={styles.floatingLabelStyle}
                            floatingLabelFocusStyle={styles.floatingLabelFocusStyle}
                            required />
                        <TextField floatingLabelText="Password"
                            onChange={this.onPasswordChange.bind(this)}
                            onKeyPress={this.handleKeyPress.bind(this)}
                            value={this.state.userSession.password}
                            underlineStyle={styles.underlineStyle}
                            underlineFocusStyle={styles.underlineFocusStyle}
                            floatingLabelStyle={styles.floatingLabelStyle}
                            floatingLabelFocusStyle={styles.floatingLabelFocusStyle}
                            type="password"
                            required />
                        <br />
                        <div className="login_submit"
                            onClick={this.handleLogin.bind(this)}>Login</div>
                        <br />
                        <br />
                        {this.renderErrorMessage()}
                        <div className="bottom-container">
                            <img className="elt-logo" src="../images/eLeaderTechLogoWhiteWhithShadow.svg" alt="ELT Logo" />
                        </div>
                    </div>

                </div>
            );
        }
        else {
            if (this.props.platform === "web") {
                return (
                    <Redirect to="/liveRotation" />
                );
            }
            else if (this.props.platform === "mobile") {
                return (
                    <Redirect to="/liveRotationPageMobile" />
                );
            }
            else {
                return (
                    <Redirect to="/liveRotation" />
                );
            }
        }
    }
}

LoginPage.propTypes = {
    userSession: PropTypes.object.isRequired,
    errorMessage: PropTypes.string,
    location: PropTypes.object,
    platform: PropTypes.string,
    match: PropTypes.object,
    params: PropTypes.object,
    uuid: PropTypes.string,

    authenticateWithRefreshToken: PropTypes.func.isRequired,
    authenticateUser: PropTypes.func.isRequired,
    storePlatform: PropTypes.func
};

function mapStateToProps(state) {
    return {
        // userSession reducer
        userSession: state.userSession,
        errorMessage: state.userSession.error,
        platform: state.userSession.platform,
        uuid: state.userSession.uuid,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        // userSession actions
        authenticateWithRefreshToken: () => dispatch(userSessionActions.authenticateWithRefreshToken()),
        authenticateUser: (userName, password, uuid) => dispatch(userSessionActions.authenticateUser(userName, password, uuid)),
        storePlatform: (thePlatform) => dispatch(userSessionActions.storePlatform(thePlatform))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);