import * as types from "../constants/actionTypes";

const initialState = {
  organization: null,
  organiaztionId: 0,
  autoRotateMinutes: 0,
  allowUsersSelfManagement: false,
};

export default function OrganizationReducer(state = initialState, action) {
  switch (action.type) {
    case types.ORGANIZATION_RETRIEVE_BY_ID: {
      const { data } = action.payload;
      return {
        ...state,
        organization: data,
        autoRotateMinutes: data != null ? data.autoRotateMinutes : 0,
        allowUsersSelfManagement:
          data != null ? data.allowUsersSelfManagement : 0,
      };
    }
  }

  return state;
}
