import * as types from '../constants/actionTypes';
import config from '../../configuration/config';
import jwt_decode from 'jwt-decode';
import AuthenticationServices from '../services/AuthenticationServices';
import UserServices from '../services/UserServices';
import LiveRotationServices from '../services/LiveRotationServices';
import userSessionExtension from './userSessionExtensions';
//import * as liveRotationActions from '../LiveRotation/liveRotationActions';
//import { fetchAllAssociatesInLiveRotation } from '../LiveRotation/liveRotationActions';

const authenticationServices = new AuthenticationServices(config);
let userServices = new UserServices(config);
let liveRotationServices = new LiveRotationServices(config);

/*****/
/* AUTHENTICATES THE USER USING GIVEN USERNAME AND PASSWORD */
export function authenticateUser(userName, password, uuid) {
  return function (dispatch) {
    authenticationServices.authenticateWithCredentials(userName, password)
      .then(eltResponse => {
        authenticationServices.authorize(eltResponse.data.access_token)
          .then(productResponse => {
            let productWebToken = productResponse.data.access_token;
            let userId = jwt_decode(productResponse.data.access_token).nameid;
            userId = userId.substr(0, 8) + "-" + userId.substr(8, 4) + "-" + userId.substr(12, 4) + "-" + userId.substr(16, 4) + "-" + userId.substr(20, 12);
            userServices = new UserServices(config, productWebToken);

            if (uuid !== null && typeof uuid === 'string') {
              let exists = uuid.indexOf('uuid=');
              if (exists != -1) {
                uuid = uuid.replace('uuid=', '');
              }
            }

            if (uuid != null && uuid != undefined && uuid !== "undefined" && uuid !== '' && uuid !== '0' && uuid !== 0) {

              userServices.createUuidByUser(userId, uuid)
                .then(() => {
                  dispatch({ type: types.CREATE_UUID_BY_USER_FULFILLED, payload: uuid });
                  //dispatch({ type: types.REQUEST_FULFILLED });
                })
                .catch(error => {
                  dispatch({ type: types.CREATE_UUID_BY_USER_REJECTED, payload: error.response });
                  //dispatch({ type: types.REQUEST_REJECTED });
                });
            }

            userServices.getProductSpecificUserData(userId, "00000000-0000-0000-0000-000000000000")
              // use default organization in the authentication since there is no way for the user to select the organization beforehand
              .then(productSpecificResponse => {
                liveRotationServices = new LiveRotationServices(config, productWebToken);
                liveRotationServices.fetchAllAssociatesInLiveRotationByOrganizationId(productSpecificResponse.data.defaultOrganizationId)
                  .then(lvResponse => {
                    let data = { eltResponse, productResponse, productSpecificResponse };
                    dispatch({ type: types.AUTHENTICATE, payload: data });
                    dispatch({ type: types.FETCH_ALL_ASSOCIATES_FULFILLED, payload: lvResponse });

                    userServices.getRolByUserId(userId)
                      .then(getRolByUserIdResult => {
                        dispatch({ type: types.GET_ROLE_BY_USER_ID_FULFILLED, payload: getRolByUserIdResult.data });
                        dispatch({ type: types.REQUEST_POSTED, payload: types.GET_ROLE_BY_USER_ID_FULFILLED });
                        location.reload(); // yeah, I know. But I need the switch organization feature in prod asap
                      })
                      .catch(error => {
                        dispatch({ type: types.GET_ROLE_BY_USER_ID_REJECTED, payload: error.response });
                      });

                  })
                  .catch(error => {
                    dispatch({ type: types.FETCH_ALL_ASSOCIATES_REJECTED, payload: error.response });
                  });
              })
              .catch((error) => {
                dispatch({ type: types.AUTH_ERROR, payload: error.response });
              });
          })
          .catch((error) => {
            dispatch({ type: types.AUTH_ERROR, payload: error.response });
          });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_ERROR, payload: error.response });
      });
  };
}

/*****/
/* AUTHENTICATES THE USER USING A SAVED REFRESH TOKEN */
export function authenticateWithRefreshToken(toOrganizationId = "00000000-0000-0000-0000-000000000000") {
  const refreshToken = localStorage.getItem('refreshToken');

  return function (dispatch) {
    authenticationServices.authenticateWithRefreshToken(refreshToken)
      .then(eltResponse => {
        authenticationServices.authorize(eltResponse.data.access_token)
          .then(productResponse => {
            let productWebToken = productResponse.data.access_token;
            let userId = jwt_decode(productResponse.data.access_token).nameid;
            userId = userId.substr(0, 8) + "-" + userId.substr(8, 4) + "-" + userId.substr(12, 4) + "-" + userId.substr(16, 4) + "-" + userId.substr(20, 12);

            let theOrganizationId = toOrganizationId;

            userServices = new UserServices(config, productWebToken);

            // uuid is used only for push notifications in mobile app
            const uuid = localStorage.getItem('uuid');
            if (uuid != null && uuid != undefined && uuid !== "undefined" && uuid !== '' && uuid !== '0' && uuid !== 0) {

              userServices.createUuidByUser(userId, uuid)
                .then(() => {
                  dispatch({ type: types.CREATE_UUID_BY_USER_FULFILLED, payload: uuid });
                  //dispatch({ type: types.REQUEST_FULFILLED });
                })
                .catch(error => {
                  dispatch({ type: types.CREATE_UUID_BY_USER_REJECTED, payload: error.response });
                  //dispatch({ type: types.REQUEST_REJECTED });
                });
            }

            userServices.getProductSpecificUserData(userId, theOrganizationId)
              .then(productSpecificResponse => {
                liveRotationServices = new LiveRotationServices(config, productWebToken);
                liveRotationServices.fetchAllAssociatesInLiveRotationByOrganizationId(productSpecificResponse.data.defaultOrganizationId)
                  .then(lvResponse => {
                    let data = { eltResponse, productResponse, productSpecificResponse };
                    dispatch({ type: types.AUTHENTICATE, payload: data });
                    dispatch({ type: types.FETCH_ALL_ASSOCIATES_FULFILLED, payload: lvResponse });

                    userServices.getRolByUserId(userId)
                      .then(getRolByUserIdResult => {
                        dispatch({ type: types.GET_ROLE_BY_USER_ID_FULFILLED, payload: getRolByUserIdResult.data });
                        dispatch({ type: types.REQUEST_POSTED, payload: types.GET_ROLE_BY_USER_ID_FULFILLED });
                        location.reload(); // yeah, I know. But I need the switch organization feature in prod asap
                      })
                      .catch(error => {
                        dispatch({ type: types.GET_ROLE_BY_USER_ID_REJECTED, payload: error.response });
                      });

                  })
                  .catch(error => {
                    dispatch({ type: types.FETCH_ALL_ASSOCIATES_REJECTED, payload: error.response });
                  });
              })
              .catch((error) => {
                dispatch({ type: types.AUTH_ERROR, payload: error.response });
              });
          })
          .catch((error) => {
            dispatch({ type: types.AUTH_ERROR, payload: error.response });
          });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_ERROR, payload: error.response });
      });
  };
}

/*****/
/* JUST CALL THE ACTION SIGNOUT */
export function signOut() {

  let userId = localStorage.getItem("uid");
  let uuid = localStorage.getItem("uuid");

  // return ({ type: types.SIGNOUT });
  return function (dispatch) {

    if (uuid != null && uuid != undefined && uuid !== "undefined" && uuid !== '' && uuid !== '0' && uuid !== 0) {
      let userServices = new UserServices(config);
      userServices.removeUuidByUser(userId, uuid)
        .then(() => {
          //dispatch({ type: types.CREATE_UUID_BY_USER_FULFILLED, payload: uuid });
          //dispatch({ type: types.REQUEST_FULFILLED });
        })
        .catch(() => {
          //dispatch({ type: types.CREATE_UUID_BY_USER_REJECTED, payload: error.response });
          //dispatch({ type: types.REQUEST_REJECTED });
        });
    }

    dispatch({ type: types.SIGNOUT });

  };
}

/*****/
/* UPDATES THE STATUS OF A GIVEN USER */
export function updateUserStatus(userId, newStatusId) {

  let madeByUserId = localStorage.getItem("uid");
  return function (dispatch) {
    let userServices = new UserServices(config);
    dispatch({ type: types.REQUEST_POSTED, payload: types.UPDATE_USER_STATUS_POST });
    userServices.updateUserStatus(userId, newStatusId, madeByUserId)
      .then(response => {
        dispatch({ type: types.UPDATE_USER_STATUS_FULFILLED, payload: response.data.status });
        dispatch({ type: types.REQUEST_FULFILLED });
      })
      .catch(error => {
        dispatch({ type: types.UPDATE_USER_STATUS_REJECTED, payload: error.response });
        dispatch({ type: types.REQUEST_REJECTED });
      });
  };
}

export function fetchAllAssociatesByRole(roleId, organizationId) {
  if (organizationId !== undefined || roleId !== undefined) {
    return function (dispatch) {
      let liveRotationServices = new LiveRotationServices(config);
      dispatch({ type: types.REQUEST_POSTED, payload: types.FETCH_ASSOCIATES_BY_ROLE_POSTS });

      liveRotationServices.fetchAllAssociatesByRole(roleId, organizationId)
        .then(response => {
          dispatch({ type: types.FETCH_ASSOCIATES_BY_ROLE_FULFILLED, payload: response });
          dispatch({ type: types.FETCH_ASSOCIATES_BY_ROLE_NO_FILTER_FULFILLED, payload: response });
          dispatch({ type: types.REQUEST_FULFILLED });
        })
        .catch(error => {
          dispatch({ type: types.FETCH_ASSOCIATES_BY_ROLE_REJECTED, payload: error.response });
          dispatch({ type: types.REQUEST_REJECTED });
        });
    };
  }
}

export function fetchAllAssociatesLiveRotationModelByRole(roleId, organizationId) {
  if (organizationId !== undefined || roleId !== undefined) {
    return function (dispatch) {
      let liveRotationServices = new LiveRotationServices(config);
      dispatch({ type: types.REQUEST_POSTED, payload: types.FETCH_ASSOCIATES_BY_ROLE_POSTS });

      liveRotationServices.fetchAllAssociatesLiveRotationModelByRole(roleId, organizationId)
        .then(response => {
          dispatch({ type: types.FETCH_ASSOCIATES_BY_ROLE_FULFILLED, payload: response });
          dispatch({ type: types.FETCH_ASSOCIATES_BY_ROLE_NO_FILTER_FULFILLED, payload: response });
          dispatch({ type: types.REQUEST_FULFILLED });
        })
        .catch(error => {
          dispatch({ type: types.FETCH_ASSOCIATES_BY_ROLE_REJECTED, payload: error.response });
          dispatch({ type: types.REQUEST_REJECTED });
        });
    };
  }
}

export function getReportInformation(organizationId, selectedClientAdvisor, minDate, maxDate) {
  if (organizationId !== undefined && selectedClientAdvisor !== undefined && minDate !== undefined && maxDate !== undefined) {
    return function (dispatch) {
      let userServices = new UserServices(config);
      dispatch({ type: types.REQUEST_POSTED, payload: types.REPORT_GET_REPORT_INFORMATION_FULFILLED });

      userServices.getReportInformation(organizationId, selectedClientAdvisor, minDate, maxDate)
        .then(responseReport => {
          dispatch({ type: types.REPORT_GET_REPORT_INFORMATION_FULFILLED, payload: responseReport.data });
          dispatch({ type: types.REQUEST_FULFILLED });
        })
        .catch(error => {
          dispatch({ type: types.REPORT_GET_REPORT_INFORMATION_REJECTED, payload: error.response });
          dispatch({ type: types.REQUEST_REJECTED });
        });
    };
  }
}

export function storePlatform(thePlatform) {
  return ({ type: types.STORE_PLATFORM, payload: thePlatform });
}

export function retrieveCasUser(userId) {
  let userid = userId;
  if (userId === undefined || userId === null) {
    userid = localStorage.getItem("uid");
  }

  return function (dispatch) {
    const authServices = new AuthenticationServices(config);

    authServices.retrieveCasUser(userid)
      .then(response => {
        dispatch({ type: types.FETCH_CAS_USER_FULFILLED, payload: response });
      })
      .catch(error => {
        dispatch({ type: types.FETCH_CAS_USER_REJECTED, payload: error.response });
      });
  };
}

export function updateUser(user) {

  if (user.userId === undefined) {
    user.userId = localStorage.getItem("uid");
  }

  const days = [];
  if (user.sunday) {
    days.push("SU");
  }
  if (user.monday) {
    days.push("MO");
  }
  if (user.tuesday) {
    days.push("TU");
  }
  if (user.wednesday) {
    days.push("WE");
  }
  if (user.thursday) {
    days.push("TH");
  }
  if (user.friday) {
    days.push("FR");
  }
  if (user.saturday) {
    days.push("SA");
  }
  user.schedule = userSessionExtension.daysToBit(days);

  return function (dispatch) {
    const autServices = new AuthenticationServices(config);
    dispatch({ type: types.REQUEST_POSTED, payload: types.UPDATE_USER_FULFILLED });

    autServices.updateUser(user)
      .then(response => {
        dispatch({ type: types.UPDATE_USER_FULFILLED, payload: response });
        dispatch({ type: types.REQUEST_FULFILLED });
      })
      .catch(error => {
        dispatch({ type: types.UPDATE_USER_REJECTED, payload: error.response });
        dispatch({ type: types.REQUEST_REJECTED });
      });
  };
}

export function clearUserSavedFlag() {
  return function (dispatch) {
    dispatch({ type: types.UPDATE_USER_CLEAR_FLAG });
  };
}

export function clearDataForSwitchingOrganization(newSelectedOrganizationId) {
  return function (dispatch) {
    dispatch({ type: types.CLEAR_DATA_FOR_SWITCH_ORGANIZATION, payload: newSelectedOrganizationId });
  };
}
