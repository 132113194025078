import React from 'react';
import PropTypes from 'prop-types';
import ChartBarCustomLabel from '../components/ChartBarCustomLabel';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTheme, VictoryLabel } from 'victory';

const ChartBarSimple = (props) => {

    return (
        <VictoryChart
            domainPadding={props.containerPadding}
            theme={VictoryTheme.material}
            width={props.width}
            padding={{ top: props.chartTopPadding, bottom: props.chartBottomPadding, left: props.chartLeftPadding, right: props.chartRightPadding }}
        >
            <VictoryLabel text={props.title} x={props.titlePositionX} y={props.titlePositionY} textAnchor="middle" />
            <VictoryAxis
                // tickValues specifies both the number of ticks and where they are placed on the axis
                tickValues={props.xTickValues}
                tickFormat={props.xTickFormat}
                tickLabelComponent={<ChartBarCustomLabel xAxisInformation={props.xAxisInformation}
                    xAxisImagesTopPadding={props.xAxisImagesTopPadding}
                    xAxisImagesLeftInitialPadding={props.xAxisImagesLeftInitialPadding}
                    xAxisImagesTickSize={props.xAxisImagesTickSize}
                    xAxisImagesMinWidth={props.xAxisImagesMinWidth}
                    xAxisImagesMinHeight={props.xAxisImagesMinHeight} />}
            />
            <VictoryAxis
                dependentAxis // this defines that the axis is Y axis
                tickFormat={props.tickFormatingFunction} // tickFormat specifies how ticks should be displayed
            />
            <VictoryBar
                data={props.data}
                x={props.xKey}
                y={props.yKey}
                style={{ data: { fill: props.barColor }, labels: { fill: "black"} }}
                barWidth={props.barWidth}
                labels={(d) => props.valuesInMinutes ?  ((d.value) * (1/60)).toFixed(2) : d.value}
            />
        </VictoryChart>
    );
};

ChartBarSimple.propTypes = {
    data: PropTypes.array,
    xKey: PropTypes.string,
    yKey: PropTypes.string,
    containerPadding: PropTypes.number,
    chartTopPadding: PropTypes.number,
    chartBottomPadding: PropTypes.number,
    chartRightPadding: PropTypes.number,
    chartLeftPadding: PropTypes.number,
    valuesInMinutes: PropTypes.bool,
    xTickValues: PropTypes.array,
    xTickFormat: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
    title: PropTypes.string,
    titlePositionX: PropTypes.number,
    titlePositionY: PropTypes.number,
    barWidth: PropTypes.number,
    barColor: PropTypes.string,
    tickFormatingFunction: PropTypes.func,
    xAxisImagesTopPadding: PropTypes.number,
    xAxisImagesLeftInitialPadding: PropTypes.number,
    xAxisImagesTickSize: PropTypes.number,
    xAxisImagesMinWidth: PropTypes.number,
    xAxisImagesMinHeight: PropTypes.number,
    xAxisInformation: PropTypes.array
};

export default ChartBarSimple;