export const AUTHENTICATE = "AUTHENTICATE";
export const SIGNOUT = "SIGNOUT";
export const AUTH_ERROR = "AUTH_ERROR";
export const FETCH_TEMP_USER = "FETCH_TEMP_USER";
export const FETCH_CAS_USER_FULFILLED = "FETCH_CAS_USER_FULFILLED";
export const FETCH_CAS_USER_REJECTED = "FETCH_CAS_USER_REJECTED";

export const FETCH_STATUS_FULFILLED = "FETCH_STATUS_FULFILLED";
export const FETCH_STATUS_REJECTED = "FETCH_STATUS_REJECTED";

export const FETCH_STANDARD_POSITIONS_FULFILLED =
  "FETCH_STANDARD_POSITIONS_FULFILLED";
export const FETCH_STANDARD_POSITIONS_REJECTED =
  "FETCH_STANDARD_POSITIONS_REJECTED";

export const FETCH_ALL_ASSOCIATES_POST = "FETCH_ALL_ASSOCIATES_POST";
export const FETCH_ALL_ASSOCIATES_FULFILLED = "FETCH_ALL_ASSOCIATES_FULFILLED";
export const FETCH_ALL_ASSOCIATES_REJECTED = "FETCH_ALL_ASSOCIATES_REJECTED";

export const REORDER_ALL_ASSOCIATES_IN_STATE =
  "REORDER_ALL_ASSOCIATES_IN_STATE";

export const STORE_APPLICATION_DIMENSIONS = "STORE_APPLICATION_DIMENSIONS";
export const STORE_LEFT_BAR_TOP = "STORE_LEFT_BAR_TOP";
export const STORE_LIVEROTATION_CONTAINER_TOP =
  "STORE_LIVEROTATION_CONTAINER_TOP";
export const STORE_AVAILABLE_CONTAINER_TOP = "STORE_AVAILABLE_CONTAINER_TOP";
export const STORE_AVAILABLE_ROW_HEIGHT = "STORE_AVAILABLE_ROW_HEIGHT";
export const STORE_AVAILABLE_SELECTED_PAGE = "STORE_AVAILABLE_SELECTED_PAGE";
export const STORE_STATUS_CONTAINER_DIMENSIONS =
  "STORE_STATUS_CONTAINER_DIMENSIONS";
export const STORE_STANDARD_STATUS_SELECTED_PAGE =
  "STORE_STANDARD_STATUS_SELECTED_PAGE";
export const STORE_PLATFORM = "STORE_PLATFORM";

export const UPDATE_USER_STATUS_POST = "UPDATE_USER_STATUS_POST";
export const UPDATE_USER_STATUS_FULFILLED = "UPDATE_USER_STATUS_FULFILLED";
export const UPDATE_USER_STATUS_REJECTED = "UPDATE_USER_STATUS_REJECTED";
export const UPDATE_USER_FULFILLED = "UPDATE_USER_FULFILLED";
export const UPDATE_USER_REJECTED = "UPDATE_USER_REJECTED";
export const UPDATE_USER_CLEAR_FLAG = "UPDATE_USER_CLEAR_FLAG";
export const INSERT_UPDATE_FULFILLED = "INSERT_UPDATE_FULFILLED";

export const CREATE_UUID_BY_USER_FULFILLED = "CREATE_UUID_BY_USER_FULFILLED";
export const CREATE_UUID_BY_USER_REJECTED = "CREATE_UUID_BY_USER_REJECTED";

export const GET_ROLE_BY_USER_ID_FULFILLED = "GET_ROLE_BY_USER_ID_FULFILLED";
export const GET_ROLE_BY_USER_ID_REJECTED = "GET_ROLE_BY_USER_ID_REJECTED";

export const INSERT_CAR_SOLD_POSTS = "INSERT_CAR_SOLD_POSTS";
export const INSERT_CAR_SOLD_FULFILLED = "INSERT_CAR_SOLD_FULFILLED";
export const INSERT_CAR_SOLD_REJECTED = "INSERT_CAR_SOLD_REJECTED";

export const FETCH_LAST_UPDATE_FULFILLED = "FETCH_LAST_UPDATE_FULFILLED";
export const FETCH_LAST_UPDATE_REJECTED = "FETCH_LAST_UPDATE_REJECTED";

export const DISMISS_UPDATE_FULFILLED = "DISMISS_UPDATE_FULFILLED";
export const DISMISS_UPDATE_REJECTED = "DISMISS_UPDATE_REJECTED";

export const STORE_STANDARD_POSITION_NUMBER = "STORE_STANDARD_POSITION_NUMBER";
export const STORE_STANDARD_POSITION_MOVE_POSITION =
  "STORE_STANDARD_POSITION_MOVE_POSITION";
export const UPDATE_USER_POSITION = "UPDATE_USER_POSITION";
export const CHANGE_USER_FROM_STATUS = "CHANGE_USER_FROM_STATUS";

export const CLEAR_UNCOMMITTED_CHANGES_POSTS =
  "CLEAR_UNCOMMITTED_CHANGES_POSTS";
export const CLEAR_UNCOMMITTED_CHANGES_FULFILLED =
  "CLEAR_UNCOMMITTED_CHANGES_FULFILLED";
export const CLEAR_UNCOMMITTED_CHANGES_REJECTED =
  "CLEAR_UNCOMMITTED_CHANGES_REJECTED";

export const SAVE_MANAGER_CHANGES_POSTS = "SAVE_MANAGER_CHANGES_POSTS";
export const SAVE_MANAGER_CHANGES_FULFILLED = "SAVE_MANAGER_CHANGES_FULFILLED";
export const SAVE_MANAGER_CHANGES_REJECTED = "SAVE_MANAGER_CHANGES_REJECTED";

export const REQUEST_POSTED = "REQUEST_POSTED";
export const REQUEST_REJECTED = "REQUEST_REJECTED";
export const REQUEST_CLEAR = "REQUEST_CLEAR";
export const REQUEST_FULFILLED = "REQUEST_FULFILLED";

export const FETCH_ASSOCIATES_BY_ROLE_POSTS = "FETCH_ASSOCIATES_BY_ROLE_POSTS";
export const FETCH_ASSOCIATES_BY_ROLE_FULFILLED =
  "FETCH_ASSOCIATES_BY_ROLE_FULFILLED";
export const FETCH_ASSOCIATES_BY_ROLE_REJECTED =
  "FETCH_ASSOCIATES_BY_ROLE_REJECTED";
export const FETCH_ASSOCIATES_BY_ROLE_NO_FILTER_FULFILLED =
  "FETCH_ASSOCIATES_BY_ROLE_NO_FILTER_FULFILLED";

export const FILTER_ASSOCIATES_BY_ROLE = "FILTER_ASSOCIATES_BY_ROLE";

export const STORE_CURRENT_DROPPABLE_SOURCE = "STORE_CURRENT_DROPPABLE_SOURCE";

export const REPORT_STORE_DATE_RANGE = "REPORT_STORE_DATE_RANGE";
export const REPORT_UPDATE_MIN_DATE_CHANGE = "REPORT_UPDATE_MIN_DATE_CHANGE";
export const REPORT_UPDATE_MAX_DATE_CHANGE = "REPORT_UPDATE_MAX_DATE_CHANGE";
export const REPORT_UPDATE_SELECTED_CLIENT_ADVISOR =
  "REPORT_UPDATE_SELECTED_CLIENT_ADVISOR";
export const REPORT_GET_REPORT_INFORMATION_FULFILLED =
  "REPORT_GET_REPORT_INFORMATION_FULFILLED";
export const REPORT_GET_REPORT_INFORMATION_REJECTED =
  "REPORT_GET_REPORT_INFORMATION_REJECTED";
export const REPORT_CLEAR_REPORT_INFORMATION =
  "REPORT_CLEAR_REPORT_INFORMATION";
export const REPORT_SET_X_AXIS_INFORMATION = "REPORT_SET_X_AXIS_INFORMATION";

export const MOBILE_CHANGE_VIEW = "MOBILE_CHANGE_VIEW";
export const MOBILE_FETCH_USER_CURRENT_STANDARD_POSITION =
  "MOBILE_FETCH_USER_CURRENT_STANDARD_POSITION";

export const SWITCH_ORGANIZATION = "SWITCH_ORGANIZATION";
export const CLEAR_DATA_FOR_SWITCH_ORGANIZATION =
  "CLEAR_DATA_FOR_SWITCH_ORGANIZATION";

export const ORGANIZATION_RETRIEVE_BY_ID = "ORGANIZATION_RETRIEVE_BY_ID";
export const ORGANIZATION_UPDATE_ALLOW_USERS_SELF_MANAGEMENT =
  "ORGANIZATION_UPDATE_ALLOW_USERS_SELF_MANAGEMENT";
export const ORGANIZATION_UPDATE_AUTO_ROTATE_MINUTES =
  "ORGANIZATION_UPDATE_AUTO_ROTATE_MINUTES";
