import React from 'react';
import PropTypes from 'prop-types';
import * as statusDescriptions from '../data/constants/statusDescriptions';
import moment from 'moment';

const MobileViewProfile = (props) => {

    function selectClassByStatus() {

        switch (props.status) {
            case statusDescriptions.AVAILABLE: {
                return "Mobile-body available-status";
            }

            case statusDescriptions.ON_HOLD: {
                return "Mobile-body onhold-status";
            }

            case statusDescriptions.GUEST_ENGAGEMENT: {
                return "Mobile-body unavailable-status";
            }

            case statusDescriptions.WILLING: {
                return "Mobile-body willinge-status";
            }

            case statusDescriptions.OFF: {
                return "Mobile-body off-status";
            }

            default: {
                return "Movile-body off-status";
            }
        }
    }

    function computeTimeDifference() {
        if (props.lastUpdate !== null && props.lastUpdate !== undefined) {
            let end = moment.utc(); // right now in utc
            let rotationUpdateDateInLocal = moment.utc(props.lastUpdate.rotationUpdateDateTimeUTC);
            let duration = moment.duration(end.diff(rotationUpdateDateInLocal));
            let result = Math.floor(duration.asSeconds());
            let minsHours = "Seconds ago";

            if (result < 60) {
                return minsHours;
            }
            else if (result >= 60 && result < 3600) {
                result = Math.floor(duration.asMinutes());

                if (result === 1) {
                    minsHours = " minute ago";
                }
                else {
                    minsHours = " minutes ago";
                }
            }
            else if (result >= 3600) {
                result = Math.floor(duration.asHours());

                if (result === 1) {
                    minsHours = " hour ago";
                }
                else {
                    minsHours = " hours ago";
                }
            }
            else if (result >= 86400) {
                result = Math.floor(duration.asDays());

                if (result === 1) {
                    minsHours = " day ago";
                }
                else {
                    minsHours = " days ago";
                }
            }

            return result.toString() + minsHours;
        }
        else {
            return "";
        }
    }

    return (
        props.selectedView === "availableList" ? null
            :
            <div className={selectClassByStatus()}>
                <div className="mob-notifications-active" />
                <div className="status">
                    <div className="profile-image">
                        <img src={props.userImage} alt="" />
                    </div>
                    {
                        props.status === statusDescriptions.AVAILABLE
                            ?
                            <div className="position-status">
                                <img src={props.userCurrentStandardPositionIcon} alt="" />
                            </div>
                            : null
                    }
                </div>
                <div className="NameInfo">
                    <div className="postion-info">{props.firstName}&nbsp;{props.lastName}</div>
                    <div className="postion">{props.status}</div>
                    <div className="detail-postion">

                        {props.status === statusDescriptions.AVAILABLE ? <div className="av-position">{props.userCurrentStandardPositionDescription}</div> : null}

                        {computeTimeDifference() !== "" ? <div className="time-position">YOUR STATUS CHANGED<br/><br/><span><b>{computeTimeDifference()}</b></span></div> : null}
                    </div>
                </div>
            </div>
    );
};

MobileViewProfile.propTypes = {
    userImage: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    selectedView: PropTypes.string,
    status: PropTypes.string,
    userCurrentStandardPositionDescription: PropTypes.string,
    userCurrentStandardPositionIcon: PropTypes.string,
    lastUpdate: PropTypes.object
};

export default MobileViewProfile;
