import * as types from "../constants/actionTypes";

export function dateRangeChange(newDateRange) {
  if (newDateRange !== undefined) {
    return function (dispatch) {
      dispatch({ type: types.REPORT_STORE_DATE_RANGE, payload: newDateRange });
    };
  }
}

export function updateMinDateChange(minDate) {
  return function (dispatch) {
    dispatch({ type: types.REPORT_UPDATE_MIN_DATE_CHANGE, payload: minDate });
  };
}

export function updateMaxDateChange(maxDate) {
  return function (dispatch) {
    dispatch({ type: types.REPORT_UPDATE_MAX_DATE_CHANGE, payload: maxDate });
  };
}

export function updateSelectedClientAdvisor(clientAdvisorId) {
  return function (dispatch) {
    dispatch({
      type: types.REPORT_UPDATE_SELECTED_CLIENT_ADVISOR,
      payload: clientAdvisorId,
    });
  };
}

export function clearReportInformation() {
  return function (dispatch) {
    dispatch({ type: types.REPORT_CLEAR_REPORT_INFORMATION });
  };
}

export function setXAxisInformation() {
  return function (dispatch) {
    dispatch({ type: types.REPORT_SET_X_AXIS_INFORMATION });
  };
}
