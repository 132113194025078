import React from 'react';
import TopNavigationBar from './TopNavigationBar';
//import Toggle from 'material-ui/Toggle';
// import PropTypes from 'prop-types';

const Roles = (/*props*/) => {

    return (
        <div>
            <TopNavigationBar />
            <div className="header">
                <h1 className="main-title">Security Roles</h1>
                <input className="searching-input" type="text" placeholder="Search"/>
            </div>
            <div className="add-associate elt-shadow-3">
                <div className="lil-tile">
                    <div className="circle-profile-image profile-image">
                        <img src="https://ets.eleadertech.com/Files/Employees/Account_103/employee_2057.jpg" alt="Profile"/>
                    </div>
                    <div className="text-role">
                        <h2>Ronny Delgado</h2>
                        <span className="assoc-role">Security Profiles: <b>4</b></span>
                    </div>
                </div>
                <div className="lil-tile">
                    <div className="circle-profile-image profile-image">
                        <img src="https://ets.eleadertech.com/Files/Employees/Account_103/employee_2057.jpg" alt="Profile"/>
                    </div>
                    <div className="text-role">
                        <h2>Ronny Delgado</h2>
                        <span className="assoc-role">Security Profiles: <b>4</b></span>
                    </div>
                </div>
                <div className="lil-tile">
                    <div className="circle-profile-image profile-image">
                        <img src="https://ets.eleadertech.com/Files/Employees/Account_103/employee_2057.jpg" alt="Profile"/>
                    </div>
                    <div className="text-role">
                        <h2>Ronny Delgado</h2>
                        <span className="assoc-role">Security Profiles: <b>4</b></span>
                    </div>
                </div>
            </div>
            <div className="big-container">
                <div className="lil-tile tile-green-left elt-shadow-3">
                    <div className="circle-profile-image profile-image">
                        <img src="https://ets.eleadertech.com/Files/Employees/Account_103/employee_2057.jpg" alt="Profile"/>
                    </div>
                    <div className="text-role">
                        <h2>Ronny Delgado</h2>
                        <span className="assoc-role">Security Profiles: <b>4</b></span>
                        <div>
                            <button className="btn-lightblue">View Profile</button>&nbsp;
                            <button className="btn-red">Remove</button>
                        </div>
                    </div>
                </div>
                <div className="lil-tile tile-green-left elt-shadow-3">
                    <div className="circle-profile-image profile-image">
                        <img src="https://ets.eleadertech.com/Files/Employees/Account_103/employee_2057.jpg" alt="Profile"/>
                    </div>
                    <div className="text-role">
                        <h2>Ronny Delgado</h2>
                        <span className="assoc-role">Security Profiles: <b>4</b></span>
                        <div>
                            <button className="btn-lightblue">View Profile</button>&nbsp;
                            <button className="btn-red">Remove</button>
                        </div>
                    </div>
                </div>
                <div className="lil-tile tile-green-left elt-shadow-3">
                    <div className="circle-profile-image profile-image">
                        <img src="https://ets.eleadertech.com/Files/Employees/Account_103/employee_2057.jpg" alt="Profile"/>
                    </div>
                    <div className="text-role">
                        <h2>Ronny Delgado</h2>
                        <span className="assoc-role">Security Profiles: <b>4</b></span>
                        <div>
                            <button className="btn-lightblue">View Profile</button>&nbsp;
                            <button className="btn-red">Remove</button>
                        </div>
                    </div>
                </div>
                <div className="lil-tile tile-green-left elt-shadow-3">
                    <div className="circle-profile-image profile-image">
                        <img src="https://ets.eleadertech.com/Files/Employees/Account_103/employee_2057.jpg" alt="Profile"/>
                    </div>
                    <div className="text-role">
                        <h2>Ronny Delgado</h2>
                        <span className="assoc-role">Security Profiles: <b>4</b></span>
                        <div>
                            <button className="btn-lightblue">View Profile</button>&nbsp;
                            <button className="btn-red">Remove</button>
                        </div>
                    </div>
                </div>
                <div className="lil-tile tile-green-left elt-shadow-3">
                    <div className="circle-profile-image profile-image">
                        <img src="https://ets.eleadertech.com/Files/Employees/Account_103/employee_2057.jpg" alt="Profile"/>
                    </div>
                    <div className="text-role">
                        <h2>Ronny Delgado</h2>
                        <span className="assoc-role">Security Profiles: <b>4</b></span>
                        <div>
                            <button className="btn-lightblue">View Profile</button>&nbsp;
                            <button className="btn-red">Remove</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

Roles.propTypes = {
    
};

export default Roles;