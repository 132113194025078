import React from "react";
import PropTypes from "prop-types";
import AutoComplete from "material-ui/AutoComplete";
import { blueGrey400 } from "material-ui/styles/colors";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import moment from "moment";
import DatePicker from "material-ui/DatePicker";
//import moment from 'moment';

class ReportFilters extends React.Component {
  constructor(props) {
    super(props);
    this.pickerInitialDate = React.createRef();
    this.changeFilterSelection = this.changeFilterSelection.bind(this);
  }
  changeFilterSelection(el) {
    this.props.handleDateRangeChange.bind(el);
    this.pickerInitialDate.current.handleClick();
  }
  render() {
    const styles = {
      underlineStyle: {
        color: blueGrey400,
      },
      underlineFocusStyle: {
        color: blueGrey400,
      },
      floatingLabelStyle: {
        color: blueGrey400,
      },
      floatingLabelFocusStyle: {
        color: blueGrey400,
      },
      selectionText: {
        paddingLeft: "10px",
        top: "-15px",
      },
      radioButton: {
        width: "140px",
        float: "left",
      },
    };

    const dataSourceConfigClientAdvisors = {
      text: "fullName",
      value: "id",
    };

    return (
      <div>
        <div className="flex-container width-100" style={{ marginTop: 40 }}>
          {/* <div>Filter by Company:</div>

                <AutoComplete floatingLabelText="Select Company"
                    onChange={this}
                    onKeyPress={}
                    value={}
                    underlineStyle={styles.underlineStyle}
                    underlineFocusStyle={styles.underlineFocusStyle}
                    floatingLabelStyle={styles.floatingLabelStyle}
                    floatingLabelFocusStyle={styles.floatingLabelFocusStyle}
                    style={styles.selectionText} /> */}
          <div>Filter by Client Advisor:</div>
          <AutoComplete
            floatingLabelText="All Client Advisors"
            openOnFocus={true}
            filter={AutoComplete.caseInsensitiveFilter}
            dataSource={this.props.listOfClientAdvisorsNoFilter}
            dataSourceConfig={dataSourceConfigClientAdvisors}
            onNewRequest={this.props.handleClientAdvisorChange.bind(this)}
            onBlur={this.props.handleClientAdvisorChange.bind(this)}
          />
        </div>
        <div className="flex-container width-100" style={{ marginTop: 40 }}>
          <div style={{ marginBottom: 10 }}>Select date range:</div>
          <RadioButtonGroup
            name="preSetDateRanges"
            defaultSelected={this.props.selectedDateRange}
            valueSelected={this.props.selectedDateRange}
          >
            <RadioButton
              value="Today"
              label="Today"
              style={styles.radioButton}
              onClick={this.props.handleDateRangeChange.bind(this)}
            />
            <RadioButton
              value="ThisWeek"
              label="This Week"
              style={styles.radioButton}
              onClick={this.props.handleDateRangeChange.bind(this)}
            />
            <RadioButton
              value="Prior"
              label="Prior Month"
              style={styles.radioButton}
              onClick={this.props.handleDateRangeChange.bind(this)}
            />
            <RadioButton
              value="MTD"
              label="MTD"
              style={styles.radioButton}
              onClick={this.props.handleDateRangeChange.bind(this)}
            />
            <RadioButton
              value="YTD"
              label="YTD"
              style={styles.radioButton}
              onClick={this.props.handleDateRangeChange.bind(this)}
            />
            <RadioButton
              value="Custom"
              label="Custom"
              style={styles.radioButton}
              onClick={this.changeFilterSelection.bind(this)}
            />
          </RadioButtonGroup>
        </div>
        <div
          className="flex-container width-100"
          style={{ display: "inline-flex", marginTop: 40 }}
        >
          <div style={{ paddingTop: 30 }}>Custom Date Range:</div>

          <DatePicker
            ref={this.pickerInitialDate}
            autoOk={true}
            floatingLabelText="Initial Date"
            disableYearSelection={false}
            style={styles.selectionText}
            value={this.props.minDate}
            onChange={this.props.handleMinDateChange.bind(this)}
            formatDate={(date) => moment(date).format("MM/DD/YYYY")}
          />

          <DatePicker
            autoOk={true}
            floatingLabelText="Final Date"
            disableYearSelection={false}
            style={styles.selectionText}
            value={this.props.maxDate}
            onChange={this.props.handleMaxDateChange.bind(this)}
            formatDate={(date) => moment(date).format("MM/DD/YYYY")}
          />
        </div>
      </div>
    );
  }
}

ReportFilters.propTypes = {
  selectedDateRange: PropTypes.string,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  listOfClientAdvisorsNoFilter: PropTypes.array,

  handleDateRangeChange: PropTypes.func,
  handleMinDateChange: PropTypes.func,
  handleMaxDateChange: PropTypes.func,
  handleClientAdvisorChange: PropTypes.func,
};

export default ReportFilters;
