import * as types from '../constants/actionTypes';
import config from '../../configuration/config';
import ManagerServices from '../services/ManagerServices';
import LiveRotationServices from '../services/LiveRotationServices';
import axios from 'axios';
import * as roles from '../constants/securityRoles';

export function clearUncommittedChanges(showAlert = true, availableStatusId) {
    return function (dispatch) {
        if (showAlert) { dispatch({ type: types.REQUEST_POSTED, payload: types.CLEAR_UNCOMMITTED_CHANGES_POSTS }); }
        let organizationId = localStorage.getItem("selectedOrganizationId");

        if (organizationId !== undefined) {
            let liveRotationServices = new LiveRotationServices(config);
            axios.all([
                liveRotationServices.fetchAllAssociatesInLiveRotation(organizationId),
                liveRotationServices.fetchAllAssociatesLiveRotationModelByRole(roles.ClientAdvisorIdMap, organizationId),
                liveRotationServices.fetchStandardPositions(availableStatusId)
            ])
                .then(axios.spread(function (allAssociates, clientAdvisors, standardPositions) {
                    dispatch({ type: types.FETCH_ALL_ASSOCIATES_FULFILLED, payload: allAssociates });
                    dispatch({ type: types.FETCH_ASSOCIATES_BY_ROLE_FULFILLED, payload: clientAdvisors });
                    dispatch({ type: types.FETCH_ASSOCIATES_BY_ROLE_NO_FILTER_FULFILLED, payload: clientAdvisors });
                    dispatch({ type: types.FETCH_STANDARD_POSITIONS_FULFILLED, payload: standardPositions });
                    dispatch({ type: types.CLEAR_UNCOMMITTED_CHANGES_FULFILLED });
                    if (showAlert) { dispatch({ type: types.REQUEST_FULFILLED }); }
                }))
                .catch(error => {
                    dispatch({ type: types.CLEAR_UNCOMMITTED_CHANGES_REJECTED, payload: error.response });
                    dispatch({ type: types.REQUEST_REJECTED });
                });
        }
    };
}

export function saveManagerChanges(standardPositions, inAvailable, inGuestEngagement, inWilling, inOff, organizationId) {
    if (organizationId !== undefined) {
        return function (dispatch) {
            dispatch({ type: types.REQUEST_POSTED, payload: types.SAVE_MANAGER_CHANGES_POSTS });
            let managerServices = new ManagerServices(config);
            managerServices.saveStandardPositionChanges(standardPositions)
                .then(responseStandardPositions => {
                    managerServices.saveManagerChanges(inAvailable, inGuestEngagement, inWilling, inOff, organizationId)
                        .then(responseManagerChanges => {
                            let data = { responseStandardPositions, responseManagerChanges };
                            dispatch({ type: types.SAVE_MANAGER_CHANGES_FULFILLED, payload: data });
                            dispatch({ type: types.CLEAR_UNCOMMITTED_CHANGES_FULFILLED });
                            dispatch({ type: types.REQUEST_FULFILLED });
                        });
                })
                .catch(error => {
                    dispatch({ type: types.SAVE_MANAGER_CHANGES_REJECTED, payload: error.response });
                    dispatch({ type: types.REQUEST_REJECTED });
                });
        };
    }
}