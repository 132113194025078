import * as types from "../constants/actionTypes";
import moment from "moment";
import _ from "underscore";

const initialState = {
  errorMessage: "",
  selectedDateRange: "MTD",
  selectedClientAdvisor: "00000000-0000-0000-0000-000000000000",
  //selectedCompany: "00000000-0000-0000-0000-000000000000",
  minDate: moment().startOf("month"),
  maxDate: moment().endOf("day"),
  listOfClientAdvisorsNoFilter: [],
  reportInformation: [],
  reportOverallTimeSpent: null,
  reportOverallNumberOfTimes: null,
};

export default function reportReducer(state = initialState, action) {
  let errorMessage = "";

  switch (action.type) {
    /* TODO: find best way to handle errors */
    case types.REPORT_GET_REPORT_INFORMATION_REJECTED: {
      if (
        action.payload !== undefined &&
        action.payload.data !== undefined &&
        action.payload.data.error !== undefined
      ) {
        errorMessage = action.payload.data.error;
      }

      if (
        action.payload !== undefined &&
        action.payload.data !== undefined &&
        action.payload.data.error_description !== undefined
      ) {
        errorMessage = action.payload.data.error_description;
      }

      if (errorMessage !== "") {
        return { ...state, errorMessage: errorMessage };
      } else {
        return { ...state };
      }
    }

    case types.REPORT_STORE_DATE_RANGE: {
      let payload = action.payload;
      let theMinDate = moment().startOf("month"); // defaulted to start of month
      let theMaxDate = moment().endOf("day"); // defaulted to today

      switch (payload) {
        case "Today": {
          theMinDate = moment().startOf("day");
          theMaxDate = moment().add(1, "days").endOf("day");
          break;
        }
        case "ThisWeek": {
          theMinDate = moment().startOf("week");
          theMaxDate = moment().endOf("day");
          break;
        }
        case "MTD": {
          theMinDate = moment().startOf("month");
          theMaxDate = moment().endOf("day");
          break;
        }
        case "YTD": {
          theMinDate = moment().startOf("year");
          theMaxDate = moment().endOf("day");
          break;
        }
        case "Prior": {
          theMinDate = moment(moment().add(-1, "month")).startOf("month");
          theMaxDate = moment(moment().add(-1, "month")).endOf("month");
          break;
        }
      }

      return {
        ...state,
        selectedDateRange: payload,
        minDate: theMinDate._d,
        maxDate: theMaxDate._d,
      };
    }

    case types.REPORT_UPDATE_MIN_DATE_CHANGE: {
      return { ...state, minDate: action.payload, selectedDateRange: "Custom" };
    }

    case types.REPORT_UPDATE_MAX_DATE_CHANGE: {
      return { ...state, maxDate: action.payload, selectedDateRange: "Custom" };
    }

    case types.FETCH_ASSOCIATES_BY_ROLE_NO_FILTER_FULFILLED: {
      // remove associates already assigned to other status
      let listOfAssociatesByRole = [];
      for (let i = 0; i < action.payload.data.length; i++) {
        let item = Object.assign({}, action.payload.data[i]);

        if (item.userFirstName === undefined && item.firstName !== undefined) {
          item.userFirstName = item.firstName;
          item.userLastName = item.lastName;
          item.userId = item.id;
        }

        item.fullName = item.userFirstName + " " + item.userLastName;
        listOfAssociatesByRole.push(item);
      }

      listOfAssociatesByRole = _.sortBy(
        listOfAssociatesByRole,
        "userFirstName"
      );

      return { ...state, listOfClientAdvisorsNoFilter: listOfAssociatesByRole };
    }

    case types.REPORT_UPDATE_SELECTED_CLIENT_ADVISOR: {
      return { ...state, selectedClientAdvisor: action.payload };
    }

    case types.REPORT_GET_REPORT_INFORMATION_FULFILLED: {
      let reportList = Object.assign({}, action.payload);
      reportList = _.sortBy(reportList, "firstName");

      let overallTimeSpent = null;
      let overallNumberOfTimes = null;
      let timeSpentArray = [];
      let numberOfTimesArray = [];

      if (reportList.length > 0) {
        reportList.forEach((reportItem) => {
          if (
            reportItem.timeSpent !== null &&
            reportItem.timeSpent !== undefined
          ) {
            reportItem.timeSpent.forEach((item) => {
              if (timeSpentArray.length === 0) {
                overallTimeSpent = { id: item.id, value: item.value };
                timeSpentArray.push(overallTimeSpent);
              } else {
                let innerIndex = _.findIndex(timeSpentArray, { id: item.id });

                if (innerIndex === -1) {
                  overallTimeSpent = { id: item.id, value: item.value };
                  timeSpentArray.push(overallTimeSpent);
                } else {
                  timeSpentArray[innerIndex].value =
                    timeSpentArray[innerIndex].value + item.value;
                }
              }
            });
          }

          if (
            reportItem.numberOfTimes !== null &&
            reportItem.numberOfTimes !== undefined
          ) {
            reportItem.numberOfTimes.forEach((item) => {
              if (numberOfTimesArray.length === 0) {
                overallNumberOfTimes = { id: item.id, value: item.value };
                numberOfTimesArray.push(overallNumberOfTimes);
              } else {
                let innerIndex = _.findIndex(numberOfTimesArray, {
                  id: item.id,
                });

                if (innerIndex === -1) {
                  overallNumberOfTimes = { id: item.id, value: item.value };
                  numberOfTimesArray.push(overallNumberOfTimes);
                } else {
                  numberOfTimesArray[innerIndex].value =
                    parseInt(numberOfTimesArray[innerIndex].value) +
                    parseInt(item.value);
                }
              }
            });
          }
        });
      }

      return {
        ...state,
        reportInformation: reportList,
        reportOverallTimeSpent: timeSpentArray,
        reportOverallNumberOfTimes: numberOfTimesArray,
      };
    }

    case types.REPORT_CLEAR_REPORT_INFORMATION: {
      return {
        ...state,
        reportInformation: [],
        reportOverallTimeSpent: [],
        reportOverallNumberOfTimes: [],
      };
    }

    case types.SIGNOUT: {
      localStorage.clear();
      return { ...initialState, isLoggedIn: false };
    }
  }

  return state;
}
