import React from 'react';
import PropTypes from 'prop-types';

const TopStatusDropdown = (props) => {

    return (

      <li className="position-dropdown">
        {
          props.selectedStatus
        }
        <div className="dropdown-content elt-shadow-2">
          {
            props.allStatus
          }
        </div>
      </li>
    ); 
};

TopStatusDropdown.propTypes = {
  allStatus: PropTypes.array,
  selectedStatus: PropTypes.object
};


export default TopStatusDropdown;
