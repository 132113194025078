import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as statusDescription from '../data/constants/statusDescriptions';
import * as utils from '../helpers/utils';
import * as actions from '../data/Application/applicationActions';
import { VelocityTransitionGroup } from 'velocity-react';
import 'velocity-animate/velocity.ui';
import draggableItemTypes from '../data/constants/draggableItemTypes';
import { Draggable } from 'react-beautiful-dnd';

class StandardPositionRow extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.addEventListener("resize", this.getRowHeight.bind(this));

        setTimeout(() => { this.getRowHeight(); }, 0); // feel this a little hacky? check this: http://stackoverflow.com/questions/26556436/react-after-render-code
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.getRowHeight.bind(this));
    }

    getRowHeight() {
        let theHeight = 0;
        let rowDiv = document.getElementById('availableRow');
        if (rowDiv != undefined) {
            theHeight = rowDiv.clientHeight;
        }
        this.props.storeAvailableRowHeight(theHeight);
    }

    userIsOnHold(status) {
        return status === statusDescription.ON_HOLD ? " position-reserved" : "";
    }

    render() {
        if (this.props.standardPosition !== undefined && this.props.availableSelectedPage !== undefined) {
            return (
                <VelocityTransitionGroup enter={{ animation: "transition.slideUpIn", duration: 500 }}>
                    {this.props.standardPosition.NumberOfPage === this.props.availableSelectedPage ?

                        <Draggable draggableId={"draggable-users-available-" + this.props.standardPosition.NumberOfRow + (this.props.user !== undefined ? "-" + String(this.props.user.userId) : "-nouser") + "-maxindex:" + this.props.maxIndex}
                            type={draggableItemTypes.MANAGER_USER_CARD}
                            key={this.props.standardPosition.NumberOfRow}
                            index={this.props.index}
                            isDragDisabled={!this.props.allowScrolling}>
                            {(provided) => (
                                <div id="availableRow" className={utils.isOdd(this.props.standardPosition.NumberOfRow) ? "row odd-tag clase" + this.userIsOnHold(this.props.user != undefined ? this.props.user.statusDescription : statusDescription.AVAILABLE) : "row even-tag clase" + this.userIsOnHold(this.props.user !== undefined ? this.props.user.statusDescription : statusDescription.AVAILABLE)}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}>
                                    <div className={utils.isOdd(this.props.standardPosition.NumberOfRow) ? "number odd" : "number even"}>
                                        {this.props.standardPosition.NumberOfRow}
                                    </div>
                                    <img className="position-role" src={this.props.standardPosition.Icon} alt={this.props.standardPosition.Description} />
                                    <div className="name-tag">
                                        <div className="circle-profile-image">
                                            <img src={this.props.user != undefined ? this.props.user.user.photo : ""} alt="" />
                                        </div>
                                        &nbsp;{this.props.user != undefined ? this.props.user.userFirstName + " " + this.props.user.userLastName : ""}
                                    </div>
                                    {
                                        (this.props.user != undefined && this.props.user.statusDescription === statusDescription.ON_HOLD) &&
                                        <div className="onhold-reserved">
                                            <img className="squared-icon" src="images/onhold-icon.svg" alt="" />
                                        </div>
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                        </Draggable>
                        :
                        null
                    }
                </VelocityTransitionGroup>
            );
        }
        else {
            return <div />;
        }
    }
}

StandardPositionRow.propTypes = {
    standardPosition: PropTypes.object.isRequired,
    user: PropTypes.object,

    storeAvailableRowHeight: PropTypes.func,
    availableSelectedPage: PropTypes.number,
    index: PropTypes.number,
    maxIndex: PropTypes.number,
    allowScrolling: PropTypes.bool
};

function mapStateToProps(state) {
    return {
        availableRowHeight: state.application.availableRowHeight,
        availableSelectedPage: state.application.availableSelectedPage
    };
}

export default connect(mapStateToProps, actions)(StandardPositionRow);