import * as types from '../constants/actionTypes';

const initialState = {
  numberOfUncommitedChanges: 0,
  errorMessage: ""
};

export default function managerReducer(state = initialState, action) {
  let errorMessage = "";

  switch (action.type) {

    /* TODO: find best way to handle errors */
    case types.CLEAR_UNCOMMITTED_CHANGES_REJECTED:
    case types.SAVE_MANAGER_CHANGES_REJECTED: {
      if (action.payload !== undefined && action.payload.data !== undefined && action.payload.data.error !== undefined) {
        errorMessage = "";
      }

      if (action.payload !== undefined && action.payload.data !== undefined && action.payload.data.error_description !== undefined) {
        errorMessage = action.payload.data.error_description;
      }

      if (errorMessage !== "") {
        return { ...state, errorMessage: errorMessage };
      }
      else {
        return { ...state };
      }
    }

    case types.CHANGE_USER_FROM_STATUS:
    case types.UPDATE_USER_POSITION:
    case types.STORE_STANDARD_POSITION_MOVE_POSITION:
    case types.STORE_STANDARD_POSITION_NUMBER: {
      const newNumber = state.numberOfUncommitedChanges + 1;
      return { ...state, numberOfUncommitedChanges: newNumber };
    }

    case types.CLEAR_UNCOMMITTED_CHANGES_FULFILLED: {
      return { ...state, numberOfUncommitedChanges: 0 };
    }

    case types.SAVE_MANAGER_CHANGES_FULFILLED: {
      return { ...state };
    }

    case types.SIGNOUT: {
      localStorage.clear();
      return { ...initialState, isLoggedIn: false };
    }
  }

  return state;
}
