import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import config from '../configuration/config';
import * as userSessionActions from '../data/UserSession/userSessionActions';

class SignoutPage extends React.Component {

  componentWillMount() {
    this.props.signOut();
  }

  render() {

    let redirectTo = this.props.platform === "mobile" ? "/loginMobile" : "/login";
    const uuid = this.props.uuid;
    
    if (this.props.platform === "mobile" && uuid != null && uuid != undefined && uuid !== "undefined" && uuid !== '' && uuid !== '0' && uuid !== 0) {
      redirectTo += "/" + uuid;
    }
    else if (this.props.platform === "web" && parseInt(config.debug) === 0) {
      window.location.href = config.redirectAfterSignout;
    }
    else if (this.props.platform === "web" && parseInt(config.debug) === 1) {
      redirectTo += "/0";
    }


    return (
      <Redirect to={redirectTo} />
    );
  }
}

SignoutPage.propTypes = {
  signOut: PropTypes.func,

  platform: PropTypes.string,
  uuid: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    platform: state.userSession.platform,
    uuid: state.userSession.uuid,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: () => dispatch(userSessionActions.signOut())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignoutPage);
