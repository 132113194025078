import * as types from '../constants/actionTypes';

/*****/
/* ALL FOLLOWING ACTIONS CREATORS STORES THE DIMENSIONS OF THE APPLICATION 
    AND OTHER DOM OBJECTS TO BE USED THROUGHOUT THE SOFTWARE SINCE WE NEED TO
    DINAMICALLY COMPUTES THE HEIGHT OF SOME ELEMENTS */


export function storeApplicationDimensions(dimensions) {
    return ({ type: types.STORE_APPLICATION_DIMENSIONS, payload: dimensions });
}

export function storeLeftNavigationBarTop(topValue) {
    return ({ type: types.STORE_LEFT_BAR_TOP, payload: topValue });
}

export function storeLiveRotationContainerTop(topValue) {
    return ({ type: types.STORE_LIVEROTATION_CONTAINER_TOP, payload: topValue });
}

export function storeAvailableContainerTop(topValue) {
    return ({ type: types.STORE_AVAILABLE_CONTAINER_TOP, payload: topValue });
}

export function storeAvailableRowHeight(theHeight) {
    return ({ type: types.STORE_AVAILABLE_ROW_HEIGHT, payload: theHeight });
}

export function storeAvailableSelectedPage(thePage) {
    return ({ type: types.STORE_AVAILABLE_SELECTED_PAGE, payload: thePage });
}

export function storeStandardStatusDimensions(dimensions) {
    return ({ type: types.STORE_STATUS_CONTAINER_DIMENSIONS, payload: dimensions });
}

export function storeStandardStatusSelectedPage(thePage, theContainer) {
    let data = { thePage, theContainer };
    return ({ type: types.STORE_STANDARD_STATUS_SELECTED_PAGE, payload: data });
}