import React from 'react';
import TopNavigationBar from './TopNavigationBar';
// import PropTypes from 'prop-types';

const UnderConstructionPage = (/*props*/) => {

    return (
        <div>
            <TopNavigationBar />
            <img src="images/under_construction.png" alt="" className="center-under-construction" />
        </div>
    );
};

UnderConstructionPage.propTypes = {
    
};

export default UnderConstructionPage;
