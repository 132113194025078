import moment from "moment";
import aesjs from "aes-js";

export const isOdd = num => {
  return num % 2 === 1;
};

export function isOnShift(schedule) {
  
  //This method can be used to set the day of the week, with Sunday as 0 and Saturday as 6.
  let todayDay = moment().day(); // sunday=0, monday=1, tuesday=2, wednesday=3, thursday=4, friday=5, saturday=6

  switch (todayDay) {
    case 0: // sunday
      return (schedule & 1) === 1;
    case 1: // monday
      return (schedule & 2) === 2;
    case 2: // tuesday
      return (schedule & 4) === 4;
    case 3: // wednesday
      return (schedule & 8) === 8;
    case 4: // thursday
      return (schedule & 16) === 16;
    case 5: // friday
      return (schedule & 32) === 32;
    case 6: // saturday
      return (schedule & 64) === 64;
    default:
      return false;
  }
}

// An example 128-bit key
const key = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
// The initialization vector (must be 16 bytes)
const iv = [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36];

const encryptText = `!@#$%^&$+_)(*&^%`;

export function encrypt16(text) {
  if (text == null) {
    return 0;
  }
  // gets the length of the text and the text
  let newText = text.length.toString() + text;
  // create a string of 16 bytes
  newText += encryptText.substring(newText.length, encryptText.length);

  // Convert text to bytes (text must be a multiple of 16 bytes)
  let textBytes = aesjs.utils.utf8.toBytes(newText);

  let aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);
  let encryptedBytes = aesCbc.encrypt(textBytes);

  // To print or store the binary data, you may convert it to hex
  let encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);

  return encryptedHex;
}

export function decrypt16(encryptedHex) {
  if (encryptedHex == null) {
    return 0;
  }
  // When ready to decrypt the hex string, convert it back to bytes
  let encryptedBytes = aesjs.utils.hex.toBytes(encryptedHex);
  // The cipher-block chaining mode of operation maintains internal
  // state, so to decrypt a new instance must be instantiated.
  let aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);
  let decryptedBytes = aesCbc.decrypt(encryptedBytes);

  // Convert our bytes back into text
  let decryptedTextFull = aesjs.utils.utf8.fromBytes(decryptedBytes);

  let decryptedText = decryptedTextFull.substring(1, parseInt(decryptedTextFull[0]) + 1);

  return decryptedText;
}
