import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SignoutButton = (props) => {

    return (
        props.wrapInListItem
            ?
            <li>
                <Link className="logout-btn" to="/signout">
                    <img src="images/logout-icon.svg" alt="" />
                    <span>{props.text}</span>
                </Link>
            </li>
            :
            <Link className="logout-btn" to="/signout">
                <img src="images/logout-icon.svg" alt="" />
                <span>{props.text}</span>
            </Link>
    );
};

SignoutButton.propTypes = {
    text: PropTypes.string,
    wrapInListItem: PropTypes.bool
};

export default SignoutButton;
