import axios from 'axios';
import _ from 'underscore';

class ManagerServices {
    constructor(config, productWT) {
        this.managerUrl = config.api.managerRotation.url;
        this.productWebToken = "";

        if (productWT !== undefined && productWT !== "") {
            this.productWebToken = productWT;
        }
        else {
            this.productWebToken = localStorage.getItem("productWebToken");
        }

        this.axiosClient = axios.create({
            baseURL: this.managerUrl,
            headers: {
                Accept: 'application/json',
                "Authorization": "Bearer " + this.productWebToken
            }
        });
    }

    saveStandardPositionChanges(standardPositions) {
        return this.axiosClient.put("StandardPositionServices/StandardPosition/UpdateStandardPositionListByStatus", standardPositions)
            .catch(error => {
                return Promise.reject(error);
            });
    }

    saveManagerChanges(inAvailable, inGuestEngagement, inWilling, inOff, organizationId) {
        if (organizationId !== undefined && organizationId !== null) {
            let allStatus = inAvailable.concat(inGuestEngagement, inWilling, inOff);
            allStatus = _.uniq(allStatus);


            return this.axiosClient.put(`LiveRotationServices/LiveRotation/UpdateAllLiveRotation/ByOrganizationId/${organizationId}`, allStatus)
                .catch(error => {
                    return Promise.reject(error);
                });
        }
    }
}

export default ManagerServices;