import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as statusDescription from '../data/constants/statusDescriptions';
import * as rotationActions from '../data/LiveRotation/liveRotationActions';
import * as applicationActions from '../data/Application/applicationActions';
import * as offSets from '../data/constants/applicationSizeOffsets';
import draggableItemTypes from '../data/constants/draggableItemTypes';
import { Droppable, Draggable } from 'react-beautiful-dnd';

class StandardStatusContainer extends React.Component {

    componentDidMount() {
        // feel this a little hacky? check this: http://stackoverflow.com/questions/26556436/react-after-render-code
        setTimeout(() => {
            this.loadInformation();
            window.addEventListener("resize", this.updateDimensions.bind(this));
            this.updateDimensions();
            this.updateTop();
        }, 0);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.currentUserStatus !== nextProps.currentUserStatus) {
            this.loadInformation();
        }
    }

    componentDidUpdate() {
        //this.updateDimensions();
        this.updateTop();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    loadInformation() {
        this.props.fetchAllAssociatesInLiveRotation(this.props.selectedOrganizationId);
    }

    updateTop() {
        let distanceToTop = 0;
        let componentDiv = document.getElementById('availableContainer');
        if (componentDiv != undefined) {

            let theOffSet = !this.props.allowScrolling ? offSets.STANDARD_POSITION_CONTAINER : offSets.STANDARD_POSITION_CONTAINER_MANAGER;

            distanceToTop = componentDiv.getBoundingClientRect().top + theOffSet;
        }

        this.props.storeAvailableContainerTop({ distanceToTop: distanceToTop, isFromManager: this.props.allowScrolling });
    }

    updateDimensions() {
        let containerWidth = 0;
        let componentDiv = document.getElementById('divStandardStatusContainer');
        if (componentDiv != undefined) {
            containerWidth = componentDiv.clientWidth;
        }

        let circularImageWidth = 0;
        let circularImageHeight = 0;

        let circularImageDiv = document.getElementById('divCircularImage');
        if (circularImageDiv != undefined) {
            circularImageWidth = circularImageDiv.clientWidth;
            circularImageHeight = circularImageDiv.clientHeight;
        }

        this.props.storeStandardStatusDimensions({ containerWidth: containerWidth, circularImageWidth: circularImageWidth, circularImageHeight: circularImageHeight });
    }

    buildContainerClass(statusType) {
        switch (statusType) {
            case statusDescription.ON_HOLD:
                return "position-container position-onhold column";
            case statusDescription.GUEST_ENGAGEMENT:
                return "position-container position-unavailable column";
            case statusDescription.WILLING:
                return "position-container position-willing column";
            case statusDescription.OFF:
                return "position-container position-unavailable column";
        }
    }

    buildTitleClass(statusType) {
        switch (statusType) {
            case statusDescription.ON_HOLD:
                return "row position-title-onhold";
            case statusDescription.GUEST_ENGAGEMENT:
                return "row position-title-unavailable";
            case statusDescription.WILLING:
                return "row position-title-willing";
            case statusDescription.OFF:
                return "row position-title-off";
        }
    }

    computeTotal(statusType) {
        switch (statusType) {
            case statusDescription.ON_HOLD:
                return this.props.associatesInOnHold.length;
            case statusDescription.GUEST_ENGAGEMENT:
                return this.props.associatesInGuestEngagement.length;
            case statusDescription.WILLING:
                return this.props.associatesInWilling.length;
            case statusDescription.OFF:
                return this.props.associatesInOff.length;
        }
    }

    renderAssociates(statusType, format, maxIndex) {
        let listOfAssociates = [];
        let theSelectedPage = 0;
        let associatesToRender = "&nbsp";
        maxIndex -= 1;

        switch (statusType) {
            case statusDescription.ON_HOLD:
                listOfAssociates = this.props.associatesInOnHold;
                theSelectedPage = this.props.standardStatusOnHoldSelectedPage;
                break;
            case statusDescription.GUEST_ENGAGEMENT:
                listOfAssociates = this.props.associatesInGuestEngagement;
                theSelectedPage = this.props.standardStatusGuestEngagementSelectedPage;
                break;
            case statusDescription.WILLING:
                listOfAssociates = this.props.associatesInWilling;
                theSelectedPage = this.props.standardStatusWillingSelectedPage;
                break;
            case statusDescription.OFF:
                listOfAssociates = this.props.associatesInOff;
                theSelectedPage = this.props.standardStatusOffSelectedPage;
                break;
        }

        if (listOfAssociates !== null && listOfAssociates !== undefined && listOfAssociates.length > 0) {
            associatesToRender = listOfAssociates.map((theAssociate, index) => {
                let theCurrentPage = 0;

                if (this.props.allowScrolling === false) {
                    theCurrentPage = Math.floor(index / this.props.standardStatusNumberOfItemsByPage);
                }

                if (theCurrentPage === theSelectedPage && format === "grid") {
                    return (

                        <div id="divCircularImage" className="name-tag-collapsed" key={index} title={theAssociate.userFirstName + " " + theAssociate.userLastName}>
                            <div className="circle-profile-image">
                                <img src={theAssociate.user.photo} alt="" />
                            </div>
                            {statusType === statusDescription.WILLING &&
                                <div className="willing-number">{index + 1}</div>
                            }
                            <div className="name-collapsed">
                                {theAssociate.userFirstName.charAt(0)}.{theAssociate.userLastName.charAt(0)}
                            </div>
                        </div>

                    );
                }
                else if (format === "list") {
                    return (
                        <Draggable isDragDisabled={this.props.status.statusDescription === statusDescription.OFF} draggableId={"draggable-users-" + theAssociate.userId + "-maxindex:" + maxIndex}
                            type={draggableItemTypes.MANAGER_USER_CARD}
                            index={index}
                            key={index}>
                            {(provided) => (
                                <div
                                    id="availableRow"
                                    className="row even-tag"
                                    key={index}
                                    position={index}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}>
                                    <div className="number even">
                                        {index + 1}
                                    </div>
                                    <div className="name-tag">
                                        <div className="circle-profile-image">
                                            <img src={theAssociate.user.photo} alt="" />
                                        </div>
                                        &nbsp;{theAssociate.userFirstName + " " + theAssociate.userLastName}
                                    </div>
                                    {provided.placeholder}
                                </div>
                            )}
                        </Draggable>
                    );
                }
            });

            return associatesToRender;
        }

        this.updateDimensions();
    }

    renderBottomPages(statusType) {

        if (this.props.allowScrolling != undefined && this.props.allowScrolling === false) {

            let listOfAssociates = [];
            let theSelectedPage = 0;

            switch (statusType) {
                case statusDescription.ON_HOLD:
                    listOfAssociates = this.props.associatesInOnHold;
                    theSelectedPage = this.props.standardStatusOnHoldSelectedPage;
                    break;
                case statusDescription.GUEST_ENGAGEMENT:
                    listOfAssociates = this.props.associatesInGuestEngagement;
                    theSelectedPage = this.props.standardStatusGuestEngagementSelectedPage;
                    break;
                case statusDescription.WILLING:
                    listOfAssociates = this.props.associatesInWilling;
                    theSelectedPage = this.props.standardStatusWillingSelectedPage;
                    break;
                case statusDescription.OFF:
                    listOfAssociates = this.props.associatesInOff;
                    theSelectedPage = this.props.standardStatusOffSelectedPage;
                    break;
            }

            let pages = [];
            if ((this.props.standardStatusNumberOfItemsByPage != undefined && listOfAssociates != undefined)
                && (this.props.standardStatusNumberOfItemsByPage > 0 && listOfAssociates.length > 0)) {
                let numberOfPages = Math.ceil(listOfAssociates.length / this.props.standardStatusNumberOfItemsByPage);

                for (let i = 0; i < numberOfPages; i++) {
                    pages.push(<div key={i} className={i === theSelectedPage ? "paging-btn page-selected" : "paging-btn"} onClick={this.props.storeStandardStatusSelectedPage.bind(this, i, statusType)} />);
                }

                pages;
            }
            else {
                pages.push(<div key={0} className="paging-btn page-selected" />);
            }

            let thePages = pages.map(element => {
                return element;
            });

            return thePages;
        }
        else {
            return "";
        }
    }

    disableThisDroppable(droppableName, currentDroppableSource) {
        if (currentDroppableSource === "") {
            return false;
        }

        if (currentDroppableSource == "associatesInOnHold" && droppableName == "associatesInOnHold") {
            return true;
        }
    }

    renderStatusContainer(status, currentDroppableSource, format, styles, stylesDragging) {

        let droppableName = "associatesInOnHold"; // it is important to keep the name of the droppable as the name of the object in the state (list of actions in liveRotationReducer)
        switch (status.statusDescription) {
            case statusDescription.GUEST_ENGAGEMENT:
                droppableName = "associatesInGuestEngagement";
                break;

            case statusDescription.WILLING:
                droppableName = "associatesInWilling";
                break;

            case statusDescription.OFF:
                droppableName = "associatesInOff";
                break;
        }

        return (
            <div className={this.buildContainerClass(status.statusDescription)}>
                <div className={this.buildTitleClass(status.statusDescription)}>
                    <div className="nonavailable">
                        <img className="squared-icon" src={status.statusIcon} alt="" />
                        {status.statusDescription}
                    </div>
                    <div className="position-total">
                        Total: <span>{this.computeTotal(status.statusDescription)}</span>
                    </div>
                </div>

                <Droppable droppableId={droppableName} type={draggableItemTypes.MANAGER_USER_CARD} direction="vertical" isDropDisabled={this.disableThisDroppable(droppableName, currentDroppableSource)}>
                    {(provided, snapshot) => (
                        <div
                            id="divStandardStatusContainer"
                            className="nonavailable-content"
                            style={!snapshot.isDraggingOver ? styles : stylesDragging}
                            ref={provided.innerRef}
                            /*{...provided.droppableProps} need in version 5.0.0*/>
                            {
                                this.renderAssociates(status.statusDescription, format, this.computeTotal(status.statusDescription))
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>

                <div className="paging-container">
                    {
                        this.renderBottomPages(status.statusDescription)
                    }
                </div>
            </div>
        );
    }

    render() {

        const containerSizeStyle = {
            minHeight: (this.props.standardStatusHeight).toString() + "px",
            height: (this.props.standardStatusHeight).toString() + "px",
            maxHeight: (this.props.standardStatusHeight).toString() + "px",
            overflow: this.props.allowScrolling ? "auto" : "hidden"
        };

        const containerSizeStyleDragging = {
            minHeight: (this.props.standardStatusHeight).toString() + "px",
            height: (this.props.standardStatusHeight).toString() + "px",
            maxHeight: (this.props.standardStatusHeight).toString() + "px",
            overflow: this.props.allowScrolling ? "auto" : "hidden",
            backgroundColor: '#969696'
        };
        this.props.associatesInOff;

        return (
            this.renderStatusContainer(this.props.status, this.props.currentDroppableSource, this.props.format, containerSizeStyle, containerSizeStyleDragging)
        );
    }
}

StandardStatusContainer.propTypes = {
    applicationHeight: PropTypes.number,
    liveRotationContainerTop: PropTypes.number,
    standardStatusHeight: PropTypes.number,
    standardStatusNumberOfItemsByPage: PropTypes.number,
    standardStatusOnHoldSelectedPage: PropTypes.number,
    standardStatusGuestEngagementSelectedPage: PropTypes.number,
    standardStatusWillingSelectedPage: PropTypes.number,
    standardStatusOffSelectedPage: PropTypes.number,
    storeAvailableContainerTop: PropTypes.func,
    storeStandardStatusSelectedPage: PropTypes.func,
    storeStandardStatusDimensions: PropTypes.func,

    status: PropTypes.object,
    selectedOrganizationId: PropTypes.string,

    format: PropTypes.string,
    fetchAllAssociatesInLiveRotation: PropTypes.func,

    associatesInOnHold: PropTypes.array,
    associatesInGuestEngagement: PropTypes.array,
    associatesInWilling: PropTypes.array,
    associatesInOff: PropTypes.array,
    initialDroppableId: PropTypes.string,
    currentDroppableSource: PropTypes.string,

    currentUserStatus: PropTypes.string,
    allowScrolling: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return {
        applicationHeight: state.application.applicationHeight,
        liveRotationContainerTop: state.application.availableContainerTop,
        standardStatusHeight: state.application.standardStatusHeight,
        standardStatusNumberOfItemsByPage: state.application.standardStatusNumberOfItemsByPage,
        standardStatusOnHoldSelectedPage: state.application.standardStatusOnHoldSelectedPage,
        standardStatusGuestEngagementSelectedPage: state.application.standardStatusGuestEngagementSelectedPage,
        standardStatusWillingSelectedPage: state.application.standardStatusWillingSelectedPage,
        standardStatusOffSelectedPage: state.application.standardStatusOffSelectedPage,

        associatesInOnHold: state.liveRotation.associatesInOnHold,
        associatesInGuestEngagement: state.liveRotation.associatesInGuestEngagement,
        associatesInWilling: state.liveRotation.associatesInWilling,
        associatesInOff: state.liveRotation.associatesInOff,
        currentDroppableSource: state.liveRotation.currentDroppableSource,

        currentUserStatus: state.userSession.status,
        selectedOrganizationId: state.userSession.selectedOrganizationId
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchAllAssociatesInLiveRotation: (organizationId) => dispatch(rotationActions.fetchAllAssociatesInLiveRotation(organizationId)),
        storeStandardStatusDimensions: (dimensions) => dispatch(applicationActions.storeStandardStatusDimensions(dimensions)),
        storeAvailableContainerTop: (topValue) => dispatch(applicationActions.storeAvailableContainerTop(topValue)),
        storeStandardStatusSelectedPage: (thePage, theContainer) => dispatch(applicationActions.storeStandardStatusSelectedPage(thePage, theContainer))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StandardStatusContainer);