import React from 'react';
import TopNavigationBar from './TopNavigationBar';
//import Toggle from 'material-ui/Toggle';
// import PropTypes from 'prop-types';

const AdminPage = (/*props*/) => {

    return (
        <div>
            <TopNavigationBar />
            <div className="header">
                <h1 className="main-title">Administrator</h1>
            </div>
            <div className="big-container">
                <div className="tile tile-blue elt-shadow-3">
                    <h1>Admin Links</h1>
                    <div className="permission-table-cont">
                    <div className="permission-table">
                        <a href="/security">
                            <div className="circle-profile-image">
                                <img src="https://ets.eleadertech.com/Content/images/prf_generic.jpg" alt="Profile"/>
                            </div>
                            <div className="associate-info"><span className="name">Dealer Profile</span></div>
                        </a>

                    </div>
                </div>
                </div>
            </div>
        </div>
    );
};

AdminPage.propTypes = {
    
};

export default AdminPage;