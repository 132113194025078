import axios from 'axios';

class UserServices {
    constructor(config, productWT) {
        this.client = config.client;
        this.userServices = config.api.userServices.url;
        this.productWebToken = "";

        if (productWT === undefined) {
            this.productWebToken = localStorage.getItem("productWebToken");
        }
        else {
            this.productWebToken = productWT;
        }

        this.axiosClient = axios.create({
            baseURL: this.userServices,
            headers: {
                Accept: 'application/json',
                "Authorization": "Bearer " + this.productWebToken
            }
        });
    }

    getProductSpecificUserData(uid, orgId) {
        return this.axiosClient.get(`UserServices/User/ByUserId/${uid}/${orgId}`
            ,
            // header config
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }

    updateUserStatus(userId, newStatusId, madeByUserId) {
        return this.axiosClient.put(`RotationUpdateServices/RotationUpdate/UpdateUserStatus/UserId/${userId}/StatusByOrganization/${newStatusId}/MadeBy/${madeByUserId}`)
            .catch(error => {
                return Promise.reject(error);
            });

        //TODO: Also all previous non-dismissed updates have to be dismissed <= CHECK IF THIS HAS TO BE REALLY DONE IN THE BACK END
    }

    getReportInformation(organizationId, selectedClientAdvisor, minDate, maxDate) {
        return this.axiosClient.get(`UserServices/User/Report/${organizationId}/${selectedClientAdvisor}/${minDate}/${maxDate}`)
            .catch(error => {
                return Promise.reject(error);
            });
    }

    createUuidByUser(userId, uUID) {
        return this.axiosClient.post(`UserServices/User/CreateUuidByUser/userId/${userId}/uUID/${uUID}`)
            .catch(error => {
                return Promise.reject(error);
            });
    }

    removeUuidByUser(userId, uUID) {
        return this.axiosClient.post(`UserServices/User/RemoveUuidByUser/userId/${userId}/uUID/${uUID}`)
            .catch(error => {
                return Promise.reject(error);
            });
    }

    getRolByUserId(userId) {
        return this.axiosClient.get(`UserServices/User/GetRoleByUserId/userId/${userId}`)
            .catch(error => {
                return Promise.reject(error);
            });
    }

}

export default UserServices;