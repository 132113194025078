import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'material-ui';
import draggableItemTypes from '../data/constants/draggableItemTypes';
import { Draggable } from 'react-beautiful-dnd';

class StandardPosition extends React.Component {

  render() {

    return (
      <Draggable draggableId={"draggable-standardposition-" + this.props.standardPosition.standardPositionByStatusId} type={draggableItemTypes.STANDARD_POSITION} index={this.props.position}>
        {(provided) => (
          <div className="move-position"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}>
            <img className="position-role" src={this.props.standardPosition.standardPositionIcon} alt="" />&nbsp;
                <span className="position-role-text">
              {this.props.standardPosition.standardPositionDescription}&nbsp;
                </span>
            <TextField
              id={String(this.props.standardPosition.standardPositionByStatusId)}
              type="number"
              value={this.props.standardPosition.totalNumberOfUsers > 0 ? this.props.standardPosition.totalNumberOfUsers : 0}
              className="move-position-input"
              onChange={this.props.handleStandardPositionChange.bind(this)}
              disabled={this.props.standardPosition.isWildcard} />
            {provided.placeholder}
          </div>
        )}
      </Draggable>
    );
  }
}

StandardPosition.propTypes = {
  standardPosition: PropTypes.object,
  handleStandardPositionChange: PropTypes.func,
  position: PropTypes.number
};

export default StandardPosition;