import React from 'react';
import TopNavigationBar from './TopNavigationBar';
//import Toggle from 'material-ui/Toggle';
// import PropTypes from 'prop-types';

const Security = (/*props*/) => {

    return (
        <div>
            <TopNavigationBar />
            <div className="header">
                <h1 className="main-title">Security Profiles</h1>
            </div>
            <div className="big-container">
                <a href="/roles" className="lil-tile tile-green-left elt-shadow-3">
                    <div className="circle-role">
                        <span>C</span>
                    </div>
                    <div className="text-role">
                        <h2>Client Advisor</h2>
                        <span className="assoc-role">Associates: <b>4</b></span>
                    </div>
                </a>
                <a href="/roles" className="lil-tile tile-dark-blue-left elt-shadow-3">
                    <div className="circle-role">
                        <span>M</span>
                    </div>
                    <div className="text-role">
                        <h2>RB Manager</h2>
                        <span className="assoc-role">Associates: <b>4</b></span>
                    </div>
                </a>
                <a href="/roles" className="lil-tile tile-blue-left elt-shadow-3">
                    <div className="circle-role">
                        <span>D</span>
                    </div>
                    <div className="text-role">
                        <h2>Dealership Admin</h2>
                        <span className="assoc-role">Associates: <b>4</b></span>
                    </div>
                    
                </a>
            </div>
        </div>
    );
};

Security.propTypes = {
    
};

export default Security;