import React from 'react';
import PropTypes from 'prop-types';
import StandardPosition from './StandardPosition';
import draggableItemTypes from '../data/constants/draggableItemTypes';
import { Droppable } from 'react-beautiful-dnd';

class StandardPositionsCard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <div className="rb-card elt-shadow-2 position-settings">
                <Droppable droppableId="droppable-standard-position" type={draggableItemTypes.STANDARD_POSITION} direction="horizontal">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={{ backgroundColor: snapshot.isDraggingOver ? 'lightgray' : 'white' }}
                            /*{...provided.droppableProps} need in version 5.0.0*/>
                            {
                                props.activeStandardPositions.map((standardPosition, index) => {
                                    return (
                                        <StandardPosition
                                            key={index}
                                            position={index}
                                            standardPosition={standardPosition}
                                            handleStandardPositionChange={props.handleStandardPositionChange} />
                                    );
                                })
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>

                <div className="move-position total-position">
                    <img className="position-role" src="images/Total-icon.svg" alt="" />
                    &nbsp;
                    <b>Total</b>
                    &nbsp;
                    <span><b>{props.totalNumberOfStandardPositions === undefined ? 0 : props.totalNumberOfStandardPositions}</b></span>
                </div>
            </div>
        );
    }
}

StandardPositionsCard.propTypes = {
    activeStandardPositions: PropTypes.array,
    totalNumberOfStandardPositions: PropTypes.number,
    handleStandardPositionChange: PropTypes.func
};

export default StandardPositionsCard;