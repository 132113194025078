import React from 'react';
import PropTypes from 'prop-types';

const FooterStandardPositions = (props) => {

    return (
        <div>
            {
                props.activeStandardPositions.map((standardPosition, index) => {
                    return <span key={index}><img className="position-role" src={standardPosition.standardPositionIcon} alt="" />{standardPosition.standardPositionDescription}</span>;
                })
            }
        </div>
    );
};

FooterStandardPositions.propTypes = {
    activeStandardPositions: PropTypes.array
};

export default FooterStandardPositions;
