import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//import { Redirect } from 'react-router-dom';
import * as statusDescription from "../data/constants/statusDescriptions";
import * as rotationActions from "../data/LiveRotation/liveRotationActions";
import * as applicationActions from "../data/Application/applicationActions";
import * as userSessionActions from "../data/UserSession/userSessionActions";
import * as managerActions from "../data/Manager/managerActions";
import * as requestActions from "../data/Request/requestActions";
import * as requestState from "../data/constants/requestStates";
import * as types from "../data/constants/actionTypes";
import * as roles from "../data/constants/securityRoles";
import TopNavigationBar from "./TopNavigationBar";
import StandardPositionsCard from "../components/StandardPositionsCard";
import StandardPositionContainer from "./StandardPositionContainer";
import StandardStatusContainer from "./StandardStatusContainer";
import UserLookup from "../components/UserLookup";
import Offline from "../components/Offline";
import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentSave from "material-ui/svg-icons/content/save";
import ContentDiscard from "material-ui/svg-icons/content/clear";
import { toastr } from "react-redux-toastr";
import Spinner from "react-spinkit";

class ManageRotation extends React.Component {
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.updateDimensions();
    this.props.clearUncommittedChanges(false, this.props.availableStatusId);
    this.props.fetchAllAssociatesLiveRotationModelByRole(
      roles.ClientAdvisorIdMap,
      this.props.selectedOrganizationId
    );

    setTimeout(() => {
      this.props.storeAvailableSelectedPage(0);
    }, 0); // feel this a little hacky? check this: http://stackoverflow.com/questions/26556436/react-after-render-code
  }

  componentDidUpdate() {
    this.loadStandardPositions();
    this.updateDimensions();

    if (this.props.request === requestState.SUCCESS) {
      switch (this.props.lastRequestName) {
        case types.CLEAR_UNCOMMITTED_CHANGES_POSTS:
          toastr.info("Changes Discarted", "All changes were discarted.");
          break;
        case types.SAVE_MANAGER_CHANGES_POSTS:
          toastr.success("All Done", "All changes were saved successfully.");
          break;
      }
      this.props.clearRequest();
    } else if (this.props.request === requestState.FAIL) {
      const toastrOps = { timeOut: 0 };
      if (
        this.props.errorMessage !== undefined &&
        this.props.errorMessage !== null &&
        this.props.errorMessage !== ""
      ) {
        toastr.error(
          "Something went wrong",
          this.props.errorMessage,
          toastrOps
        );
      }

      this.props.clearRequest();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
    this.props.clearUncommittedChanges(true, this.props.availableStatusId);
  }

  handleStandardPositionChange(event) {
    this.props.storeStandardPositionNumber(event.target.id, event.target.value);
  }

  loadStandardPositions() {
    if (
      this.props.activeStandardPositions == undefined ||
      this.props.activeStandardPositions.length <= 0
    ) {
      if (this.props.availableStatusId !== "") {
        this.props.fetchStandardPositions(this.props.availableStatusId);
      }
    }
  }

  updateDimensions() {
    // compute the dimensions of the screen
    let w = window;
    let d = document;
    let documentElement = d.documentElement;
    let body = d.getElementsByTagName("body")[0];
    let theWidth =
      w.innerWidth || documentElement.clientWidth || body.clientWidth;
    let theHeight =
      w.innerHeight || documentElement.clientHeight || body.clientHeight;

    this.props.storeApplicationDimensions({
      width: theWidth,
      height: theHeight,
    });
  }

  renderOtherStatusContainers() {
    if (
      this.props.statusList != undefined &&
      this.props.statusList.length > 0
    ) {
      let statusContainersToRender = this.props.statusList.map(
        (theStatus, index) => {
          return theStatus.statusDescription != statusDescription.AVAILABLE ? (
            <StandardStatusContainer
              key={index}
              status={theStatus}
              allowScrolling={true}
              format="list"
            />
          ) : null;
        }
      );

      return statusContainersToRender;
    }
  }

  handleDiscardChanges() {
    this.props.clearUncommittedChanges(true, this.props.availableStatusId);
  }

  confirmDiscardChanges() {
    const toastrConfirmOptions = {
      onOk: () => this.handleDiscardChanges(),
      okText: "Yes",
      cancelText: "No",
    };

    toastr.confirm(
      "Are you sure you want to discard all changes?",
      toastrConfirmOptions
    );
  }

  handleSaveChanges() {
    this.props.saveManagerChanges(
      this.props.activeStandardPositions,
      this.props.inAvailable,
      this.props.inGuestEngagement,
      this.props.inWilling,
      this.props.inOff,
      this.props.selectedOrganizationId
    );
  }

  handleFilteringOfUsers(event) {
    this.props.filterAssociatesByRole(event.target.value);
  }

  render() {
    return !this.props.connection ? (
      <Offline />
    ) : (
      <div id="RB-Manager">
        <TopNavigationBar />
        <div className="header">
          <h1 className="main-title">Rotation Manager</h1>
        </div>

        <UserLookup
          userFilter={this.props.userFilter}
          applicationHeight={this.props.applicationHeight}
          handleFilteringOfUsers={this.handleFilteringOfUsers.bind(this)}
          listOfClientAdvisors={
            this.props.listOfClientAdvisors !== undefined
              ? this.props.listOfClientAdvisors
              : []
          }
        />

        <StandardPositionsCard
          activeStandardPositions={
            this.props.activeStandardPositions === undefined
              ? []
              : this.props.activeStandardPositions
          }
          handleStandardPositionChange={this.handleStandardPositionChange.bind(
            this
          )}
          totalNumberOfStandardPositions={
            this.props.totalNumberOfStandardPositions === undefined
              ? 0
              : this.props.totalNumberOfStandardPositions
          }
        />

        <div
          id="managerRotationContainer"
          className="rb-card elt-shadow-2 margin-rspv"
        >
          <StandardPositionContainer
            automaticPagingChangeOn={false}
            allowScrolling={true}
          />
          {this.renderOtherStatusContainers()}
          <div className="row no-bg align-right discard-changes-area">
            <span className="simple-text-red">
              <b>{this.props.numberOfUncommitedChanges}&nbsp;unsaved changes</b>
            </span>
            &nbsp;&nbsp;&nbsp;
            <FloatingActionButton
              secondary={true}
              className="btn-red"
              onClick={this.confirmDiscardChanges.bind(this)}
              disabled={this.props.numberOfUncommitedChanges === 0}
              style={{
                display:
                  this.props.request === requestState.IN_PROGRESS
                    ? "none"
                    : "inline-block",
              }}
            >
              <ContentDiscard />
            </FloatingActionButton>
            &nbsp;
            <FloatingActionButton
              className="btn-green"
              onClick={this.handleSaveChanges.bind(this)}
              disabled={this.props.numberOfUncommitedChanges === 0}
              style={{
                display:
                  this.props.request === requestState.IN_PROGRESS
                    ? "none"
                    : "inline-block",
              }}
            >
              <ContentSave />
            </FloatingActionButton>
            <Spinner
              style={{
                display:
                  this.props.request === requestState.IN_PROGRESS
                    ? "inline-block"
                    : "none",
              }}
              fadeIn="none"
              name="three-bounce"
              color="steelblue"
            />
          </div>
        </div>
      </div>
    );
  }
}

ManageRotation.propTypes = {
  // state to props
  isLoggedIn: PropTypes.bool,
  selectedOrganizationId: PropTypes.string,

  activeStandardPositions: PropTypes.array,
  totalNumberOfStandardPositions: PropTypes.number,
  statusList: PropTypes.array,
  availableStatusId: PropTypes.string,
  inAvailable: PropTypes.array,
  inOnHold: PropTypes.array,
  inGuestEngagement: PropTypes.array,
  inWilling: PropTypes.array,
  inOff: PropTypes.array,

  applicationHeight: PropTypes.number,
  liveRotationContainerTop: PropTypes.number,

  numberOfUncommitedChanges: PropTypes.number,
  errorMessage: PropTypes.string,
  listOfClientAdvisors: PropTypes.array,
  userFilter: PropTypes.string,

  request: PropTypes.number,
  lastRequestName: PropTypes.string,

  connection: PropTypes.bool,

  // dispatch to props
  fetchStandardPositions: PropTypes.func,
  storeStandardPositionNumber: PropTypes.func,
  fetchAllAssociatesLiveRotationModelByRole: PropTypes.func,
  filterAssociatesByRole: PropTypes.func,

  storeApplicationDimensions: PropTypes.func,
  storeLiveRotationContainerTop: PropTypes.func,
  storeAvailableSelectedPage: PropTypes.func,

  clearUncommittedChanges: PropTypes.func,
  saveManagerChanges: PropTypes.func,

  clearRequest: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    // userSession reducer
    isLoggedIn: state.userSession.isLoggedIn,
    selectedOrganizationId: state.userSession.selectedOrganizationId,

    // liveRotation reducer
    activeStandardPositions: state.liveRotation.activeStandardPositions,
    totalNumberOfStandardPositions:
      state.liveRotation.totalNumberOfStandardPositions,
    statusList: state.liveRotation.statusList,
    availableStatusId: state.liveRotation.availableStatusId,
    inAvailable: state.liveRotation.associatesInAvailable,
    inOnHold: state.liveRotation.associatesInOnHold,
    inGuestEngagement: state.liveRotation.associatesInGuestEngagement,
    inWilling: state.liveRotation.associatesInWilling,
    inOff: state.liveRotation.associatesInOff,
    listOfClientAdvisors: state.liveRotation.filteredListOfClientAdvisors,
    userFilter: state.liveRotation.userFilter,

    // application reducer
    applicationHeight: state.application.applicationHeight,
    liveRotationContainerTop: state.application.liveRotationContainerTop,

    // manager reducer
    numberOfUncommitedChanges: state.manager.numberOfUncommitedChanges,
    errorMessage: state.manager.errorMessage,

    // requestReducer reducer
    request: state.requestReducer.request,
    lastRequestName: state.requestReducer.lastRequestName,

    // internet reducer
    connection: state.internet.connection,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // rotation actions
    fetchStandardPositions: (availableStatusId) =>
      dispatch(rotationActions.fetchStandardPositions(availableStatusId)),
    storeStandardPositionNumber: (id, newNumber) =>
      dispatch(rotationActions.storeStandardPositionNumber(id, newNumber)),
    filterAssociatesByRole: (value) =>
      dispatch(rotationActions.filterAssociatesByRole(value)),

    // user actions
    fetchAllAssociatesLiveRotationModelByRole: (roleId, organizationId) =>
      dispatch(
        userSessionActions.fetchAllAssociatesLiveRotationModelByRole(
          roleId,
          organizationId
        )
      ),

    // application actions
    storeApplicationDimensions: (dimensions) =>
      dispatch(applicationActions.storeApplicationDimensions(dimensions)),
    storeLiveRotationContainerTop: (topValue) =>
      dispatch(applicationActions.storeLiveRotationContainerTop(topValue)),
    storeAvailableSelectedPage: (thePage) =>
      dispatch(applicationActions.storeAvailableSelectedPage(thePage)),

    // manager actions
    clearUncommittedChanges: (optionalValue, availableStatusId) =>
      dispatch(
        managerActions.clearUncommittedChanges(optionalValue, availableStatusId)
      ),
    saveManagerChanges: (
      standardPositions,
      inAvailable,
      inGuestEngagement,
      inWilling,
      inOff,
      organizationId
    ) =>
      dispatch(
        managerActions.saveManagerChanges(
          standardPositions,
          inAvailable,
          inGuestEngagement,
          inWilling,
          inOff,
          organizationId
        )
      ),

    // request actions
    clearRequest: () => dispatch(requestActions.clearRequest()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageRotation);
