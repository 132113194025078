import React from "react";
import PropTypes from "prop-types";
import * as requestState from "../../data/constants/requestStates";

//import TextField from "material-ui/TextField";
import Toggle from "material-ui/Toggle";
import RaisedButton from "material-ui/RaisedButton";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import Spinner from "react-spinkit";
import { toastr } from "react-redux-toastr";

const rotationOptions = [
  { value: 0, primaryText: "" },
  { value: 15, primaryText: "15 minutes" },
  { value: 30, primaryText: "30 minutes" },
  { value: 45, primaryText: "45 minutes" },
  { value: 60, primaryText: "1 hour" },
  { value: 75, primaryText: "1 hour, 15 minutes" },
  {
    value: 90,
    primaryText: "1 hour, 30 minutes",
  },
  {
    value: 105,
    primaryText: "1 hour, 45 minutes",
  },
  { value: 120, primaryText: "2 hours" },
];

class MainSettings extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      autoRotateMinutes: this.props.autoRotateMinutes,
      allowUsersSelfManagement: this.props.allowUsersSelfManagement,
    };

    this.handleAutoRotateToggle = this.handleAutoRotateToggle.bind(this);
    this.handleAllowUsersSelfManagement = this.handleAllowUsersSelfManagement.bind(
      this
    );
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (
      this.state.autoRotateMinutes !== newProps.autoRotateMinutes &&
      newProps.autoRotateMinutes !== null
    ) {
      this.setState({ autoRotateMinutes: newProps.autoRotateMinutes });
    }
    if (
      this.state.allowUsersSelfManagement !==
        newProps.allowUsersSelfManagement &&
      newProps.allowUsersSelfManagement !== null
    ) {
      this.setState({
        allowUsersSelfManagement: newProps.allowUsersSelfManagement,
      });
    }
  }

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  handleAutoRotateToggle(event, e) {
    this.setState({
      autoRotateMinutes: rotationOptions[e].value,
    });
  }

  handleAllowUsersSelfManagement(event) {
    this.setState({
      allowUsersSelfManagement: Boolean(event.target.value),
    });
  }

  handleDiscardChanges() {
    this.setState({
      autoRotateMinutes: this.props.autoRotateMinutes,
      allowUsersSelfManagement: this.props.allowUsersSelfManagement,
    });
  }

  handleSaveChanges() {
    this.props.updateSettings(
      this.props.selectedOrganization,
      this.state.autoRotateMinutes,
      this.state.allowUsersSelfManagement
    );
    toastr.info(" ", "Changes Saved", {
      transitionIn: "fadeIn",
      transitionOut: "fadeOut",
      timeOut: 1200,
    });
  }

  render() {
    return (
      <div className="big-container">
        <div
          style={{ width: "660px", height: "100%" }}
          className="tile tile-blue elt-shadow-3"
        >
          <h1 style={{ marginBottom: "5px" }}>Auto Rotation</h1>
          <div style={{ display: "flex", alignItems: "center", width: "95%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexBasis: "100%",
                flex: "2",
                paddingLeft: "10px",
              }}
            >
              <span style={{ marginBottom: "33px" }}>
                Allow auto rotation for users in Available
              </span>
              <span style={{ marginTop: "15px" }}>
                Frequency of auto-rotation for users in Available
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexBasis: "100%",
                flex: "1",
              }}
            >
              <Toggle
                label=""
                defaultToggled={
                  this.state.autoRotateMinutes === null ||
                  this.state.autoRotateMinutes === 0
                    ? false
                    : true
                }
                value={
                  this.state.autoRotateMinutes === null ||
                  this.state.autoRotateMinutes === 0
                    ? false
                    : true
                }
                onClick={() =>
                  this.setState({
                    autoRotateMinutes:
                      this.state.autoRotateMinutes === null ? 15 : null,
                  })
                }
              />
              <SelectField
                floatingLabelText="Minutes"
                style={{ width: "210px" }}
                value={this.state.autoRotateMinutes}
                onChange={this.handleAutoRotateToggle}
              >
                {rotationOptions.map((e, i) => (
                  <MenuItem
                    key={"mn" + i}
                    value={e.value}
                    primaryText={e.primaryText}
                  />
                ))}
              </SelectField>
            </div>
          </div>
          <div style={{ marginTop: "45px" }} />
          <h1 style={{ marginBottom: "5px" }}>Status Management</h1>
          <div style={{ display: "flex", alignItems: "center", width: "95%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexBasis: "100%",
                flex: "2",
                paddingLeft: "10px",
              }}
            >
              <span>Allow users to manage status of other users</span>
              {/* <TextField style={{ marginLeft: "10px", width: "99%", float: "left" }} floatingLabelText="First Name" value={this.state.firstName} onChange={this.handleChange('firstName')} />
                                <TextField style={{ marginLeft: "10px", width: "100%", float: "right" }} floatingLabelText="Title" value={this.state.title} onChange={this.handleChange('title')} /> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexBasis: "100%",
                flex: "1",
              }}
            >
              <Toggle
                defaultToggled={this.state.allowUsersSelfManagement}
                value={this.state.allowUsersSelfManagement}
                onClick={() =>
                  this.setState({
                    allowUsersSelfManagement: !this.state
                      .allowUsersSelfManagement,
                  })
                }
                //defaultToggled={true}
                //style={styles.toggle}
              />
            </div>
          </div>
          <div
            style={{
              flexGrow: "1",
              padding: "15px",
              backgroundColor: "#F2F2F2",
              boxSizing: "border-box",
              marginTop: "45px",
            }}
          >
            <RaisedButton
              label="Save"
              primary={true}
              onClick={this.handleSaveChanges.bind(this)}
              disabled={this.props.request === requestState.IN_PROGRESS}
            />
            &nbsp;&nbsp;
            <RaisedButton
              label="Discard Changes"
              onClick={this.handleDiscardChanges.bind(this)}
              disabled={this.props.request === requestState.IN_PROGRESS}
            />
            &nbsp;&nbsp;
            <Spinner
              style={{
                display:
                  this.props.request === requestState.IN_PROGRESS
                    ? "inline-block"
                    : "none",
              }}
              fadeIn="none"
              name="three-bounce"
              color="steelblue"
            />
          </div>
        </div>
      </div>
    );
  }
}

MainSettings.propTypes = {
  autoRotateMinutes: PropTypes.number,
  allowUsersSelfManagement: PropTypes.bool,
  selectedOrganization: PropTypes.string,

  updateSettings: PropTypes.func,
  request: PropTypes.number,
};

export default MainSettings;
