import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as userSessionActions from "../data/UserSession/userSessionActions";
import * as organizationActions from "../data/Organization/organizationActions";

import TopNavigationBar from "./TopNavigationBar";
import MainContainer from "./Settings/MainSettings";
class Settings extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleAutoRotateToggle = this.handleAutoRotateToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    //this.loadCasUserInfo();
  }

  componentDidMount() {
    //setTimeout(() => { this.loadCasUserInfo(); }, 0);
    this.props.getOrganizationById(this.props.selectedOrganization);
  }
  loadCasUserInfo() {
    this.props.retrieveCasUser();
  }

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  handleAutoRotateToggle = () => (event) => {
    this.setState({
      autoRotateMinutes: Boolean(event.target.value) === true ? 15 : null,
    });
  };

  handleDiscardChanges() {
    this.setState({
      autoRotateMinutes: this.props.autoRotateMinutes,
      allowUsersSelfManagement: this.props.allowUsersSelfManagement,
    });
  }

  handleSaveChanges(org, min, manageVal) {
    this.props.updateSetttings(org, min, manageVal);
  }

  render() {
    return (
      <div>
        <TopNavigationBar />
        <div className="header">
          <h1 className="main-title">Settings</h1>
        </div>
        <MainContainer
          autoRotateMinutes={this.props.autoRotateMinutes}
          allowUsersSelfManagement={this.props.allowUsersSelfManagement}
          selectedOrganization={this.props.selectedOrganization}
          request={this.props.request}
          updateSettings={this.props.updateSetttings}
        />
      </div>
    );
  }
}

Settings.propTypes = {
  retrieveCasUser: PropTypes.func,
  updateUser: PropTypes.func,
  clearUserSavedFlag: PropTypes.func,
  getOrganizationById: PropTypes.func,
  updateSetttings: PropTypes.func,

  autoRotateMinutes: PropTypes.number,
  allowUsersSelfManagement: PropTypes.bool,
  selectedOrganization: PropTypes.string,

  userSaved: PropTypes.bool,
  request: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    userSaved: state.userSession.userSaved,

    request: state.requestReducer.request,

    autoRotateMinutes: state.organization.autoRotateMinutes,
    allowUsersSelfManagement: state.organization.allowUsersSelfManagement,
    selectedOrganization: state.userSession.selectedOrganizationId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // userSession actions
    retrieveCasUser: (userId) =>
      dispatch(userSessionActions.retrieveCasUser(userId)),
    updateUser: (theUser) => dispatch(userSessionActions.updateUser(theUser)),
    clearUserSavedFlag: () => dispatch(userSessionActions.clearUserSavedFlag()),
    // updateAllowUsersSelfManagement: (orgId, val) =>
    //   dispatch(organizationActions.updateAllowUsersSelfManagement(orgId, val)),
    updateSetttings: (orgId, min, manageValue) =>
      dispatch(organizationActions.updateSettings(orgId, min, manageValue)),
    getOrganizationById: (orgId) =>
      dispatch(organizationActions.retrieveById(orgId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
