import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as rotationActions from '../data/LiveRotation/liveRotationActions';
import * as status from '../data/constants/statusDescriptions';
import * as securityRoles from '../data/constants/securityRoles';
import moment from 'moment';


class LastUpdate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            intervalId: 0,
            timeDifference: ""
        };
    }

    componentWillMount() {
        this.restartInterval();
    }

    componentDidMount() {
        setTimeout(() => { this.retrieveUpdate(); }, 0);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.rotationLastUpdate !== null && nextProps.rotationLastUpdate !== undefined
            && this.props.userCurrentStatus !== nextProps.userCurrentStatus) {
            if (nextProps.rotationLastUpdate.isDismissed === true) {
                this.retrieveUpdate();
            }
        }

        if (this.props.rotationLastUpdate !== null && this.props.rotationLastUpdate !== undefined
            && nextProps.rotationLastUpdate !== null && nextProps.rotationLastUpdate !== undefined) {
            if (this.props.rotationLastUpdate.id !== nextProps.rotationLastUpdate.id) {
                this.restartInterval();
            }
        }
    }

    componentWillUnmount() {
        if (this.state !== undefined && this.state.intervalId !== undefined && this.state.intervalId !== 0) {
            clearInterval(this.state.intervalId);
        }
    }

    restartInterval() {
        clearInterval(this.state.intervalId);

        let interval = setInterval(() => { this.computeTimeDifference(); }, 1000);

        this.setState({ intervalId: interval });
    }

    computeTimeDifference() {
        if (this.props.rotationLastUpdate !== null && this.props.rotationLastUpdate !== undefined) {
            let end = moment.utc(); // right now in utc
            let rotationUpdateDateInLocal = moment.utc(this.props.rotationLastUpdate.rotationUpdateDateTimeUTC);
            let duration = moment.duration(end.diff(rotationUpdateDateInLocal));
            let result = Math.floor(duration.asSeconds());
            let minsHours = " seconds";

            if (result >= 60 && result < 3600) {
                result = Math.floor(duration.asMinutes());

                if (result === 1) {
                    minsHours = " minute";
                }
                else {
                    minsHours = " minutes";
                }
            }
            else if (result >= 3600) {
                result = Math.floor(duration.asHours());

                if (result === 1) {
                    minsHours = " hour";
                }
                else {
                    minsHours = " hours";
                }
            }
            else if (result >= 86400) {
                result = Math.floor(duration.asDays());

                if (result === 1) {
                    minsHours = " day";
                }
                else {
                    minsHours = " days";
                }
            }

            this.setState({ timeDifference: result.toString() + minsHours });
            return result.toString() + minsHours;
        }
        else {
            this.setState({ timeDifference: "" });
            return "";
        }
    }

    computePositionStyle() {
        let basicStyle = "position-update";

        switch (this.props.rotationLastUpdate.currentStatusDescription) {
            case status.AVAILABLE:
                return basicStyle + " available";
            case status.GUEST_ENGAGEMENT:
                return basicStyle + " guest-engagement";
            case status.ON_HOLD:
                return basicStyle + " on-hold";
            case status.WILLING:
                return basicStyle + " willing";
            default:
                return basicStyle + " off";
        }
    }

    getCurrentStatusIcon() {
        switch (this.props.rotationLastUpdate.currentStatusDescription) {
            case status.AVAILABLE:
                return "images/available-icon.svg";
            case status.GUEST_ENGAGEMENT:
                return "images/unavailable-icon.svg";
            case status.ON_HOLD:
                return "images/onhold-icon.svg";
            case status.WILLING:
                return "images/willing-icon.svg";
            default:
                return "images/off-icon.svg";
        }
    }

    retrieveUpdate() {
        switch (this.props.userInitialRole) {
            case securityRoles.User:
            case securityRoles.Associate:
            case securityRoles.ClientAdvisor:
                this.props.fetchLastUpdateByUserId(this.props.userId, false);
                break;
            case securityRoles.DealershipAdmin:
            case securityRoles.GlobalAdmin:
            case securityRoles.Admin:
                this.props.fetchLastUpdate();
                break;
        }
    }

    handleDismiss() {
        if (this.state !== undefined && this.state.intervalId !== undefined && this.state.intervalId !== 0) {
            clearInterval(this.state.intervalId);
        }

        this.props.dismissLastUpdate(this.props.rotationLastUpdate);
    }

    render() {
        if (this.props.rotationLastUpdate !== null && this.props.rotationLastUpdate !== undefined && this.props.rotationLastUpdate.isDismissed === false) {
            return (
                <div className="rb-card no-padding elt-shadow-2">
                    <div className="left-title">Last Update</div>
                    <div className="right-title"><span>{this.state.timeDifference !== "" ? this.state.timeDifference + " since status change" : ""}</span>
                        <button className="close-btn" onClick={this.handleDismiss.bind(this)} type="button">X</button>
                    </div>
                    <div className="profile-update">
                        <div className="circle-profile-image">
                            <img src={this.props.rotationLastUpdate.user.photo} alt="" />
                        </div>
                        {this.props.rotationLastUpdate.user.fullName}
                        <img className="blue-arrow" src="images/blue-arrow.svg" alt="arrow" />
                    </div>
                    <div className={this.computePositionStyle()}>

                        {this.props.rotationLastUpdate.currentStandardPositionDescription !== undefined && this.props.rotationLastUpdate.currentStandardPositionDescription !== "" ?
                            <img className="position-role" src={this.props.rotationLastUpdate.standardPosition.icon} alt="" /> :
                            <img className="position-role" src={this.getCurrentStatusIcon()} alt="" />
                        }
                        <span>{this.props.rotationLastUpdate.currentStatusDescription}</span>
                    </div>
                </div>
            );
        }
        else {
            return null;
        }
    }
}

LastUpdate.propTypes = {
    fetchLastUpdate: PropTypes.func,
    fetchLastUpdateByUserId: PropTypes.func,
    dismissLastUpdate: PropTypes.func,

    rotationLastUpdate: PropTypes.object,
    userCurrentStatus: PropTypes.string,
    userId: PropTypes.string,
    userInitialRole: PropTypes.string
};

function mapStateToProps(state) {
    return {
        rotationLastUpdate: state.liveRotation.rotationLastUpdate,
        userCurrentStatus: state.userSession.status,
        userId: state.userSession.uid,
        userInitialRole: state.userSession.initialRole
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchLastUpdate: () => dispatch(rotationActions.fetchLastUpdate()),
        fetchLastUpdateByUserId: (userId, includeDismissed) => dispatch(rotationActions.fetchLastUpdateByUserId(userId, includeDismissed)),
        dismissLastUpdate: (rotationUpdateObject) => dispatch(rotationActions.dismissLastUpdate(rotationUpdateObject))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LastUpdate);
