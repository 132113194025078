import axios from "axios";
class OrganizationServices {
  constructor(config, productWT) {
    this.organizationUrl =
      config.api.organizationServices.url + "OrganizationServices/Organization";
    this.productWebToken = "";

    if (productWT !== undefined && productWT !== "") {
      this.productWebToken = productWT;
    } else {
      this.productWebToken = localStorage.getItem("productWebToken");
    }

    this.axiosClient = axios.create({
      baseURL: this.managerUrl,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + this.productWebToken,
      },
    });
  }

  retrieveById(idOrganization) {
    const url = `${this.organizationUrl}/GetOrganization/${idOrganization}`;

    return this.axiosClient.get(`${url}`).catch((error) => {
      return Promise.reject(error);
    });
  }

  updateAllowUsersSelfManagement(idOrganization, value) {
    const url = `${this.organizationUrl}/UpdateAllowUsersSelfManagement/${idOrganization}/${value}`;

    return this.axiosClient.post(`${url}`).catch((error) => {
      return Promise.reject(error);
    });
  }

  updateAutoRotateMinutes(idOrganization, minutes) {
    const url = `${this.organizationUrl}/UpdateAutoRotateMinutes/${idOrganization}/${minutes}`;

    return this.axiosClient.post(`${url}`).catch((error) => {
      return Promise.reject(error);
    });
  }
}

export default OrganizationServices;
