import * as types from "../constants/actionTypes";
import userSessionExtension from "./userSessionExtensions";
import { isOnShift, encrypt16 } from "../../helpers/utils";
import jwt_decode from "jwt-decode";

const initialState = {
  error: null,
  isLoading: false,
  refreshToken: localStorage.getItem("refreshToken"),
  accessToken: localStorage.getItem("accessToken"),
  userName: localStorage.getItem("userName"),
  firstName: localStorage.getItem("firstName"),
  middleName: "",
  lastName: localStorage.getItem("lastName"),
  title: "",
  workPhoneNumber: "",
  mobilePhoneNumber: "",
  email: "",
  userImage: localStorage.getItem("userImage"),
  mbsImage: localStorage.getItem("mbsImage"),
  expire: localStorage.getItem("expire"),
  uid: localStorage.getItem("uid"),
  org: localStorage.getItem("org"),
  initialRole: localStorage.getItem("initialRole"),
  isLoggedIn: userSessionExtension.isLoggedIn(
    localStorage.getItem("accessToken"),
    localStorage.getItem("expire")
  ),
  status: localStorage.getItem("status"),
  statusId: localStorage.getItem("statusId"),
  isOnShift: localStorage.getItem("isOnShift"),
  userOrganizations: localStorage.getItem("userOrganizations"),
  selectedOrganizationId: localStorage.getItem("selectedOrganizationId"),
  selectedOrganizationName: localStorage.getItem("selectedOrganizationName"),
  platform: localStorage.getItem("platform"),
  uuid: localStorage.getItem("uuid"),
  userProfile: localStorage.getItem("userProfile"),
  schedule: localStorage.getItem("schedule"),
  userSaved: null,

  // Schedule. Following values will change according to the bitwise "schedule" property
  sunday: false,
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: false,

  // organization switch
  organizationSwitchedFlag: false,
  newSelectedOrganizationId: "",
};

export default function userSessionReducer(state = initialState, action) {
  let errorMessage = "";

  switch (action.type) {
    case types.AUTH_ERROR: {
      localStorage.clear();

      if (
        action.payload !== undefined &&
        action.payload.data !== undefined &&
        action.payload.data.error !== undefined
      ) {
        errorMessage = action.payload.data.error;
      }

      if (
        action.payload !== undefined &&
        action.payload.data !== undefined &&
        action.payload.data.error_description !== undefined &&
        action.payload.data.error_description
      ) {
        errorMessage = action.payload.data.error_description;
      }

      return {
        ...state,
        isLoggedIn: false,
        refreshToken: "",
        accessToken: "",
        expire: "",
        error: errorMessage
      };
    }

    case types.UPDATE_USER_STATUS_REJECTED: {
      if (
        action.payload !== undefined &&
        action.payload.data !== undefined &&
        action.payload.data.error !== undefined
      ) {
        errorMessage = action.payload.data.error;
      }

      if (
        action.payload !== undefined &&
        action.payload.data !== undefined &&
        action.payload.data.error_description !== undefined
      ) {
        errorMessage = action.payload.data.error_description;
      }

      if (errorMessage !== "") {
        return { ...state, errorMessage: errorMessage };
      }
      else {
        return { ...state };
      }
    }

    case types.AUTHENTICATE: {
      let productResponse = action.payload.productResponse;
      let eltResponse = action.payload.eltResponse;
      let productSpecificResponse = action.payload.productSpecificResponse;
      let otherClaims = jwt_decode(productResponse.data.access_token);
      let isLoggedInUpdated = userSessionExtension.isLoggedIn(
        productResponse.data.access_token,
        eltResponse.data[".expires"]
      );

      if (isLoggedInUpdated) {
        userSessionExtension.saveClaims(
          eltResponse,
          otherClaims,
          productSpecificResponse,
          productResponse.data.access_token
        );

        const days = userSessionExtension.availableDays(localStorage.getItem("schedule"));

        return {

          ...state,
          isLoggedIn: isLoggedInUpdated,
          error: null,
          refreshToken: eltResponse.data.refresh_token,
          accessToken: eltResponse.data.access_token,
          userName: eltResponse.data.userName,
          firstName: eltResponse.data.firstName,
          middleName: eltResponse.data.middleName === null ? "" : eltResponse.data.middleName,
          lastName: eltResponse.data.lastName,
          mobilePhoneNumber: eltResponse.data.mobilePhoneNumber,
          userImage: localStorage.getItem("userImage"),
          mbsImage: localStorage.getItem("mbsImage"),
          expire: eltResponse.data[".expires"],
          uid: localStorage.getItem("uid"),
          org: localStorage.getItem("org"),
          initialRole: localStorage.getItem("initialRole"),
          schedule: localStorage.getItem("schedule"),
          userOrganizations: localStorage.getItem("userOrganizations"),
          selectedOrganizationId: localStorage.getItem("selectedOrganizationId"),
          isOnShift: localStorage.getItem("isOnShift"),
          selectedOrganizationName: localStorage.getItem("selectedOrganizationName"),
          status: localStorage.getItem("status"),
          statusId: localStorage.getItem("statusId"),
          platform: localStorage.getItem("platform"),
          uuid: localStorage.getItem("uuid"),

          sunday: days.find(x => (x === "SU")) !== undefined,
          monday: days.find(x => (x === "MO")) !== undefined,
          tuesday: days.find(x => (x === "TU")) !== undefined,
          wednesday: days.find(x => (x === "WE")) !== undefined,
          thursday: days.find(x => (x === "TH")) !== undefined,
          friday: days.find(x => (x === "FR")) !== undefined,
          saturday: days.find(x => (x === "SA")) !== undefined,

          organizationSwitchedFlag: false,
          newSelectedOrganizationId: "",
        };
      } else {
        localStorage.clear();
        return { ...initialState };
      }
    }

    case types.UPDATE_USER_STATUS_FULFILLED: {
      localStorage.setItem("status", action.payload.description);
      localStorage.setItem("statusId", action.payload.id);

      return {
        ...state,
        status: action.payload.description,
        statusId: action.payload.id
      };
    }

    case types.STORE_PLATFORM: {
      localStorage.setItem("platform", action.payload);
      return { ...state, platform: action.payload };
    }

    case types.CREATE_UUID_BY_USER_FULFILLED: {
      localStorage.setItem("uuid", action.payload);
      return { ...state, uuid: action.payload };
    }

    case types.GET_ROLE_BY_USER_ID_FULFILLED: {
      let data = action.payload;
      let userProfile = '';
      data.forEach(item => {
        userProfile += item.roleID + ',';
      });
      userProfile = encrypt16(userProfile);

      localStorage.setItem("userProfile", userProfile);
      return { ...state, userProfile: userProfile };
    }

    case types.UPDATE_USER_FULFILLED: {

      let updatedUser = action.payload.data;
      localStorage.setItem("schedule", updatedUser.schedule);
      localStorage.setItem("firstName", updatedUser.firstName);
      localStorage.setItem("lastName", updatedUser.lastName);

      const ios = isOnShift(updatedUser.schedule);

      localStorage.setItem("isOnShift", ios);

      const availableDays = userSessionExtension.availableDays(updatedUser.schedule);

      return {
        ...state,
        userSaved: true,
        schedule: localStorage.getItem("schedule"),
        firstName: localStorage.getItem("firstName"),
        lastName: localStorage.getItem("lastName"),
        title: updatedUser.title,
        workPhoneNumber: updatedUser.phoneNumber,
        isOnShift: ios,

        sunday: availableDays.find(x => (x == "SU")) !== undefined,
        monday: availableDays.find(x => (x === "MO")) !== undefined,
        tuesday: availableDays.find(x => (x === "TU")) !== undefined,
        wednesday: availableDays.find(x => (x === "WE")) !== undefined,
        thursday: availableDays.find(x => (x === "TH")) !== undefined,
        friday: availableDays.find(x => (x === "FR")) !== undefined,
        saturday: availableDays.find(x => (x === "SA")) !== undefined,
      };
    }

    case types.UPDATE_USER_REJECTED: {
      return { ...state, userSaved: false };
    }

    case types.FETCH_CAS_USER_FULFILLED: {

      const theDays = userSessionExtension.availableDays(action.payload.data.schedule);

      return {
        ...state,
        title: action.payload.data.title === null ? "" : action.payload.data.title,
        workPhoneNumber: action.payload.data.phoneNumber == null ? "" : action.payload.data.phoneNumber,
        email: action.payload.data.email === null ? "" : action.payload.data.email,
        middleName: action.payload.data.middleName === null ? "" : action.payload.data.middleName,
        mobilePhoneNumber: action.payload.data.mobilePhoneNumber === null ? "" : action.payload.data.mobilePhoneNumber,
        userSaved: null,

        sunday: theDays.find(x => (x == "SU")) !== undefined,
        monday: theDays.find(x => (x === "MO")) !== undefined,
        tuesday: theDays.find(x => (x === "TU")) !== undefined,
        wednesday: theDays.find(x => (x === "WE")) !== undefined,
        thursday: theDays.find(x => (x === "TH")) !== undefined,
        friday: theDays.find(x => (x === "FR")) !== undefined,
        saturday: theDays.find(x => (x === "SA")) !== undefined,
      };
    }

    case types.FETCH_CAS_USER_REJECTED: {
      return { ...state, userSaved: false };
    }

    case types.UPDATE_USER_CLEAR_FLAG: {
      return { ...state, userSaved: null };
    }

    case types.CLEAR_DATA_FOR_SWITCH_ORGANIZATION: {
      let refToken = localStorage.getItem('refreshToken');
      
      localStorage.clear();

      localStorage.setItem('refreshToken', refToken);

      return {
        ...state,
        organizationSwitchedFlag: true,
        newSelectedOrganizationId: action.payload
      };
    }    

    case types.SIGNOUT: {
      localStorage.clear();
      return { ...initialState, isLoggedIn: false };
    }
  }

  return state;
}
