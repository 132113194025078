/* FROM ELT CAS AND GTR CAS  */
export const Associate = "Associate";
export const Admin = "Admin";
export const GlobalAdmin = "GlobalAdmin";
export const DealershipAdmin = "Dealership Admin";
export const User = "User";
/* FROM RB */
export const ClientAdvisor = "Client Advisor";
export const RotationBoardManager = "RotationBoard Manager";
/* MAPPING */
export const RotationBoardManagerIdMap = 1;
export const ClientAdvisorIdMap = 2;