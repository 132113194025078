import React from 'react';
import PropTypes from 'prop-types';
import * as statusDescription from '../data/constants/statusDescriptions';

const MobileMoveToMenu = (props) => {

    function renderStatusList() {
        let willingIsVisible = !props.isOnShift && props.currentStatus !== statusDescription.WILLING;
        let availableIsVisible = (props.currentStatus === statusDescription.ON_HOLD) ? true :
            props.isOnShift &&
            (props.currentStatus === statusDescription.ON_HOLD ||
                props.currentStatus === statusDescription.GUEST_ENGAGEMENT ||
                props.currentStatus === statusDescription.OFF) && props.currentStatus !== statusDescription.AVAILABLE;
        let onHoldIsVisible = props.currentStatus === statusDescription.AVAILABLE;
        let guestEngagementIsVisible = props.currentStatus === statusDescription.AVAILABLE;

        let statuses = props.statusList.map((theStatus, index) => {

            if (theStatus.statusDescription === statusDescription.OFF && props.currentStatus !== statusDescription.OFF) {
                return <li key={index} onClick={props.handleUpdateUserStatusSelection.bind(this, theStatus.statusByOrganizationId)}><img className="squared-icon" src={theStatus.statusIcon} alt="" /><span>{theStatus.statusDescription}</span></li>;
            }

            if (willingIsVisible && theStatus.statusDescription === statusDescription.WILLING) {
                return <li key={index} onClick={props.handleUpdateUserStatusSelection.bind(this, theStatus.statusByOrganizationId)}><img className="squared-icon" src={theStatus.statusIcon} alt="" /><span>{theStatus.statusDescription}</span></li>;
            }

            if (availableIsVisible && theStatus.statusDescription === statusDescription.AVAILABLE) {
                return <li key={index} onClick={props.handleUpdateUserStatusSelection.bind(this, theStatus.statusByOrganizationId)}><img className="squared-icon" src={theStatus.statusIcon} alt="" /><span>{theStatus.statusDescription}</span></li>;
            }

            if (onHoldIsVisible && theStatus.statusDescription === statusDescription.ON_HOLD) {
                return <li key={index} onClick={props.handleUpdateUserStatusSelection.bind(this, theStatus.statusByOrganizationId)}><img className="squared-icon" src={theStatus.statusIcon} alt="" /><span>{theStatus.statusDescription}</span></li>;
            }

            if (guestEngagementIsVisible && theStatus.statusDescription === statusDescription.GUEST_ENGAGEMENT) {
                return <li key={index} onClick={props.handleUpdateUserStatusSelection.bind(this, theStatus.statusByOrganizationId)}><img className="squared-icon" src={theStatus.statusIcon} alt="" /><span>{theStatus.statusDescription}</span></li>;
            }

        });

        return statuses;
    }

    return (
        (props.moveToIsDisplayed === false) ? null
            :
            <div className="move-menu">
                <ul>
                    {renderStatusList()}
                </ul>
            </div>
    );
};

MobileMoveToMenu.propTypes = {
    selectedView: PropTypes.string,
    moveToIsDisplayed: PropTypes.bool,
    currentStatus: PropTypes.string,
    statusList: PropTypes.array,
    isOnShift: PropTypes.bool,
    handleUpdateUserStatusSelection: PropTypes.func
};

export default MobileMoveToMenu;
