import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as rotationActions from "../data/LiveRotation/liveRotationActions";
import * as applicationActions from "../data/Application/applicationActions";
import * as offSets from "../data/constants/applicationSizeOffsets";
import StandardPositionRow from "./StandardPositionRow";
import draggableItemTypes from "../data/constants/draggableItemTypes";
import { Droppable } from "react-beautiful-dnd";

class StandardPositionContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = { intervalId: 0 };
  }

  componentDidMount() {
    this.props.fetchAllAssociatesInLiveRotation(
      this.props.selectedOrganizationId
    );

    window.addEventListener("resize", this.updateTop.bind(this));

    setTimeout(() => {
      this.updateTop();
      this.startAutomaticPagingChange();
    }, 0); // feel this a little hacky? check this: http://stackoverflow.com/questions/26556436/react-after-render-code
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status !== nextProps.status) {
      this.props.fetchAllAssociatesInLiveRotation(
        this.props.selectedOrganizationId
      );
    }
  }

  componentWillUpdate(nextProps) {
    if (nextProps.lastUpdateIsDismissed !== this.props.lastUpdateIsDismissed) {
      this.updateTop();
    }
  }

  componentDidUpdate() {
    this.loadStandardPositions();

    this.updateTop();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateTop.bind(this));
    if (this.state.intervalId !== 0) {
      clearInterval(this.state.intervalId);
    }
  }

  loadStandardPositions() {
    if (
      this.props.standardPositionsList == undefined ||
      this.props.standardPositionsList.length <= 0
    ) {
      if (
        this.props.availableStatusId !== "" &&
        this.props.availableStatusId !== undefined
      ) {
        this.props.fetchStandardPositions(this.props.availableStatusId);
      }
    }
  }

  updateTop() {
    let distanceToTop = 0;
    let componentDiv = document.getElementById("availableContainer");
    if (componentDiv != undefined) {
      let theOffSet = !this.props.allowScrolling
        ? offSets.STANDARD_POSITION_CONTAINER
        : offSets.STANDARD_POSITION_CONTAINER_MANAGER;

      distanceToTop = componentDiv.getBoundingClientRect().top + theOffSet;
    }

    this.props.storeAvailableContainerTop({
      distanceToTop: distanceToTop,
      isFromManager: this.props.allowScrolling,
    });
  }

  createRowInfo(theStandardPosition, theIndex) {
    let numberOfPage = 0;

    if (
      this.props.allowScrolling != undefined &&
      this.props.allowScrolling === false
    ) {
      numberOfPage = Math.floor(
        (theIndex - 1) / this.props.availableNumberOfRowsByPage
      ); // need to substract one because in the props the value sent is "index + 1"
    }

    return {
      Description: theStandardPosition.Description,
      Icon: theStandardPosition.Icon,
      NumberOfRow: theIndex,
      NumberOfPage: numberOfPage,
    };
  }

  renderBottomPages() {
    if (
      this.props.allowScrolling != undefined &&
      this.props.allowScrolling === false
    ) {
      let pages = [];
      if (
        this.props.availableNumberOfRowsByPage != undefined &&
        this.props.associatesInAvailable != undefined &&
        this.props.availableNumberOfRowsByPage > 0 &&
        this.props.associatesInAvailable.length > 0
      ) {
        let numberOfPages = Math.ceil(
          this.props.associatesInAvailable.length /
            this.props.availableNumberOfRowsByPage
        );

        for (let i = 0; i < numberOfPages; i++) {
          pages.push(
            <div
              key={i}
              className={
                i === this.props.availableSelectedPage
                  ? "paging-btn page-selected"
                  : "paging-btn"
              }
              onClick={this.props.storeAvailableSelectedPage.bind(this, i)}
            />
          );
        }

        pages;
      } else {
        pages.push(<div key={0} className="paging-btn page-selected" />);
      }

      let thePages = pages.map((element) => {
        return element;
      });

      return thePages;
    } else {
      return "";
    }
  }

  startAutomaticPagingChange() {
    if (
      this.props.automaticPagingChangeOn != undefined &&
      this.props.automaticPagingChangeOn === true &&
      this.state != undefined &&
      this.props.availableNumberOfRowsByPage != undefined &&
      this.props.associatesInAvailable != undefined
    ) {
      let theIntervalId = setInterval(() => {
        if (
          this.props.availableNumberOfRowsByPage > 0 &&
          this.props.associatesInAvailable.length > 0
        ) {
          let numberOfPages = Math.ceil(
            this.props.associatesInAvailable.length /
              this.props.availableNumberOfRowsByPage
          );
          let theNextPage = this.props.availableSelectedPage + 1;

          if (theNextPage === numberOfPages) {
            theNextPage = 0;
          }

          this.props.storeAvailableSelectedPage(theNextPage);
        }
      }, 10000);

      this.setState({ intervalId: theIntervalId });
    }
  }

  handleMouseEnter() {
    clearInterval(this.state.intervalId);
  }

  handleMouseLeave() {
    this.startAutomaticPagingChange();
  }

  render() {
    const containerHeightStyle = {
      minHeight:
        (
          this.props.applicationHeight - this.props.availableContainerTop
        ).toString() + "px",
      height:
        (
          this.props.applicationHeight - this.props.availableContainerTop
        ).toString() + "px",
      overflow: this.props.allowScrolling ? "auto" : "hidden",
    };

    const containerHeightStyleDragging = {
      minHeight:
        (
          this.props.applicationHeight - this.props.availableContainerTop
        ).toString() + "px",
      height:
        (
          this.props.applicationHeight - this.props.availableContainerTop
        ).toString() + "px",
      overflow: this.props.allowScrolling ? "auto" : "hidden",
      backgroundColor: "#969696",
    };

    const availableContentDragging = {
      backgroundColor: "#969696",
    };
    const countMissingPosition =
      this.props.associatesInAvailable != null &&
      this.props.standardPositionsList != null
        ? this.props.associatesInAvailable.length -
          this.props.standardPositionsList.length
        : 0;

    return (
      <Droppable
        droppableId="associatesInAvailable"
        type={draggableItemTypes.MANAGER_USER_CARD}
        direction="vertical"
        isDropDisabled={!this.props.allowScrolling}
      >
        {(provided, snapshot) => (
          <div
            id="availableContainer"
            onMouseEnter={this.handleMouseEnter.bind(this)}
            onMouseLeave={this.handleMouseLeave.bind(this)}
            className="position-container position-available column"
            style={
              !snapshot.isDraggingOver
                ? containerHeightStyle
                : containerHeightStyleDragging
            }
            ref={provided.innerRef}
            /*{...provided.droppableProps} need in version 5.0.0*/
          >
            <div className="row position-title-available">
              <div className="number odd">#</div>
              <div className="inline">
                <img
                  className="squared-icon"
                  src="images/available-icon.svg"
                  alt=""
                />{" "}
                Available
              </div>
              <div className="position-total">
                Total:{" "}
                <span>
                  {this.props.associatesInAvailable === undefined
                    ? 0
                    : this.props.associatesInAvailable.length}
                </span>
              </div>
            </div>

            <div
              id="availableContent"
              className="available-content"
              style={!snapshot.isDraggingOver ? {} : availableContentDragging}
            >
              {this.props.standardPositionsList !== undefined ? (
                this.props.standardPositionsList.map(
                  (standarPosition, index) => {
                    const user = this.props.associatesInAvailable[index];
                    if (user == null) return null;
                    return (
                      <StandardPositionRow
                        key={index}
                        user={user}
                        index={index}
                        maxIndex={this.props.associatesInAvailable.length}
                        allowScrolling={this.props.allowScrolling}
                        standardPosition={this.createRowInfo(
                          standarPosition,
                          index + 1
                        )}
                      />
                    );
                  }
                )
              ) : (
                <div />
              )}
              {/******* Display Missing Available Employees without an space */}
              {this.props.standardPositionsList != null &&
              this.props.associatesInAvailable != null &&
              this.props.standardPositionsList.length > 0 &&
              this.props.associatesInAvailable.length >
                this.props.standardPositionsList.length
                ? Array.from(Array(countMissingPosition)).map((e, i) => {
                    let realIndex = i + 1;
                    const countOfPositions =
                      this.props.standardPositionsList.length - 1;
                    const standarPosition = this.props.standardPositionsList[
                      countOfPositions
                    ];
                    const index = countOfPositions + realIndex;

                    const userIndex = countOfPositions + realIndex;
                    if (userIndex >= this.props.associatesInAvailable.length)
                      return null;
                    const user = this.props.associatesInAvailable[userIndex];

                    return (
                      <StandardPositionRow
                        key={index}
                        user={user}
                        index={index}
                        maxIndex={this.props.associatesInAvailable.length}
                        allowScrolling={this.props.allowScrolling}
                        standardPosition={this.createRowInfo(
                          standarPosition,
                          index + 1
                        )}
                      />
                    );
                  })
                : null}
            </div>

            {!this.props.allowScrolling ? (
              <div className="paging-container">{this.renderBottomPages()}</div>
            ) : (
              <div />
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}

StandardPositionContainer.propTypes = {
  standardPositionsList: PropTypes.array,
  fetchStandardPositions: PropTypes.func,
  availableStatusId: PropTypes.string,

  associatesInAvailable: PropTypes.array,
  fetchAllAssociatesInLiveRotation: PropTypes.func,
  reorderAllAssociatesInState: PropTypes.func,

  applicationHeight: PropTypes.number,
  applicationWidth: PropTypes.number,
  availableContainerTop: PropTypes.number,
  storeAvailableContainerTop: PropTypes.func,
  availableNumberOfRowsByPage: PropTypes.number,
  availableSelectedPage: PropTypes.number,
  storeAvailableSelectedPage: PropTypes.func,

  status: PropTypes.string,
  selectedOrganizationId: PropTypes.string,

  lastUpdateIsDismissed: PropTypes.bool,

  automaticPagingChangeOn: PropTypes.bool.isRequired,
  allowScrolling: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    standardPositionsList: state.liveRotation.standardPositionsList,
    associatesInAvailable: state.liveRotation.associatesInAvailable,
    availableStatusId: state.liveRotation.availableStatusId,

    applicationWidth: state.application.applicationWidth,
    applicationHeight: state.application.applicationHeight,
    availableContainerTop: state.application.availableContainerTop,
    availableNumberOfRowsByPage: state.application.availableNumberOfRowsByPage,
    availableSelectedPage: state.application.availableSelectedPage,
    lastUpdateIsDismissed: state.application.lastUpdateDismissed,

    status: state.userSession.status,
    selectedOrganizationId: state.userSession.selectedOrganizationId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // rotation actions
    fetchStandardPositions: (availableStatusId) =>
      dispatch(rotationActions.fetchStandardPositions(availableStatusId)),
    fetchAllAssociatesInLiveRotation: (organizationId) =>
      dispatch(
        rotationActions.fetchAllAssociatesInLiveRotation(organizationId)
      ),
    reorderAllAssociatesInState: () =>
      dispatch(rotationActions.reorderAllAssociatesInState()),
    // application actions
    storeAvailableContainerTop: (topValue) =>
      dispatch(applicationActions.storeAvailableContainerTop(topValue)),
    storeAvailableSelectedPage: (thePage) =>
      dispatch(applicationActions.storeAvailableSelectedPage(thePage)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StandardPositionContainer);
