import axios from 'axios';
import querystring from 'querystring';
import { isMobile } from 'react-device-detect';

class AuthenticationServices {
    constructor(config) {
        this.client = config.client;
        this.eltUrl = config.api.eltAuth.url;
        this.productUrl = config.api.productAuth.url;
        this.grantType = this.client.grant_type;
        this.clientId = isMobile ? this.client.mobile_client_id : this.client.client_id;
        this.clientSecret = this.client.client_secret;
        this.userServices = this.client.userServices;

        this.accessToken = localStorage.getItem("accessToken");
    }

    authenticateWithCredentials(userName, password) {
        const eltTokenUrl = this.eltUrl + '/Token';

        return axios.post(`${eltTokenUrl}`,
            // the body stringified
            querystring.stringify({
                username: userName,
                password,
                grant_type: this.grantType,
                client_id: this.clientId,
                client_secret: this.clientSecret
            }),
            // header config
            {
                headers: {
                    //Accept: 'application/json',
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    authenticateWithRefreshToken(refreshToken) {
        const eltTokenUrl = this.eltUrl + '/Token';

        return axios.post(`${eltTokenUrl}`,
            // the body stringified
            querystring.stringify({
                refresh_token: refreshToken,
                grant_type: "refresh_token",
                client_id: this.clientId,
                client_secret: this.clientSecret
            }),
            // header config
            {
                headers: {
                    Accept: 'application/json',
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    authorize(productWebToken) {
        const productTokenUrl = this.productUrl + '/Token';

        return axios.post(`${productTokenUrl}`,
            // the body stringified
            querystring.stringify({
                grant_type: this.grantType
            }),
            // header config
            {
                headers: {
                    Accept: 'application/json',
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "bearer " + productWebToken
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    retrieveCasUser(uid) {
        return axios.get(this.eltUrl + `/users/${uid}`
            ,
            // header config
            {
                headers: {
                    Accept: 'application/json',
                    "Authorization": "Bearer " + this.accessToken,
                    "Content-Type": "application/json"
                }
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }

    updateUser(user) {
        return axios.put(this.eltUrl + `/users`, user
            ,
            // header config
            {
                headers: {
                    Accept: 'application/json',
                    "Authorization": "Bearer " + this.accessToken,
                    "Content-Type": "application/json"
                }
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }
}

export default AuthenticationServices;